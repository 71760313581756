import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicCoverageModule } from './dynamic-coverage';
import { RealTimeEligibilityModule } from './real-time-eligibility/real-time-eligibility.module';
import { DialInMessageModule } from './dial-in-message/dial-in-message.module';

@NgModule({
  imports: [CommonModule, DynamicCoverageModule, RealTimeEligibilityModule, DialInMessageModule],
  exports: [DynamicCoverageModule, RealTimeEligibilityModule, DialInMessageModule],
})
export class FeaturesModule {}

export * from './dynamic-coverage/dynamic-coverage.module';
export * from './real-time-eligibility/real-time-eligibility.module';
export * from './dial-in-message/dial-in-message.module';
