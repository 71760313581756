import { Injectable } from '@angular/core';
import { IAnswer, IMappedQuestionGroupAnswers, areAnswersValid, getAnswerByCodePrefix } from '@boldpenguin/sdk';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable()
export class DriverService {
  questionGroupId$ = new BehaviorSubject<string>('');
  answersAreValid$ = new BehaviorSubject<boolean>(false);
  driverName$ = new BehaviorSubject<string>('Driver');
  answers$ = new BehaviorSubject<IAnswer[]>([]);

  private readonly _mvrQuestionCode = 'mqs_driver_mvr_report_condition';

  setQuestionGroup(v: IMappedQuestionGroupAnswers) {
    this.questionGroupId$.next(v.groupId);
    const valid = areAnswersValid(v.answers);
    this.answersAreValid$.next(valid);
    this.answers$.next(this.removeMVRQuestion(v.answers));
    this.driverName$.next(this.getDriverName(v));
  }

  getDriverName(group: IMappedQuestionGroupAnswers) {
    if (!group) {
      return 'Driver';
    }

    const firstName = getAnswerByCodePrefix('mqs_driver_first_name', group.answers)?.value ?? '';
    const lastName = getAnswerByCodePrefix('mqs_driver_last_name', group.answers)?.value ?? '';

    let name = group.label;
    if (firstName && lastName) {
      name = `${firstName} ${lastName}`;
    }
    if (group.parentQuestionAnswer?.value) {
      name = group.parentQuestionAnswer.value;
    }

    return name || 'Driver';
  }

  removeMVRQuestion(answers: IAnswer[]): IAnswer[] {
    return answers.filter(answer => {
      if (answer.question.code === this._mvrQuestionCode && !answer.required) {
        return;
      }
      return answer;
    });
  }
}
