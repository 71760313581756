<label [id]="labelId" [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-full-width emperor-ui-select-input">
  <mat-select
    [formControl]="control"
    [aria-labelledby]="emperorInputAriaLabeledBy"
    [id]="id || ''"
    [attr.data-test]="qaAttributeHook"
    (blur)="onBlur()"
    (focus)="onFocus()"
    disableOptionCentering
    [multiple]="multi"
    #select
    [placeholder]="placeholder ?? ''"
  >
    <mat-select-trigger *ngIf="selectTrigger">{{ selectTrigger }}</mat-select-trigger>
    <mat-option *ngFor="let choice of choices" [value]="choice.value">
      <emperor-icon *ngIf="!!choice.icon" [name]="choice.icon"></emperor-icon>
      <span [ngClass]="{ 'emperor-ui-select-label': !!choice.icon }">
        {{ choice.label }}
      </span>
    </mat-option>
  </mat-select>
  <mat-error emperorErrorHandler [errorText]="errorText" [errorMessageMap]="errorMessageMap" [id]="code || ''"></mat-error>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
</mat-form-field>
