<div class="emperor-vehicle-panel-container">
  <ng-container *ngIf="(finishedEditingTypeOfVehicle$ | async) === false || (isEditingTypeOfVehicle$ | async) === true; else accordion">
    <emperor-card [type]="emperorCardType.Outlined">
      <emperor-radio-button
        [formControl]="formControl"
        [choices]="addVehicleChoices"
        [label]="emperorAddVehicleLabel"
        [code]="questionCodeMap.vehicleFlow"
        [id]="(answerIdMap$ | async)?.vehicleFlow"
      ></emperor-radio-button>

      <div [ngSwitch]="typeOfVehicleQuestion$ | async">
        <ng-container *ngSwitchCase="vehicleEntryQuestions.vin">
          <div class="emperor-vehicle-question-container" *ngTemplateOutlet="vin"></div>
        </ng-container>
        <ng-container *ngSwitchCase="vehicleEntryQuestions.yearMakeModel">
          <div class="emperor-vehicle-question-container" *ngTemplateOutlet="year"></div>
        </ng-container>
        <ng-container *ngSwitchCase="vehicleEntryQuestions.trailer">
          <div class="emperor-vehicle-question-container" *ngTemplateOutlet="trailer"></div>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
        <div class="emperor-vehicle-panel-buttons">
          <bp-sdk-vehicle-delete-button
            (click)="handleDelete()"
          ></bp-sdk-vehicle-delete-button>
          <bp-sdk-vehicle-save-button
            class="save-button"
            text="Save Info"
            [buttonDisabled]="!this.canSave"
            (click)="onSaveVehicle()"
          ></bp-sdk-vehicle-save-button>
        </div>
      </div>
    </emperor-card>
  </ng-container>
</div>

<ng-template #accordion>
  <mat-accordion [id]="questionGroupId$ | async">
    <mat-expansion-panel
      class="primary-accordion"
      [expanded]="(isPanelExpanded$ | async) === true"
      [disabled]="(answersAreValid$ | async) === false"
      (opened)="onExpandPanel()"
      (closed)="onCollapsePanel()"
      hideToggle="true"
    >
      <mat-expansion-panel-header class="emperor-vehicle-header-content">
        <mat-panel-title>
          <div class="emperor-vehicle-label-container">
            <div class="emperor-vehicle-main-label">
              <h3>
                <bp-sdk-vehicle-panel-label [vehicleLabel]="vehicleLabel"></bp-sdk-vehicle-panel-label>
              </h3>
              <bp-sdk-vehicle-edit-button (click)="clickEdit($event)"></bp-sdk-vehicle-edit-button>
            </div>

            <div
              *ngIf="(isPrefilled$ | async) && (isPanelExpanded$ | async) === false"
              class="emperor-vehicle-prefill-container"
            >
              <emperor-icon name="prefill_lightning"></emperor-icon>
              <p>This vehicle entry is prefilled</p>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div class="emperor-vehicle-icon-wrapper">
            <emperor-icon
              *ngIf="(answersAreValid$ | async) === true"
              name="check_circle_2_1"
              class="completed-icon"
            ></emperor-icon>
          </div>
              <h4 class="expanded-text-header">
            {{ (isPanelExpanded$ | async) ? 'HIDE' : 'SHOW' }}
          </h4>
          <div class="emperor-ui-accordion-toggle-container" [ngClass]="{ expanded: isPanelExpanded$ | async }">
            <emperor-icon name="keyboard_arrow_up"></emperor-icon>
            <emperor-icon name="keyboard_arrow_down"></emperor-icon>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-container *ngIf="(isPanelExpanded$ | async) === true">
        <bp-sdk-vehicle-panel-content
          [additionalAnswers]="answers$ | async"
          [questionGroup]="questionGroup$ | async"
        ></bp-sdk-vehicle-panel-content>

        <div class="emperor-vehicle-panel-buttons">
          <bp-sdk-vehicle-delete-button
            (click)="handleDelete()"
          ></bp-sdk-vehicle-delete-button>
          <bp-sdk-vehicle-save-button
            class="save-button"
            text="Save Info"
            [buttonDisabled]="(answersAreValid$ | async) === false"
            (click)="onClickSaveButton()"
          ></bp-sdk-vehicle-save-button>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #vin>
  <bp-sdk-vehicle-vin-container
    [questionGroupId]="questionGroupId$ | async"
    [canSave]="canSave"
    text="Save Info"
    [code]="questionCodeMap.vin"
    [answerId]="(answerIdMap$ | async)?.vin"
    [value]="vinValue$ | async"
  ></bp-sdk-vehicle-vin-container>
</ng-template>

<ng-template #year>
  <bp-sdk-vehicle-interface [questionGroup]="questionGroup$ | async" [canSave]="canSave"></bp-sdk-vehicle-interface>
</ng-template>

<ng-template #trailer>
  <bp-sdk-vehicle-interface
    [questionGroup]="questionGroup$ | async"
    [canSave]="canSave"
    [isVehicleTrailer]="true"
  ></bp-sdk-vehicle-interface>
</ng-template>
