<emperor-dialog-header title="Customize limits for this quote" [color]="EmperorCardHeaderColor.NONE"></emperor-dialog-header>
<emperor-card-content class="card-content" [scrollable]="true">
  <emperor-spinner *ngIf="showSpinner$ | async" [fullscreen]="true"></emperor-spinner>
  To customize limits for this quote, you will be navigated back to {{ carrier.name }}'s application questions. Please review all
  adjustments, as they may affect this quote.
</emperor-card-content>
<emperor-card-footer [color]="EmperorCardHeaderColor.NONE">
  <emperor-button [uiButtonType]="EmperorUIButtonTypes.Text" (click)="onCloseDialog()">CANCEL</emperor-button>
  <emperor-button (click)="onSaveChanges()">GO TO CARRIER LIMITS</emperor-button>
</emperor-card-footer>
