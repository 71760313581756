import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { EmperorButtonModule } from '../button';
import { AlertCarouselComponent } from './components/alert-carousel/alert-carousel.component';
import { CarouselNavigationComponent } from './components/carousel-navigation/carousel-navigation.component';

@NgModule({
  declarations: [CarouselNavigationComponent, AlertCarouselComponent],
  imports: [CommonModule, EmperorButtonModule, IconsModule],
  exports: [CarouselNavigationComponent, AlertCarouselComponent],
})
export class EmperorCarouselModule {}
