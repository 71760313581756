export enum EmperorUIButtonTypes {
  Standard = 'standard',
  Large = 'large',
  Stroked = 'stroked',
  StrokedLarge = 'stroked large',
  Fab = 'fab',
  FabLarge = 'fab large',
  Text = 'text',
  Link = 'link',
  InverseStroked = 'inverse stroked',
  Neutral = 'neutral',
}
