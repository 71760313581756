<ng-container [ngSwitch]="uiButtonType">
  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.Standard"
    mat-raised-button
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button"
    [color]="color"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>
  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.Large"
    mat-raised-button
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button emperor-ui-button-large"
    [color]="color"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>
  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.Stroked"
    mat-stroked-button
    class="emperor-ui-button emperor-ui-button-stroked"
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    [color]="color"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>
  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.StrokedLarge"
    mat-stroked-button
    class="emperor-ui-button emperor-ui-button-stroked emperor-ui-button-large"
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    [color]="color"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>
  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.FabLarge"
    mat-fab
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button mat-elevation-z"
    [color]="color"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>

  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.Fab"
    mat-mini-fab
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button mat-elevation-z"
    [color]="color"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>

  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.Text"
    mat-button
    [color]="color"
    class="emperor-ui-button emperor-ui-button-text"
    [emperorTooltip]="tooltip"
    [closeTooltip]="closeTooltip"
    [tooltipPlacement]="tooltipPlacement"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>

  <a [href]="href" *ngSwitchCase="emperorUIButtonTypes.Link" [ngClass]="color" class="emperor-ui-link-button" [attr.target]="target">
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>

  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.InverseStroked"
    mat-flat-button
    [color]="color"
    class="emperor-ui-button emperor-ui-inverse-stroked-button"
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>

  <a
    [href]="href"
    *ngSwitchCase="emperorUIButtonTypes.Neutral"
    mat-stroked-button
    [color]="color"
    class="emperor-ui-button emperor-ui-button-neutral"
    [emperorTooltip]="tooltip"
    [closeTooltip]="closeTooltip"
    [tooltipPlacement]="tooltipPlacement"
    [type]="type"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel"
    [attr.target]="target"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </a>
</ng-container>

<ng-template #contentTemp>
  <ng-content></ng-content>
</ng-template>
