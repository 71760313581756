<emperor-dialog
  class="emperor-ui-google-address-validation-dialog"
  *ngIf="shouldShow$ | async"
  [backdropDismiss]="false"
  [ngSwitch]="verdict"
  (close)="close()"
>
  <ng-container *ngSwitchCase="AddressValidationVerdict.FIX" [ngTemplateOutlet]="fix"> </ng-container>
  <ng-container *ngSwitchCase="AddressValidationVerdict.TYPO" [ngTemplateOutlet]="typo"> </ng-container>
  <ng-container *ngSwitchCase="AddressValidationVerdict.CONFIRM" [ngTemplateOutlet]="confirm"> </ng-container>
</emperor-dialog>

<!-- CONFIRM TEMPLATE -->
<ng-template #confirm>
  <emperor-dialog-header title="Address confirmation" [color]="EmperorCardHeaderColor.NONE"></emperor-dialog-header>

  <emperor-card-content class="content">
    <ng-container
      *ngTemplateOutlet="
        addressDisplay;
        context: { header: 'Suggested address:', address: validatedAddressView.displayAddress, isSuggested: true }
      "
    ></ng-container>
    <ng-container *ngTemplateOutlet="addressDisplay; context: { header: 'Original address:', address: originalAddress }"></ng-container>
  </emperor-card-content>

  <emperor-card-footer class="footer" [color]="EmperorCardHeaderColor.NONE">
    <emperor-button class="emperor-ui-manually-set-size" (click)="continueWithConfirm()">CONTINUE WITH SUGGESTED ADDRESS</emperor-button>
    <emperor-button class="emperor-ui-manually-set-size" (click)="continueAsEntered()" [uiButtonType]="EmperorUIButtonTypes.Stroked"
      >USE ORIGINAL ADDRESS</emperor-button
    >
    <p>Address confirmation helps ensure quoting accuracy.</p>
  </emperor-card-footer>
</ng-template>

<!-- TYPO TEMPLATE -->
<ng-template #typo>
  <emperor-dialog-header title="Address confirmation" [color]="EmperorCardHeaderColor.NONE"></emperor-dialog-header>

  <emperor-card-content class="content">
    <ng-container
      *ngTemplateOutlet="
        addressDisplay;
        context: { header: 'Did you mean?', address: validatedAddressView.displayAddress, isSuggested: true }
      "
    ></ng-container>
    <ng-container *ngTemplateOutlet="addressDisplay; context: { header: 'Address entered:', address: originalAddress }"></ng-container>
  </emperor-card-content>

  <emperor-card-footer class="footer" [color]="EmperorCardHeaderColor.NONE">
    <emperor-button class="emperor-ui-manually-set-size" (click)="continueWithConfirm()"
      >YES, CONTINUE WITH SUGGESTED ADDRESS</emperor-button
    >
    <emperor-button class="emperor-ui-manually-set-size" (click)="continueAsEntered()" [uiButtonType]="EmperorUIButtonTypes.Stroked"
      >NO, CONTINUE WITH ADDRESS ENTERED</emperor-button
    >
    <p>Address confirmation helps ensure quoting accuracy.</p>
  </emperor-card-footer>
</ng-template>

<!-- FIX TEMPLATE -->
<ng-template #fix>
  <emperor-dialog-header title="Address not verified" [color]="EmperorCardHeaderColor.NONE"></emperor-dialog-header>

  <emperor-card-content class="content">
    <ng-container *ngTemplateOutlet="addressDisplay; context: { header: 'Original address:', address: originalAddress }"></ng-container>
    <ng-container *ngIf="validatedAddressView.answersWithMissingPieces; else unconfirmed">
      <h3>Missing information</h3>
      <bp-sdk-address-group
        class="missing-info"
        [answers]="validatedAddressView.answersWithMissingPieces"
        [renderValidation]="false"
        [disableAutoSave]="true"
      ></bp-sdk-address-group>
    </ng-container>
    <ng-template #unconfirmed>
      <div class="unconfirmed-container">
        <emperor-alert
          *ngFor="let component of validatedAddressView.unconfirmedAddressComponents"
          icon="interface_alert_triangle_1"
          [uiAlertType]="EmperorUIAlertType.Warn"
        >
          <h3>{{ mapToComponentTypeText(component.componentType) }} not confirmed:</h3>
          <p>{{ component.componentName.text }}</p>
        </emperor-alert>
      </div>
    </ng-template>
  </emperor-card-content>

  <emperor-card-footer class="footer" [color]="EmperorCardHeaderColor.NONE">
    <emperor-button
      class="emperor-ui-manually-set-size"
      *ngIf="validatedAddressView.answersWithMissingPieces"
      (click)="continueWithMissingInputFix()"
      [disabled]="isMissingInfoButtonDisabled()"
      >CONTINUE</emperor-button
    >
    <emperor-button class="emperor-ui-manually-set-size" *ngIf="!validatedAddressView.answersWithMissingPieces" (click)="editAddress()"
      >EDIT ADDRESS</emperor-button
    >
    <emperor-button class="emperor-ui-manually-set-size" (click)="continueAsEntered()" [uiButtonType]="EmperorUIButtonTypes.Stroked"
      >CONTINUE WITH ORIGINAL ADDRESS</emperor-button
    >
    <p>Note: By using a non-verified address, you may not receive an accurate quote.</p>
  </emperor-card-footer>
</ng-template>

<!-- TEMPLATES -->
<ng-template #addressDisplay let-header="header" let-address="address" let-isSuggested="isSuggested">
  <div class="address-block">
    <h3 [class.suggested-header]="isSuggested">{{ header }}</h3>
    <p>{{ address }}</p>
  </div>
</ng-template>
