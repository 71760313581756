import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { EmperorThemeColor } from '../../../models';
import { EmperorTooltipPlacement } from '../../../tooltip';
import { EmperorUIButtonTypes } from '../../models/button-types.model';

@Component({
  selector: 'emperor-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['../button-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkButtonComponent {
  @Input() href: string;
  @Input() uiButtonType = EmperorUIButtonTypes.Standard;
  @Input() type = 'button';
  @Input() color = EmperorThemeColor.PRIMARY;
  @Input() ariaLabel: string | undefined;
  @Input() id: string | undefined;
  @Input() tooltip: string | undefined;
  @Input() tooltipPlacement: EmperorTooltipPlacement = 'bottom';
  @Input() closeTooltip: Observable<void> | undefined;
  @Input() target: string | undefined;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();
  @Output() disabledClick = new EventEmitter();

  emperorUIButtonTypes = EmperorUIButtonTypes;
}
