import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { Observable } from 'rxjs';
import { EmperorThemeColor } from '../../../models';
import { EmperorTooltipPlacement } from '../../../tooltip';
import { EmperorUIButtonTypes } from '../../models/button-types.model';

@Component({
  selector: 'emperor-button',
  templateUrl: './button.component.html',
  styleUrls: ['../button-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements AfterViewInit {
  @ViewChild('button') button!: MatButton;
  @Input() uiButtonType = EmperorUIButtonTypes.Standard;
  @Input() type = 'button';
  @Input() set color(color: EmperorThemeColor | 'none') {
    this.materialColor = color === 'none' ? undefined : color;
  }
  @Input() disabled: boolean | undefined;
  @Input() ariaLabel: string | undefined;
  @Input() customAttributes: {name: string, value: string}[] = [];
  @Input() id: string | undefined;
  @Input() tooltip: string | undefined;
  @Input() tooltipPlacement: EmperorTooltipPlacement = 'bottom';
  @Input() closeTooltip: Observable<void> | undefined;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();
  @Output() disabledClick = new EventEmitter();

  emperorUIButtonTypes = EmperorUIButtonTypes;
  materialColor: ThemePalette = EmperorThemeColor.PRIMARY;

  ngAfterViewInit(): void {
    for(const attribute of this.customAttributes) {
      this.button._elementRef.nativeElement.setAttribute(attribute.name, attribute.value)
    }
  }

  onButtonClick($event: MouseEvent | KeyboardEvent) {
    $event.stopPropagation();
    if (this.disabled) {
      this.disabledClick.emit($event);
      return;
    }
    this.click.emit($event);
  }
}
