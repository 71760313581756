<emperor-increment-input
  [min]="min"
  [max]="max"
  [id]="answerId"
  [pattern]="pattern || ''"
  [placeholder]="placeholder"
  [label]="label"
  [formControl]="formControl"
  [id]="answerId"
  [ariaLabelledBy]="labelledBy"
  [errorText]="errorText"
  [inputLength]="inputLength"
  [hintWithIcon]="hintWithIcon"
  [hint]="helpText"
  [code]="code"
  [isReadOnly]="isReadOnly"
></emperor-increment-input>
