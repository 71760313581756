import { AppetiteMarkets, IChoice } from '@boldpenguin/sdk';
import { CoveragesMetadata, TCoverageType } from './coverage-types';

export class CoverageTypesUtils {
  /**
   * Gets product abbreviation from the product name
   *
   * @param productName product name
   * @returns product abbreviation if found, else the product name
   */
  public static getCoverageAbbreviation(productName: string): string {
    const abbr = CoveragesMetadata.getMetadata(productName)?.abbreviation;
    // Use the original name as a backup
    return abbr || productName;
  }

  /**
   * Gets product name from the product abbreviation
   *
   * @param productAbbr product abbreviation
   * @returns  product name if found, else the product abbreviation
   */
  public static getCoverageNameFromAbbreviation(productAbbr: string): string {
    const name = CoveragesMetadata.getMetadata(productAbbr)?.name;
    return name || productAbbr;
  }

  /**
   * Gets MQS coverage choice value from the product abbreviation
   * See "CoverageTypes": https://developers.boldpenguin.com/docs/api/mqs/
   *
   * @param productAbbr product abbreviation
   * @returns MQS product name if found, else the product abbreviation
   */
  public static getMqsCoverageTypeFromAbbreviation(productAbbr: string): string {
    const name = CoveragesMetadata.getMetadata(productAbbr)?.mqsCoverageType;
    // Use the original name as a backup
    return name || productAbbr;
  }

  /**
   * Gets Emperor icon name from by product
   *
   * @param product short abbreviation, human-friendly name, or an alternate abbreviation or name
   * @returns icon name if found, else empty string
   */
  public static getCoverageIcon(product: string): string {
    return CoveragesMetadata.getMetadata(product)?.icon || '';
  }

  /**
   * Gets coverage type from short code representing a product
   *
   * @param product short abbreviation, human-friendly name, or an alternate abbreviation or name
   * @returns coverage type if found
   */
  public static getCoverageType(product: string): TCoverageType | undefined {
    return CoveragesMetadata.getMetadataKey(product);
  }

  /**
   * Gets coverage type for a question choice
   *
   * @param choice question choice
   * @returns coverage type if found
   */
  public static getCoverageTypeFromChoice(choice: IChoice): TCoverageType | undefined {
    return CoveragesMetadata.getMetadataKey(choice.alias) || CoveragesMetadata.getMetadataKey(choice.value);
  }

  /**
   * Gets description for a question choice
   *
   * @param choice question choice
   * @returns description if found
   */
  public static getDescriptionFromChoice(choice: IChoice): string {
    return (CoveragesMetadata.getMetadata(choice.alias) || CoveragesMetadata.getMetadata(choice.value))?.description || '';
  }

  public static getProductKeyFromCoverageType(coverageType: TCoverageType, market: AppetiteMarkets = AppetiteMarkets.BP_QUOTABLE): string | undefined {
    const product = CoveragesMetadata.getProductKeyName(coverageType)
    return product ? this.getProductKeyFromProductName(product, market) : undefined
  }

  public static getProductKeyFromProductName(product: string, market: AppetiteMarkets = AppetiteMarkets.BP_QUOTABLE) {
    return `${product}:${market}`
  }
}
