<emperor-card>
  <emperor-quote-presentation
    [headline]="displayHeadline"
    [price]="displayPrice"
    [costCycle]="displayCostCycle"
    [carrierName]="carrier?.name || ''"
    [carrierLogo]="carrier?.logo_url || ''"
    [supplierLogo]="supplierLogoUrlFull ?? ''"
    [supplierName]="supplierName ?? ''"
    [disclaimerHtml]="disclaimerHtml"
    [lineItems]="lineItems"
    [showActionButton]="true"
    [showProvider]="showProvider"
    (actionButtonClicked)="onCheckoutButtonClick()"
    [actionButtonType]="actionButtonType"
    [buttonText]="buttonText"
    [attachments]="attachments"
    (attachmentDownloadClicked)="handleDownload($event.id, $event.filename)"
  ></emperor-quote-presentation>
</emperor-card>
