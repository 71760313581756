<div class="emperor-ui-radio-container">
  <label [id]="!!ariaLabelledBy ? ariaLabelledBy : id" *ngIf="!!label" [innerHTML]="label" class="emperor-ui-form-field-label"></label>
  <mat-radio-group
    class="emperor-ui-radio-group"
    [color]="color"
    [ngClass]="{ 'emperor-ui-supporting-text-radio-group': withSupportingText }"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledBy"
    [attr.data-test]="qaAttributeHook"
    [formControl]="control"
    (blur)="onBlur()"
    (focus)="onFocus()"
  >
    <mat-radio-button
      *ngFor="let choice of choices; trackBy: identifyRadioId"
      class="emperor-ui-radio-button"
      [value]="choice.value"
      [checked]="control.value === choice.value"
    >
      <span class="emperor-ui-choice-label">{{ choice.label }}</span>
      <ng-container *ngIf="choice.showExplanation">
        <p class="emperor-ui-small-text">{{ choice.explanation }}</p>
      </ng-container>
    </mat-radio-button>
  </mat-radio-group>

  <div class="emperor-ui-radio-subscript-wrapper">
    <ng-container *ngIf="showErrorState()">
      <mat-error>{{ showErrorMessages() }}</mat-error>
    </ng-container>
    <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
    <mat-hint *ngIf="!!hintWithIcon">
      <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
    </mat-hint>
  </div>
</div>
