import { FormGroup, FormArray } from '@angular/forms';

export const updateFormTreeValidity = (group: FormGroup | FormArray) => {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      updateFormTreeValidity(abstractControl);
    } else {
      abstractControl.updateValueAndValidity();
    }
  });
};
