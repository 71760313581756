<div class="emperor-ui-naics-header">
  <div class="emperor-ui-naics-heading">
    {{ naicsDescription }}
    <span class="emperor-ui-naics-code" *ngIf="naicsCode"> - {{ naicsCode }}</span>
    <emperor-icon (click)="showDialog()" name="interface_information_1" aria-label="Button that toggles naics explanation dialog">
    </emperor-icon>

    <span class="emperor-ui-naics-visual-divider"></span>
  </div>
</div>
