import { Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';
import { map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { enUS } from 'date-fns/locale'
import { MatDatepicker } from '@angular/material/datepicker';
import { QuestionFieldType } from '@boldpenguin/sdk';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/yyyy',
  },
  display: {
    dateInput: 'MM/yyyy',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM yyyy',
  },
};

@Component({
  selector: 'emperor-month-year-input',
  templateUrl: './month-year-input.component.html',
  styleUrls: ['./month-year-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthYearInputComponent),
      multi: true,
    },
    { provide: DateAdapter, useClass: DateFnsAdapter},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    { provide: MAT_DATE_LOCALE, useValue: enUS }
  ],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class MonthYearInputComponent extends InputFieldsetDisableDirective implements OnInit {
  @Input() icon: string;
  isMobileScreenSize$: Observable<boolean> = of(false);

  constructor(injector: Injector, sanitizer: DomSanitizer, elementRef: ElementRef, private breakpointObserver: BreakpointObserver) {
    super(injector, sanitizer, elementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isMobileScreenSize$ = this.breakpointObserver.observe('(max-width: 390px)').pipe(map(state => state.matches));
  }


  setMonthAndYear(normalizedMonthYear: Date, datepicker: MatDatepicker<Date>) {
    this.control.setValue(normalizedMonthYear);
    datepicker.close();
  }

  protected readonly QuestionFieldType = QuestionFieldType;
}
