<label [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>

<mat-form-field appearance="outline" class="emperor-ui-date-picker">

  <input
    matInput
    [matDatepicker]="picker"
    [max]="max"
    [min]="min"
    [id]="id || ''"
    [formControl]="control"
    [attr.aria-label]="ariaLabel ? ariaLabel : label"
    [attr.aria-labelledby]="ariaLabelledBy"
    [attr.data-test]="qaAttributeHook"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [placeholder]="placeholder ?? ''"
  />

  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <emperor-icon matDatepickerToggleIcon [name]="icon"></emperor-icon>
  </mat-datepicker-toggle>

  <mat-datepicker #picker
    [touchUi]="isMobileScreenSize$ | async"
    startView="multi-year"
    (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>

  <mat-error
    emperorErrorHandler
    [errorText]="errorText"
    [errorMessageMap]="errorMessageMap"
    [minDate]="min ? min.toString() : ''"
    [maxDate]="max ? max.toString() : ''"
    [id]="code || ''"
    [fieldType]=QuestionFieldType.MonthYear
  ></mat-error>

  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>

  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>

</mat-form-field>
