import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { SelectorWrapperService } from '@boldpenguin/emperor-services';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'emperor-bp-sdk-back-button',
  templateUrl: './bp-sdk-back-button.component.html',
  styleUrls: ['./bp-sdk-back-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkBackButtonComponent implements OnInit {
  /**
   * Back button text
   */
  @Input() text: string;
  /**
   * Icon name
   */
  @Input() icon = 'chevron-left';
  /**
   * Controls disabled state of button
   */
  @Input() buttonDisabled = false;
  /**
   * Extra CSS classes to be added onto the back button
   */
  @Input() cssClasses: string;

  closeTooltip$ = new Subject<void>();
  disabled$: Observable<boolean>;

  emperorButtonUiTypes = EmperorUIButtonTypes;

  constructor(private elementRef: ElementRef, private selectorWrapper: SelectorWrapperService) {}

  ngOnInit() {
    this.disabled$ = combineLatest({
      questionSets: this.selectorWrapper.getQuestionSets$(),
      currentActiveQuestionSetId: this.selectorWrapper.getCurrentActiveQuestionSetId$(),
      isActiveQuestionSetLoading: this.selectorWrapper.isActiveQuestionSetLoading$(),
    }).pipe(
      map(({ questionSets, currentActiveQuestionSetId, isActiveQuestionSetLoading }) => {
        return questionSets.findIndex(qs => qs.question_set_id === currentActiveQuestionSetId) !== 0 ? isActiveQuestionSetLoading : true;
      }),
    );
  }

  click() {
    this.closeTooltip$.next();
    this.elementRef.nativeElement.dispatchEvent(new CustomEvent('navigateBack', { bubbles: true }));
  }
}
