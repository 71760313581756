import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CoverageTypesUtils, TCoverageType } from '@boldpenguin/emperor-services';
import { IApplicationFormQuestionSet, IQuotesState, IRealTimeEligibilityCarrier } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, map } from 'rxjs';
import { IEmperorRealTimeEligibilityCarrier, isQuoteQuestionSetIncomplete } from '../../models';
import { RealTimeEligibilityService } from '../../services/real-time-eligibility.service';

interface IEligibleCarriersProduct {
  products: string[];
  quote?: IQuotesState;
}

@UntilDestroy()
@Component({
  selector: 'emperor-eligibility-carrier-card',
  templateUrl: './eligibility-carrier-card.component.html',
  styleUrls: ['./eligibility-carrier-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EligibilityCarrierCardComponent implements OnInit, OnChanges {
  @Input() bulkAddCarrierQuestionSetsEnabled: boolean;
  @Input() useRecommendedCarriersEnabled: boolean;

  @Output()
  goToQuote: EventEmitter<IQuotesState> = new EventEmitter();

  carriersProducts: IEligibleCarriersProduct[] = [];

  isQuestionSetCurrent$: Observable<boolean>;
  isQuestionSetCompleted$: Observable<boolean>;
  isQuestionSetInteractive$: Observable<boolean>;
  isQuestionSetEditable$: Observable<boolean>;

  private _carrierQuotes: Array<IQuotesState>;

  private carrierQuestionSet$: Observable<IApplicationFormQuestionSet | undefined>;
  private isQuestionSetActive$: Observable<boolean>;

  constructor(private realTimeEligibilityService: RealTimeEligibilityService) {}

  @Input()
  carrier: IEmperorRealTimeEligibilityCarrier;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get carrierQuotes(): Array<IQuotesState> {
    return this._carrierQuotes;
  }
  @Input()
  set carrierQuotes(newCarrierQuotes: Array<IQuotesState> | null) {
    if (newCarrierQuotes) {
      this.updateCarrierProducts(this.carrier, newCarrierQuotes);
      this._carrierQuotes = newCarrierQuotes;
    }
  }

  ngOnInit(): void {
    this.setupQuestionSetObservables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['carrier']) {
      this.updateCarrierProducts(this.carrier, this.carrierQuotes);
    }
  }

  goToCarrierQuestionSet(): void {
    this.realTimeEligibilityService.goToCarrierQuestionSet(this.carrier.id);
  }

  onGoToQuote(quote: IQuotesState): void {
    this.goToQuote.emit(quote);
  }

  private setupQuestionSetObservables(): void {
    this.carrierQuestionSet$ = this.realTimeEligibilityService.getCarrierQuestionSet$(this.carrier.id);

    this.isQuestionSetCurrent$ = combineLatest([
      this.carrierQuestionSet$,
      this.realTimeEligibilityService.currentActiveQuestionSetId$,
    ]).pipe(
      map(
        ([questionSet, activeQuestionSetId]: [IApplicationFormQuestionSet | undefined, string]) =>
          questionSet?.question_set_id === activeQuestionSetId,
      ),
      untilDestroyed(this),
    );

    this.isQuestionSetActive$ = this.carrierQuestionSet$.pipe(
      map(questionSet => !!questionSet?.is_active),
      untilDestroyed(this),
    );

    this.isQuestionSetCompleted$ = this.carrierQuestionSet$.pipe(
      map((questionSet: IApplicationFormQuestionSet | undefined) => !!questionSet?.is_completed),
      untilDestroyed(this),
    );

    this.isQuestionSetInteractive$ = combineLatest([this.isQuestionSetActive$, this.isQuestionSetCurrent$]).pipe(
      map(([isActive, isCurrent]) => isActive && !isCurrent && !this.carrier.displayDisabledState),
      untilDestroyed(this),
    );

    this.isQuestionSetEditable$ = this.carrierQuestionSet$.pipe(
      map(qs => !!qs),
      untilDestroyed(this),
    );
  }

  private updateCarrierProducts(carrier?: IRealTimeEligibilityCarrier, carrierQuotes?: Array<IQuotesState>) {
    if (!carrier?.products || !carrierQuotes) return;

    // merge unique products with and without quote
    const productsMap = new Map<string[], (IQuotesState | undefined)[]>();
    const distinctCoverageTypes = new Set<TCoverageType>();
    // filter out incomplete carrier question sets
    // that have been spoofed as quote requests
    // and sort most products (bundles) to top
    const sortedQuotes = carrierQuotes
      .filter(quote => !isQuoteQuestionSetIncomplete(quote))
      .sort((quoteA, quoteB) => quoteB.products.length - quoteA.products.length);
    for (const quote of sortedQuotes) {
      const products = quote.products.map(product => product.name);
      productsMap.set(products, (productsMap.get(products) || []).concat(quote));

      // identify unique coverage types already present in quotes
      const coverageTypes: TCoverageType[] = quote.products
        .map(product => CoverageTypesUtils.getCoverageType(product.from_name ?? product.name))
        .filter(Boolean) as TCoverageType[];
      for (const coverage of coverageTypes) {
        distinctCoverageTypes.add(coverage);
      }
    }
    // add any selected coverages missing from quotes
    for (const product of carrier.products) {
      const coverage = CoverageTypesUtils.getCoverageType(product.name);
      if (coverage && !distinctCoverageTypes.has(coverage)) {
        distinctCoverageTypes.add(coverage);
        productsMap.set([product.name], [undefined]);
      }
    }

    this.carriersProducts = Array.from(productsMap.entries()).reduce<IEligibleCarriersProduct[]>(
      (out, [products, quotes]) =>
        out.concat(
          quotes.map(quote => ({
            products,
            quote,
          })),
        ),
      [],
    );
  }
}
