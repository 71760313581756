<mat-card
  class="mat-card emperor-ui-eligibility-carrier-card mat-elevation-z"
  [ngClass]="{
    'card-active': (isQuestionSetCurrent$ | async)
  }"
>
  <emperor-eligibility-carrier-card-header
    [interactive]="!!(isQuestionSetInteractive$ | async)"
    (goToCarrierQuestionSet)="goToCarrierQuestionSet()"
  >
    <div class="emperor-ui-eligibility-card-header-text" [class.no-products]="!carriersProducts.length">
      <div class="emperor-ui-carrier-logo">
        <figure>
          <img class="image" [src]="carrier.logo_url" />
        </figure>
      </div>
      <div class="emperor-ui-carrier-name" [ngClass]="{ 'muted-text': carrier.displayDisabledState }">
        {{ carrier.name }}
      </div>
      <emperor-icon *ngIf="isQuestionSetEditable$ | async" class="emperor-ui-edit-icon" name="write_modify_tool_edit_pen"></emperor-icon>
      <emperor-icon
        *ngIf="carrier.message && (isQuestionSetEditable$ | async) === false"
        class="emperor-ui-rte-message-icon"
        name="interface_alert_circle_1"
        [emperorTooltip]="carrier.message"
      ></emperor-icon>
    </div>
    <div *ngIf="carrier.isAdditionalMarket" class="emperor-ui-additional-market">Additional market</div>
  </emperor-eligibility-carrier-card-header>

  <mat-card-content>
    <ng-container *ngFor="let carriersProduct of carriersProducts">
      <emperor-carrier-product-eligibility
        class="cemperor-ui-overage-type"
        [carrier]="carrier"
        [products]="carriersProduct.products"
        [quote]="carriersProduct.quote"
        [isQuestionSetCompleted]="!!(isQuestionSetCompleted$ | async)"
        [bulkAddCarrierQuestionSetsEnabled]="bulkAddCarrierQuestionSetsEnabled"
        [useRecommendedCarriersEnabled]="useRecommendedCarriersEnabled"
        (goToQuote)="onGoToQuote($event)"
      >
      </emperor-carrier-product-eligibility>
    </ng-container>
  </mat-card-content>
</mat-card>
