import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { NavButtonComponent } from './components/nav-button/nav-button.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';

export * from './components/nav-button/nav-button.component';
export * from './components/nav-link/nav-link.component';
@NgModule({
  imports: [CommonModule, MatButtonModule, MatRippleModule],
  declarations: [NavButtonComponent, NavLinkComponent],
  exports: [NavButtonComponent, NavLinkComponent],
})
export class EmperorNavButtonModule {}
