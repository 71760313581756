<div
  class="emperor-ui-carrier-product-eligibility-wrapper"
  [ngClass]="{ interactive }"
  [attr.role]="interactive ? 'button' : null"
  [attr.tabindex]="interactive ? 0 : null"
  (click)="interactive && handleProductClick()"
  (keydown.enter)="interactive && handleProductClick()"
  (keydown.space)="interactive && handleProductClick()"
>
  <ng-content></ng-content>
</div>
