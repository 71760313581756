import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

export * from './components/progress-bar/progress-bar.component';
@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent],
})
export class EmperorProgressBarModule {}
