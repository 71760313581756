import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { AlertComponent } from './components/alert/alert.component';
import { EmperorTooltipModule } from '../tooltip';
import { EmperorMessageAlertComponent } from './components/message-alert/message-alert.component';

@NgModule({
  declarations: [AlertComponent, EmperorMessageAlertComponent],
  imports: [CommonModule, IconsModule, EmperorTooltipModule],
  exports: [AlertComponent, EmperorMessageAlertComponent],
})
export class EmperorAlertModule {}
