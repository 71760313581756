import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmperorGoogleMapsAutocompleteDirective } from './directives/google-maps-autocomplete.directive';

export { EmperorGoogleMapsAutocompleteDirective } from './directives/google-maps-autocomplete.directive';
@NgModule({
  declarations: [EmperorGoogleMapsAutocompleteDirective],
  imports: [CommonModule],
  exports: [EmperorGoogleMapsAutocompleteDirective],
})
export class EmperorGoogleMapsAutocompleteModule {}
