import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmperorDialogRefService, EmperorDialogSize, EmperorThemeColor, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { ICarrier } from '@boldpenguin/sdk';
import { take } from 'rxjs';
import { DynamicCoverageDialogComponent } from '../dynamic-coverage-dialog/dynamic-coverage-dialog.component';

export enum DynamicCoveragesEvent {
  CANCELLED = 'CANCELLED',
  SELECTED = 'SELECTED',
}

@Component({
  selector: 'emperor-dynamic-coverage-button',
  templateUrl: './dynamic-coverage-button.component.html',
  styleUrls: ['./dynamic-coverage-button.component.scss'],
})
export class DynamicCoverageButtonComponent {
  @Input() quoteId: string;
  @Input() appFormId: string;
  @Input() carrier: ICarrier;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();
  @Output() dynamicCoveragesEvent = new EventEmitter<DynamicCoveragesEvent>();

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorThemeColor = EmperorThemeColor;

  constructor(private dialogService: EmperorDialogRefService) {}

  onClick(): void {
    this.click.emit();
    const dialogRef = this.dialogService.open(DynamicCoverageDialogComponent, {
      uiDialogSize: EmperorDialogSize.Small,
      backdropDismiss: false,
      data: {
        quoteId: this.quoteId,
        appFormId: this.appFormId,
        carrier: this.carrier,
      },
    });

    dialogRef.afterClosed.pipe(take(1)).subscribe(event => {
      this.dynamicCoveragesEvent.emit(event);
    });
  }
}
