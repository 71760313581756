import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmperorTooltipDirective } from './directive/tooltip.directive';
import { EmperorTooltipComponent } from './components/emperor-tooltip/emperor-tooltip.component';

export * from './directive/tooltip.directive';
export * from './components/emperor-tooltip/emperor-tooltip.component';
@NgModule({
  imports: [CommonModule],
  declarations: [EmperorTooltipDirective, EmperorTooltipComponent],
  exports: [EmperorTooltipDirective, EmperorTooltipComponent],
})
export class EmperorTooltipModule {}
