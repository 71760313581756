import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { EmperorIconRegistry } from './emperor-icon.registry';

@Component({
  selector: 'emperor-icon',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./emperor-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmperorIconComponent {
  private svgIcon: SVGElement;

  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.emperorIconRegistry.getIcon(iconName);
    if (svgData) {
      this.svgIcon = this.svgElementFromString(svgData);
      this.element.nativeElement.appendChild(this.svgIcon);
    }
  }

  constructor(private element: ElementRef, private emperorIconRegistry: EmperorIconRegistry) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = document.createElement('DIV');
    div.setAttribute('class', 'emperor-ui-icon');
    div.innerHTML = svgContent;
    return (div as unknown as SVGElement) || document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }
}
