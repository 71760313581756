<ng-container *ngIf="useBinaryRadio(); else radio">
  <emperor-boolean-input
    [choices]="(mappedChoices$ | async)!"
    [formControl]="formControl"
    [label]="label"
    [ariaLabel]="label"
    [ariaLabelledBy]="labelledBy"
    [id]="answerId || ''"
    [errorText]="errorText"
    [hint]="helpText"
    [hintWithIcon]="hintWithIcon"
    [code]="code"
    [isReadOnly]="isReadOnly"
  ></emperor-boolean-input>
</ng-container>

<ng-template #radio>
  <emperor-radio-button
    [choices]="(mappedChoices$ | async)!"
    [formControl]="formControl"
    [label]="label"
    [ariaLabel]="label"
    [ariaLabelledBy]="labelledBy"
    [errorText]="errorText"
    [hint]="helpText"
    [hintWithIcon]="hintWithIcon"
    [id]="answerId"
    [code]="code"
    [isReadOnly]="isReadOnly"
  ></emperor-radio-button>
</ng-template>
