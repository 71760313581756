<div class="emperor-ui-eligibility-panel">
  <ng-container *ngIf="bulkAddCarrierQuestionSetsEnabled$ | async">
    <emperor-button
      *ngIf="realTimeEligibilityService.shouldDisplayGoToQuotes$ | async"
      class="emperor-ui-go-to-quotes"
      (click)="onGoToQuote()"
      [uiButtonType]="EmperorUIButtonTypes.Stroked"
    >
      <div class="emperor-ui-action-content">
        GO TO QUOTES
        <emperor-icon class="icon" name="check_circle_2"></emperor-icon>
      </div>
    </emperor-button>
  </ng-container>
  <div class="emperor-ui-eligibility-header-content">
    <ng-content select="[rteHeaderContent]"></ng-content>
  </div>
  <div class="emperor-ui-main-content">
    <mat-expansion-panel [expanded]="true" class="mat-elevation-z">
      <mat-expansion-panel-header class="emperor-ui-section-header" *ngIf="(useRecommendedCarriersEnabled$ | async) === false">
        <mat-panel-title>Eligible carriers</mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngIf="eligibleCarriers$ | async as eligibleCarriers">
        <emperor-eligibility-carrier-card
          *ngFor="let carrier of eligibleCarriers; trackBy: trackByCarrierId"
          [class.emperor-ui-flat-eligibility-carrier-card]="useRecommendedCarriersEnabled$ | async"
          [carrier]="carrier"
          [bulkAddCarrierQuestionSetsEnabled]="!!(bulkAddCarrierQuestionSetsEnabled$ | async)"
          [useRecommendedCarriersEnabled]="!!(useRecommendedCarriersEnabled$ | async)"
          [carrierQuotes]="realTimeEligibilityService.getCarrierQuotes$(carrier.id) | async"
          (goToQuote)="onGoToQuote($event)"
        ></emperor-eligibility-carrier-card>
        <ng-content select="[rteCarriersContent]"></ng-content>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="true"
      class="mat-elevation-z"
      *ngIf="!!(ineligibleCarriers$ | async)?.length || (useRecommendedCarriersEnabled$ | async) === false"
    >
      <mat-expansion-panel-header class="emperor-ui-section-header">
        <mat-panel-title>Ineligible carriers</mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngIf="ineligibleCarriers$ | async as ineligibleCarriers">
        <emperor-eligibility-carrier-card
          *ngFor="let carrier of ineligibleCarriers; trackBy: trackByCarrierId"
          [carrier]="carrier"
          [bulkAddCarrierQuestionSetsEnabled]="!!(bulkAddCarrierQuestionSetsEnabled$ | async)"
        ></emperor-eligibility-carrier-card>

        <!-- when we remove useRecommendedCarriersEnabled it is safe to remove this div -->
        <div class="emperor-ui-no-carriers" *ngIf="!ineligibleCarriers.length">No ineligible carriers</div>
      </ng-container>
    </mat-expansion-panel>
  </div>
</div>
