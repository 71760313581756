<div
  class="emperor-checkbox-wrapper"
  [ngClass]="{
    checked: control?.value === true,
    outline: outline
  }"
>
  <ng-container *ngIf="!!supportingText || !!withSupportingText; else normalCheckbox">
    <div class="emperor-ui-checkbox-supporting-text">
      <mat-checkbox [formControl]="control" [labelPosition]="labelPosition" [color]="color" [attr.data-test]="qaAttributeHook">
        <span class="emperor-ui-large-text">
          {{ label }}
        </span>
        <p *ngIf="supportingText">{{ supportingText }}</p>
      </mat-checkbox>
    </div>
  </ng-container>

  <ng-template #normalCheckbox>
    <div
      class="emperor-ui-checkbox-container"
      [ngClass]="{
        outline: outline,
        'custom-label': customLabel
      }"
    >
      <mat-checkbox
        [formControl]="control"
        [labelPosition]="labelPosition"
        [color]="color"
        [attr.data-test]="qaAttributeHook"
        [aria-labelledby]="ariaLabelledBy ?? null"
        [aria-label]="ariaLabel ?? ''"
        [aria-describedby]="ariaDescribedBy ?? ''"
      >
        <div *ngIf="!customLabel" class="emperor-ui-checkbox-label" [innerHTML]="label"></div>
        <!-- Custom HTML Label -->
        <ng-content *ngIf="customLabel"></ng-content>
      </mat-checkbox>
    </div>
  </ng-template>
</div>
<ng-container *ngIf="showErrorState()">
  <mat-error>This is required</mat-error>
</ng-container>

<div class="emperor-ui-checkbox-subscript-wrapper" *ngIf="!excludeSubscript">
  <mat-hint class="emperor-ui-checkbox-hint" *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint class="emperor-ui-checkbox-hint" *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
</div>
