import { Directive, HostListener, Input, ElementRef, Optional, HostBinding } from '@angular/core';
import { EmperorDialogRef } from '../dialog-ref';

@Directive({
  selector: '[emperorDialogClose]',
})
export class EmperorDialogCloseDirective {
  @Input() emperorDialogClose;

  constructor(private elementRef: ElementRef, @Optional() private dialogRef?: EmperorDialogRef) {}

  @HostBinding('style.cursor')
  cursor = 'pointer';

  @HostListener('keydown.enter')
  onEnter() {
    this.onClose();
  }

  @HostListener('click')
  onClick() {
    this.onClose();
  }

  onClose() {
    const event = new CustomEvent('emperorDialogClose', {
      detail: this.emperorDialogClose,
      bubbles: true,
    });
    this.elementRef.nativeElement.dispatchEvent(event);
    this.dialogRef?.close();
  }
}
