import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmperorUIButtonTypes, QuotePresentationLineItemViewModel } from '@boldpenguin/emperor-presentational';
import {
  CarrierLabel,
  HandleAttachmentDownloadFunction,
  HandleBindFunction,
  ICallToAction,
  ICarrier,
  IProductDetail,
  IQuoteAttachment,
  IQuoteInfo,
  IQuoteRequestComponent,
  IQuoteStatus,
  ITranslatedMessage,
  QuoteRequestRequestTypes
} from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-quote-request',
  templateUrl: './bp-sdk-quote-request.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkQuoteRequestComponent implements IQuoteRequestComponent {
  /**
   * Application Form Id
   */
  @Input() appFormId: string | undefined;
  /**
   * Is quote bindable?
   */
  @Input() bindable: boolean | undefined;
  @Input() calls_to_action: ICallToAction[] | undefined;
  /**
   * Quote carrier object
   */
  @Input() carrier: ICarrier | undefined;
  /**
   * Custom logo base URL
   */
  @Input() customLogoBaseUrl: string | undefined;
  /**
   * Bind handling function passed by container
   * Call when "Bind" or equivalent is clicked
   */
  @Input() handleBind: HandleBindFunction | undefined;
  /**
   * Is this an annual/yearly quote?
   */
  @Input() isAnnual: boolean | undefined;
  /**
   * Product/coverages object array
   */
  @Input() products: IProductDetail[] | undefined;
  /**
   * Quote ID
   */
  @Input() quoteId: string | undefined;
  /**
   * Quote info including price, installments, details, etc.
   */
  @Input() quoteInfo: IQuoteInfo | undefined;
  /**
   * Quote status (recommended, selected, focused, etc.)
   */
  @Input() quoteStatus: IQuoteStatus | undefined;
  /**
   * Should show custom logo?
   */
  @Input() showCustomLogo: boolean | undefined;
  /**
   * Should show logo?
   */
  @Input() showLogo: boolean | undefined;
  /**
   * Array of translated error messages
   */
  @Input() translatedMessages: ITranslatedMessage[] | undefined;
  /**
   * Headline for top of quote card
   */
  @Input() headline: string;
  /**
   * Request status of quote request
   */
  @Input() requestStatus: QuoteRequestRequestTypes | undefined;
  /**
   * Files attached to the quote
   */
  @Input() attachments: IQuoteAttachment[];
  /**
   * Function that handles downloading the quote attachments
   */
  @Input() handleDownload: HandleAttachmentDownloadFunction;
  /**
   * User has permission to bind
   */
  @Input() permittedToBind: boolean | undefined;
  /**
   * Quote request satisfies all products selected on application form
   */
  @Input() satisfiesApplicationFormProductRequest: boolean | undefined;
  @Input() supplierName?: string | null;
  @Input() supplierLogoUrl?: string | null;
  @Input() supplierLogoUrlFull?: string | null;
  @Input() supplierCode?: string | null;
  @Input() parentQuoteRequestId?: string | null;

  bindWindow: Window | null;
  actions: any[];
  handleAction: any;

  onCheckoutButtonClick() {
    if (this.bindable && this.handleBind) {
      const quoteWindow = window.open('about:blank', '_blank');
      this.bindWindow = quoteWindow;

      this.handleBind(this.parentQuoteRequestId || this.quoteId || '', this.appFormId || '', bindUrl => {
        this.bindWindow?.location.assign(bindUrl);
      });
    }
  }

  get disclaimerHtml(): string {
    return this.quoteInfo?.price_details?.disclaimer_html || '';
  }

  get displayPrice(): string {
    return this.quoteInfo?.annual_price || this.quoteInfo?.monthly_price || '0';
  }

  get displayCostCycle(): string {
    return this.quoteInfo?.annual_price ? 'per year' : 'per month';
  }

  get displayHeadline(): string {
    return this.headline;
  }

  get actionButtonType(): EmperorUIButtonTypes {
    return this.bindable ? EmperorUIButtonTypes.Large : EmperorUIButtonTypes.StrokedLarge;
  }

  get carrierCode(): string {
    return this.carrier?.code || '';
  }

  get buttonText(): string {
    return this.bindable ? `CONTINUE TO BIND` : `CONTINUE`;
  }

  get lineItems(): QuotePresentationLineItemViewModel[] {
    // TODO: AMZ is using quote request display as a place to show coverages, etc. and can only have a single product.
    // but terminal mocks show this as aggregate metrics about the quote. Preferring AMZ style for now but we will need to revisit.
    const product = this.products?.length ? this.products[0] : null;
    let lineItems: QuotePresentationLineItemViewModel[] = [];

    if (product) {
      const limits = product.coverage?.limit_and_deductible?.length
        ? product.coverage?.limit_and_deductible
        : product.coverage?.limit_and_deductible_default || [];

      lineItems = limits.reduce(
        (acc, limit) => [
          ...acc,
          {
            icon: 'shield_cash',
            price: limit.value,
            name: limit.description,
          },
        ],
        [],
      );
    }

    lineItems.push({
      icon: 'file_view_2',
      price: this.quoteInfo?.quote_number || '',
      name: 'Quote ID',
    });

    return lineItems;
  }

  get showProvider(): boolean {
    // copied from RTE
    return this.carrier?.labels?.includes(CarrierLabel.ADDITIONAL_MARKET) || false;
  }
}
