export const CoverageLimitsAccordionStartStatusKey = 'emperor-coverage-limits-accordion-start-status';

interface ApplicationExpansionState {
  [applicationId: string]: boolean;
}

const defaultState = '{}';

export function markCoverageLimitAccordionsAsExpanded(applicationId: string): void {
  setCoverageLimitExpansionState(applicationId, true);
}

export function shouldCoverageLimitAccordionsBeExpanded(applicationId: string): boolean {
  const localStorageData = getLocalStorageData();
  return localStorageData[applicationId] ?? false;
}

function setCoverageLimitExpansionState(applicationId: string, state: boolean): void {
  const localStorageData = getLocalStorageData();
  localStorageData[applicationId] = state;
  localStorage.setItem(CoverageLimitsAccordionStartStatusKey, JSON.stringify(localStorageData));
}

function getLocalStorageData(): ApplicationExpansionState {
  const localStorageData = localStorage.getItem(CoverageLimitsAccordionStartStatusKey) ?? defaultState;
  return JSON.parse(localStorageData) ?? {};
}
