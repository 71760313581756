import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { MapsAPILoader } from '@ng-maps/core';

import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;

@Directive({
  selector: '[emperorGoogleMapsAutocomplete]',
  exportAs: 'emperorGoogleMapsAutocomplete',
})
export class EmperorGoogleMapsAutocompleteDirective implements OnInit, OnDestroy {
  @Input() address: PlaceResult | string;
  @Input() placeIdOnly?: boolean;
  @Input() autoCompleteOptions: AutocompleteOptions = {
    fields: [
      'address_components',
      'adr_address',
      'formatted_address',
      'geometry',
      'name',
      'place_id',
      'reference',
      'types',
      'url',
      'vicinity',
    ],
  };

  @Output() autocompleteSelected = new EventEmitter<PlaceResult>();
  @Output() locationSelected = new EventEmitter<{
    latitude: number;
    longitude: number;
  }>();

  value: PlaceResult;
  autocomplete: google.maps.places.Autocomplete;

  constructor(
    @Inject(PLATFORM_ID) public platformId: string,
    public elementRef: ElementRef,
    private mapsAPILoader: MapsAPILoader,
    private zone: NgZone,
  ) {}

  getAutocompletePacContainer(autocomplete) {
    // https://stackoverflow.com/questions/21024090/google-places-autocomplete-how-to-clean-up-pac-container
    const place: Record<string, any> = autocomplete.gm_accessors_?.place;

    const placeKey = Object.keys(place).find(
      value => typeof place[value] === 'object' && Object.prototype.hasOwnProperty.call(place[value], 'gm_accessors_'),
    );

    if (placeKey) {
      const input = place[placeKey]?.gm_accessors_?.input[placeKey];

      const inputKey = Object.keys(input).find(value => input[value].classList && input[value].classList.contains('pac-container'));

      if (inputKey) {
        return input[inputKey];
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyGoogleMapsAutocomplete();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const options: AutocompleteOptions = {
        placeIdOnly: this.placeIdOnly,
        types: ['premise', 'street_address'],
        componentRestrictions: { country: ['us'] },
      };
      this.autoCompleteOptions = { ...options, ...this.autoCompleteOptions };
      this.initGoogleMapsAutocomplete();
    }
  }

  private initGoogleMapsAutocomplete() {
    this.mapsAPILoader
      .load()
      .then(() => {
        this.autocomplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement, this.autoCompleteOptions);

        this.autocomplete?.addListener('place_changed', () => {
          this.zone.run(() => {
            const place: PlaceResult = this.autocomplete?.getPlace();

            if (!!place.place_id && !!place.geometry?.location) {
              this.value = place;
              this.address = place.formatted_address as string;
              this.autocompleteSelected.emit(place);
              this.locationSelected.emit({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              });
            }
          });
        });
      })
      .catch(error => console.error(error));
  }

  private destroyGoogleMapsAutocomplete() {
    if (this.autocomplete) {
      const pacContainer = this.getAutocompletePacContainer(this.autocomplete);
      if (pacContainer && pacContainer.remove) {
        pacContainer.remove();
      }

      this.autocomplete.unbindAll();
    }
  }
}
