import { ChangeDetectionStrategy, Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmperorDialogConfig, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';

@Component({
  selector: 'emperor-bcs-naics-header-dialog',
  templateUrl: './bcs-naics-header-dialog.component.html',
  styleUrls: ['./bcs-naics-header-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BcsNaicsHeaderDialogComponent {
  showDialog = false;

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;

  constructor(
    private sanitizer: DomSanitizer,
    public config: EmperorDialogConfig,
  ) {}

  sanitizedExplanation(): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.config.data?.naicsExplanation) ?? '';
  }
}
