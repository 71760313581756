<div
  class="emperor-ui-progress-bar-container"
  role="progressbar"
  aria-label="Application completion"
  aria-valuemin="0"
  aria-valuemax="100"
  [attr.aria-valuenow]="progressPercent"
>
  <div *ngIf="!indeterminate; else indeterminateBar" class="emperor-ui-progress-bar" [ngStyle]="{ 'width.%': progressPercent }"></div>

  <ng-template #indeterminateBar>
    <div class="emperor-ui-indeterminate-progress-bar increasing"></div>
    <div class="emperor-ui-indeterminate-progress-bar decreasing"></div>
  </ng-template>
</div>
