import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EmperorThemeColor } from '../models';
import { EmperorDialogSize } from './models/dialog-size.model';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';

@Component({
  selector: 'emperor-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorDialogComponent implements AfterViewInit {
  @Input() color: EmperorThemeColor;
  @Input() backdropDismiss = true;
  @Input() uiDialogSize: EmperorDialogSize | undefined;
  @Input() ariaLabelledBy: string;
  @Input() ariaDescribeby: string;

  @HostBinding('class.emperor-ui-dialog-small') get small() {
    return this.uiDialogSize === EmperorDialogSize.Small;
  }
  @HostBinding('class.emperor-ui-dialog-medium') get medium() {
    return this.uiDialogSize === EmperorDialogSize.Medium;
  }
  @HostBinding('class.emperor-ui-dialog-large') get large() {
    return this.uiDialogSize === EmperorDialogSize.Large;
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @ViewChild('card', { read: ElementRef }) cardElement: ElementRef;
  overlayScrollStrategy: ScrollStrategy;
  private focusTrap;

  constructor(private overlay: Overlay, private focusTrapFactory: ConfigurableFocusTrapFactory) {
    this.overlayScrollStrategy = overlay.scrollStrategies.block();
  }

  ngAfterViewInit() {
    this.focusTrap = this.focusTrapFactory.create(this.cardElement.nativeElement);
    this.focusTrap.focusFirstTabbableElementWhenReady();
  }

  // case for hitting escape key
  @HostListener('document:keydown.escape', ['$event']) onEscapeKeydownHandler(event: KeyboardEvent) {
    event && event.stopPropagation();
    this.close.emit();
  }

  // handling clicks and detecting outside clicked
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const classes = Array.from((event?.target as HTMLElement | undefined)?.classList || []);
    if (this.backdropDismiss) {
      if (classes.includes('backdrop')) {
        this.close.emit();
      }
    } else {
      event && event.stopPropagation();
    }
  }

  @HostListener('emperorDialogClose', ['$event'])
  onDialogCloseClick(event: MouseEvent) {
    event && event.stopPropagation();
    this.close.emit(event?.detail);
  }
}
