import IMask from 'imask';

export const CurrencyMaskOptions = {
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
    },
  },
};

export const CommaDelimitedNumberMaskOptions = {
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
    },
  },
};

export const PercentageMaskOptions = {
  mask: Number,
  min: 0,
  max: 100,
};

export const PhoneNumberMaskOptions = {
  mask: '(000) 000-0000',
};

export const FeinMaskOptions = {
  mask: '##-###0000',
  definitions: {
    '#': /[*\d]/,
  },
};

export const SsnMaskOptions = {
  mask: '###-##-0000',
  definitions: {
    '#': /[*\d]/,
  },
};

export const NoMaskOptions = { mask: /.*/ };

export const timeMask = {
  overwrite: false,
  mask: 'h{:}mm A',
  lazy: true,
  blocks: {
    h: {
      mask: value => {
        if (isNaN(value)) {
          return false;
        }
        const intValue = parseInt(value, 10);
        return value.length < 3 && 0 <= intValue && intValue <= 12;
      },
      maxLength: 2,
    },
    mm: {
      mask: IMask.MaskedRange,
      placeholderChar: '0',
      from: 0,
      to: 59,
      maxLength: 2,
    },
    A: {
      mask: IMask.MaskedEnum,
      enum: ['AM', 'am', 'aM', 'Am', 'PM', 'pm', 'pM', 'Pm'],
    },
  },
};

export interface IIconPrefix {
  showPrefix: boolean;
  icon: string;
}
