import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { arraysEqual } from '@boldpenguin/emperor-common';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';
import { IChoice, IRadioInputComponent } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { convertChoicesToGenericChoiceType } from '../../utils/choiceToGenericType';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-radio-input',
  templateUrl: './bp-sdk-radio-input.component.html',
  styleUrls: ['./bp-sdk-radio-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkRadioInputComponent extends BpSdkBaseComponentComponent implements OnInit, OnChanges, IRadioInputComponent {
  /**
   * Selected choice
   */
  @Input() choiceId: string | null;
  /**
   * Choices to display
   */
  @Input() choices: IChoice[] = [];
  /**
   * Help text
   */
  @Input() helpText: string;

  mappedChoices$ = new BehaviorSubject<EmperorUISelectFormChoice[]>([]);

  ngOnInit(): void {
    super.ngOnInit();

    this.mapChoices(this.choices, []);

    if (this.choiceId) {
      this.formControl.setValue(this.choiceId, { emitEvent: false });
    }

    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.elementRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { detail: value, bubbles: true }));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.choices && !changes.choices.firstChange) {
      this.mapChoices(changes.choices.currentValue, changes.choices.previousValue);
    }
  }

  useBinaryRadio() {
    return this.choices?.length === 2 && this.choices.every(c => c.value.length <= 3);
  }

  private mapChoices(currentChoices: IChoice[], previousChoices: IChoice[]) {
    if (Array.isArray(currentChoices) && Array.isArray(previousChoices)) {
      const formattedChoices = convertChoicesToGenericChoiceType(currentChoices, false, this.answerId);
      const formattedCurrentChoices = convertChoicesToGenericChoiceType(previousChoices, false, this.answerId);
      if (!arraysEqual(formattedChoices, formattedCurrentChoices)) {
        this.mappedChoices$.next(formattedChoices);
      }
    }
  }
}
