import { Component, EventEmitter, forwardRef, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';

@Component({
  selector: 'emperor-increment-input',
  templateUrl: './increment-input.component.html',
  styleUrls: ['./increment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncrementInputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class IncrementInputComponent extends InputFieldsetDisableDirective {
  @HostBinding('style.--width')
  @Input()
  inputLength = 1;
  emperorUIButtonTypes = EmperorUIButtonTypes;
  @Output() buttonClick = new EventEmitter<'add' | 'subtract'>();

  add() {
    this.buttonClick.emit('add');
    if (this.formControl?.disabled) return;
    let current: string | number = this.control.value ? this.control.value : 0;
    if (typeof current === 'string') {
      current = parseInt(current, 10);
    }
    if (!!this.max && current >= this.max) {
      return;
    }

    current = current + 1;
    this.control.markAsDirty();
    this.control.setValue(current.toString());
    this.inputLength = current.toString().length;
  }

  subtract() {
    this.buttonClick.emit('subtract');
    if (this.formControl?.disabled) return;
    let current: string | number = this.control.value ? this.control.value : 0;
    if (typeof current === 'string') {
      current = parseInt(current, 10);
    }
    if (current === 0 || (!!this.min && current <= this.min)) {
      return;
    }
    current = current - 1;
    this.control.markAsDirty();
    this.control.setValue(current.toString());
    this.inputLength = current.toString().length;
  }
}
