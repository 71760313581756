export enum EmperorThemeColor {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn',
}

export enum EmperorCardHeaderColor {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn',
  NONE = 'none',
}
