import { AfterViewInit, Component, Injector, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { BPErrorMessage } from '../utils/input-error-state';
import { QuestionFieldType } from '@boldpenguin/sdk';

@UntilDestroy()
@Component({
  selector: '[emperorErrorHandler]',
  template: '{{ error }}',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix, rxjs-angular/prefer-takeuntil
export class ErrorHandlerDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() errorText: string | null;
  @Input() errorMessageMap: { [index: string]: string } | null;
  @Input() minDate: string | null = null;
  @Input() maxDate: string | null = null;
  @Input() id: string | null = null;
  @Input() fieldType: QuestionFieldType | undefined = undefined;

  error = '';
  formFieldControl: MatFormFieldControl<MatInput>;
  formFieldControlStatus$: Subscription | null | undefined;

  constructor(private injector: Injector) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['errorText'] && changes['errorText'].currentValue) {
      this.setError(changes['errorText'].currentValue);
    }
    if (changes['id'] && !changes['id'].firstChange) {
      this.ngOnDestroy();
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    this.formFieldControl = this.injector.get(MatFormField)?._control;
    this.formFieldControlStatus$ = this.formFieldControl.ngControl?.statusChanges?.pipe(untilDestroyed(this)).subscribe(state => {
      if (state === 'INVALID') {
        this.setError(this.errorText);
      }
    });
  }

  private setError(errorText: string | null) {
    this.error = errorText
      ? errorText
      : BPErrorMessage(this.formFieldControl.ngControl?.errors, errorText, this.errorMessageMap, this.minDate, this.maxDate, this.fieldType);
  }

  ngOnDestroy(): void {
    this.formFieldControlStatus$?.unsubscribe();
  }
}
