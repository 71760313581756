import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EmperorTooltipComponentData, EmperorTooltipPlacement } from '../../models/tooltip.model';

@Component({
  selector: 'emperor-tooltip',
  templateUrl: './emperor-tooltip.component.html',
  styleUrls: ['./emperor-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorTooltipComponent implements OnInit {
  @HostBinding('class') class = 'emperor-tooltip-container';
  _show = false;
  placement: EmperorTooltipPlacement;
  element: HTMLElement;
  elementPosition: DOMRect;
  // Hard Coding offset
  tooltipOffset = 8;
  triangleLeft;

  @Input() data: EmperorTooltipComponentData;

  @HostBinding('style.top') hostStyleTop: string;
  @HostBinding('style.left') hostStyleLeft: string;
  @HostBinding('style.transition-duration.ms')
  hostStyleTransitionDuration: number;
  @HostBinding('class.emperor-ui-tooltip-show') hostClassShow: boolean;
  @HostBinding('class.emperor-ui-tooltip-light') hostClassLight: boolean;

  @Input() set show(value: boolean) {
    if (value) {
      this.setPosition();
      this.hostStyleTransitionDuration = this.data.options.animationDuration;
    } else {
      this.hostStyleTransitionDuration = 0;
    }
    this._show = this.hostClassShow = value;
  }
  get show(): boolean {
    return this._show;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const {
      element,
      elementPosition,
      options: { placement },
    } = { ...this.data };
    this.placement = placement;
    this.element = element;
    this.elementPosition = elementPosition;
  }

  setPosition() {
    this.setHostStyle(this.placement);
    this.setPlacementClass(this.placement);
  }

  setPlacementClass(placement: string) {
    this.renderer.addClass(this.elementRef.nativeElement, 'emperor-ui-tooltip-' + placement);
    this.renderer.addClass(this.elementRef.nativeElement, 'emperor-ui-tooltip');
  }

  setHostStyle(placement: EmperorTooltipPlacement) {
    const isSvg = this.element instanceof SVGElement;
    const tooltip = this.elementRef.nativeElement;

    const elementHeight = isSvg ? this.element.getBoundingClientRect().height : this.element.offsetHeight;
    const elementWidth = isSvg ? this.element.getBoundingClientRect().width : this.element.offsetWidth;
    const tooltipHeight = tooltip.clientHeight;
    const tooltipWidth = tooltip.clientWidth;
    const scrollY = window.pageYOffset;

    let topStyle;
    let leftStyle;

    switch (placement) {
      case 'top':
        topStyle = this.elementPosition.top + scrollY - (tooltipHeight + this.tooltipOffset);
        leftStyle = this.getLeftPlacementForBottomAndTop(tooltipWidth);

        this.triangleLeft = this.getTriangleLeft(leftStyle, elementWidth);
        break;

      case 'bottom':
        topStyle = this.elementPosition.top + scrollY + elementHeight + this.tooltipOffset;
        leftStyle = this.getLeftPlacementForBottomAndTop(tooltipWidth);

        this.triangleLeft = this.getTriangleLeft(leftStyle, elementWidth);
        break;

      case 'left':
        leftStyle = this.elementPosition.left - tooltipWidth - this.tooltipOffset;
        topStyle = this.elementPosition.top + scrollY + elementHeight / 2 - tooltip.clientHeight / 2;
        break;
      case 'right':
        leftStyle = this.elementPosition.left + elementWidth + this.tooltipOffset;
        topStyle = this.elementPosition.top + scrollY + elementHeight / 2 - tooltip.clientHeight / 2;
        break;
      default:
        topStyle = this.elementPosition.top + scrollY - (tooltipHeight + this.tooltipOffset);
        leftStyle = this.elementPosition.left + elementWidth / 2 - tooltipWidth / 2;
    }

    this.hostStyleTop = `${topStyle}px`;
    this.hostStyleLeft = `${leftStyle}px`;
    this.cd.detectChanges();
  }

  private getLeftPlacementForBottomAndTop(tooltipWidth: number): number {
    const viewWidth = Math.max(document.documentElement.clientWidth || 0, document.body.clientWidth || 0);
    const elementMidpoint = this.getElementMidpoint();
    const leftStyle = elementMidpoint - tooltipWidth / 2;

    // If it's past the left edge, just set it to the left edge.
    if (leftStyle < 0) {
      return 0;
    }

    // If it's past the right edge, set it to the right edge.
    if (leftStyle + tooltipWidth > viewWidth) {
      return viewWidth - tooltipWidth;
    }

    return leftStyle;
  }

  private getTriangleLeft(leftStyle: number, elementWidth: number): number {
    return this.elementPosition.left - leftStyle + elementWidth / 2;
  }

  private getElementMidpoint(): number {
    return this.elementPosition.x + this.elementPosition.width / 2;
  }
}
