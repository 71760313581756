import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EmperorAlertIconMap, EmperorUIAlertType } from '../../../alert';
import { CarouselNavigationMode } from '../../models/carousel.model';

@Component({
  selector: 'emperor-alert-carousel',
  templateUrl: './alert-carousel.component.html',
  styleUrls: ['./alert-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertCarouselComponent implements OnInit, OnChanges {
  @HostBinding('class') cssClass = '';
  @HostBinding('class.has-navigation') cssClassNavigation = false;
  @Input() navigationMode: CarouselNavigationMode = 'endless';
  @Input() alertType = EmperorUIAlertType.Success;
  @Input() icon: string | undefined;
  @Input() alerts: string[] = [];

  selectedAlert: string;
  selectedIndex = 0;
  shouldDisplayNavigation = false;
  iconName = this.getIconNameWithAlertTypeFallback();

  ngOnInit(): void {
    this.selectedAlert = this.getSelectedAlert();
    this.shouldDisplayNavigation = this.hasMultipleAlerts();
    this.iconName = this.getIconNameWithAlertTypeFallback();
    this.updateHostCssClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alerts && !changes.alerts.firstChange) {
      this.selectedIndex = 0;
      this.selectedAlert = this.getSelectedAlert();
      this.shouldDisplayNavigation = this.hasMultipleAlerts();
      this.updateHostCssClasses();
    }

    if (changes.alertType && !changes.alertType.firstChange) {
      this.iconName = this.getIconNameWithAlertTypeFallback();
      this.updateHostCssClasses();
    }
  }

  onChangeIndex(index: number): void {
    this.selectedIndex = index;
    this.selectedAlert = this.getSelectedAlert();
  }

  /**
   * Order of precedence is: icon Input, alert type default
   */
  private getIconNameWithAlertTypeFallback(): string {
    return this.icon ?? EmperorAlertIconMap[this.alertType];
  }

  private updateHostCssClasses(): void {
    this.cssClass = this.alertType;
    this.cssClassNavigation = this.shouldDisplayNavigation;
  }

  private getSelectedAlert(): string {
    return this.alerts[this.selectedIndex] ?? '';
  }

  private hasMultipleAlerts(): boolean {
    return this.alerts.length > 1;
  }
}
