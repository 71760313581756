import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TimelineStepStatus, ITimelineStep } from '../../models/timeline.model';
import { EmperorUIButtonTypes } from '../../../button';

@Component({
  selector: 'emperor-timeline-task',
  templateUrl: './timeline-task.component.html',
  styleUrls: ['./timeline-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimelineTaskComponent {
  readonly TimelineStepStatus = TimelineStepStatus;
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;

  @Input() step: ITimelineStep;
  @Input() order: number;

  get disabled(): boolean {
    return !!this.step?.disabled;
  }

  stepClicked() {
    if (this.step?.callback && !this.disabled) {
      this.step.callback();
    }
  }
}
