<emperor-button
  [uiButtonType]="emperorButtonUiTypes.FabLarge"
  [disabled]="(disabled$ | async)!"
  (click)="click()"
  tooltip="Go to previous step"
  tooltipPlacement="top"
  [closeTooltip]="closeTooltip$"
>
  <emperor-icon name="arrow_back"></emperor-icon>
</emperor-button>
