import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EmperorThemeColor } from '../../../models';

@Component({
  selector: 'emperor-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavButtonComponent {
  @Input() type = 'button';
  @Input() color: EmperorThemeColor = EmperorThemeColor.PRIMARY;
  @Input() highlight = true;
  @Input() disabled: boolean | undefined;
  @Input() ariaLabel: string | undefined;
  @Input() id: string | undefined;
  @Input() stopPropagation = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();

  onButtonClick($event: MouseEvent | KeyboardEvent) {
    if (this.stopPropagation || this.disabled) $event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.click.emit($event);
  }
}
