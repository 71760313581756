import { Injectable } from '@angular/core';
import { arraysEqual } from '@boldpenguin/emperor-common';
import { CoverageTypesUtils, SdkStoreService, SelectorWrapperService, TCoverageType } from '@boldpenguin/emperor-services';
import {
  CarrierLabel,
  IApplicationFormQuestionSet,
  IQuotesState,
  IRealTimeEligibilityCarrier,
  QuoteRequestRequestTypes,
  goToCarrierQuestionSet,
  markActiveQuestionSetCompleted,
} from '@boldpenguin/sdk';
import { Observable, combineLatest, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';
import {
  CarrierProductStatus,
  EligibleCarrierStatus,
  IEligibleCarrierStatusInfo,
  IEmperorRealTimeEligibilityCarrier,
  ProductFeatureType,
  getQuoteStatus,
  isQuoteErrored,
  isQuoteSuccessful,
} from '../models';

export interface IRealTimeEligibilityService {
  shouldDisplayGoToQuotes$: Observable<boolean>;
  goToCarrierQuestionSet: (carrierName: string) => void;
  getEligibleCarriers$: () => Observable<Array<IRealTimeEligibilityCarrier>>;
  getIneligibleCarriers$: () => Observable<Array<IRealTimeEligibilityCarrier>>;
  getCarrierQuotes$(carrier: string): Observable<Array<IQuotesState>>;
  getCarrierProductStatus$(
    quote: IQuotesState,
    carrierId: string,
    hasPreferredQuotesEnabled: boolean,
  ): Observable<IEligibleCarrierStatusInfo | null>;
  getCarrierQuestionSet$(carrierId: string): Observable<IApplicationFormQuestionSet | undefined>;
}

@Injectable()
export class RealTimeEligibilityService implements IRealTimeEligibilityService {
  currentActiveQuestionSetId$ = this.selectorWrapper.getCurrentActiveQuestionSetId$();
  public shouldDisplayGoToQuotes$: Observable<boolean>;
  public allSelectedQuotes$: Observable<IQuotesState[]>;
  public shouldUseRecommendedCarriers$: Observable<boolean>;
  public selectedCoverages$: Observable<string[]>;

  constructor(private selectorWrapper: SelectorWrapperService, private storeService: SdkStoreService) {
    this.shouldUseRecommendedCarriers$ = this.selectorWrapper
      .isFeatureEnabled$(ProductFeatureType.UseRecommendedCarriers)
      .pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: false }));

    this.selectedCoverages$ = combineLatest({
      coverages: this.selectorWrapper.getCoverageTypes$(),
      isFormLoaded: this.selectorWrapper.isFormLoaded$(),
    }).pipe(
      filter(({ isFormLoaded }) => isFormLoaded),
      map(({ coverages }) => coverages),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.allSelectedQuotes$ = combineLatest({
      quotes: this.selectorWrapper.selectAllQuotes$(),
      selectedCoverages: this.selectedCoverages$,
      rteCarriers: this.selectorWrapper.getAllRTECarriers$(),
      shouldUseRecommendedCarriers: this.shouldUseRecommendedCarriers$,
    }).pipe(
      map(({ quotes, selectedCoverages, rteCarriers, shouldUseRecommendedCarriers }) => {
        let result: IQuotesState[];
        // User selection for coverage types can drift from server state
        // and quote requests (online and mapped). Filter to quotes aligned with
        // their coverage types answer.
        const selectedCoverageTypes = this.mapCoverageTypes(selectedCoverages);

        result = quotes.filter(quote =>
          quote.products.every(
            product =>
              this.hasCoverageType(product.from_name ?? product.name, selectedCoverageTypes),
          ),
        );

        if (shouldUseRecommendedCarriers) {
          /*
           * If the user doesn't have RC enable, we shouldn't filter quotes by bucket data.
           */
          const eligibleEvaluatedCarriers = rteCarriers
            .filter(carrier => carrier.eligible && carrier.products.some(product => product.evaluated))
            .map(carrier => carrier.code);

          result = result
            .filter(quote => eligibleEvaluatedCarriers.includes(quote.carrier.code))
            .filter(quote => {
              const rteCarrier = rteCarriers.find(carrier => carrier.id === quote.carrier.id);
              const mappedRteCarrierProducts = this.mapCoverageTypes((rteCarrier?.products || [])?.map(p => p.name));

              return quote.products.every(
                product =>
                  this.hasCoverageType(product.from_name ?? product.name, mappedRteCarrierProducts)
              );
            });
        }

        return result;
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.shouldDisplayGoToQuotes$ = this.allSelectedQuotes$.pipe(
      map(quotes => quotes.some(quote => isQuoteSuccessful(quote) || isQuoteErrored(quote))),
    );
  }

  getEligibleCarriers$(): Observable<IRealTimeEligibilityCarrier[]> {
    return combineLatest({
      carriers: this.selectorWrapper.getAllRTECarriers$(),
      selectedCoverages: this.selectedCoverages$,
      shouldUseRecommendedCarriers: this.shouldUseRecommendedCarriers$,
      allQuestionSets: this.selectorWrapper.getQuestionSets$(),
    }).pipe(
      distinctUntilChanged(
        (prev, current) => arraysEqual(prev.carriers, current.carriers) && arraysEqual(prev.selectedCoverages, current.selectedCoverages),
      ),
      map(({ carriers, selectedCoverages, shouldUseRecommendedCarriers, allQuestionSets }) => {
        const selectedCoverageTypes = this.mapCoverageTypes(selectedCoverages);

        const eligibleCarriers = carriers.filter(carrier => {
          const isEligibleCarrier = carrier.eligible;
          const hasNoProductsSelected = selectedCoverages.length === 0;

          const mappedCarrierProducts = this.mapCoverageTypes(carrier.products.map(p => p.name));
          const hasMatchingCarrierProducts = mappedCarrierProducts.some(carrierProduct => selectedCoverageTypes.includes(carrierProduct));
          const isEligibleCarrierWithMatchingProducts = hasMatchingCarrierProducts || hasNoProductsSelected;

          return isEligibleCarrier && isEligibleCarrierWithMatchingProducts;
        });

        // If we are using RC, we don't need to sort because the carriers will come pre sorted.
        // If we are not using RC, we need to match the legacy logic and sort by question set order.
        const result = eligibleCarriers.map(this.mapRealTimeEligibilityCarrier(selectedCoverages, shouldUseRecommendedCarriers));
        return shouldUseRecommendedCarriers ? result : this.legacySortCarriers(result, allQuestionSets);
      }),
    );
  }

  getIneligibleCarriers$(): Observable<IEmperorRealTimeEligibilityCarrier[]> {
    return combineLatest({
      carriers: this.selectorWrapper.getAllRTECarriers$(),
      selectedCoverages: this.selectedCoverages$,
      messages: this.selectorWrapper.getMessages$(),
      shouldUseRecommendedCarriers: this.shouldUseRecommendedCarriers$,
    }).pipe(
      distinctUntilChanged(
        (prev, current) =>
          arraysEqual(prev.carriers, current.carriers) &&
          arraysEqual(prev.selectedCoverages, current.selectedCoverages) &&
          arraysEqual(prev.messages, current.messages),
      ),
      map(({ carriers, selectedCoverages, messages, shouldUseRecommendedCarriers }) => {
        const selectedCoverageTypes = this.mapCoverageTypes(selectedCoverages);

        return carriers
          .filter(carrier => {
            const isIneligibleCarrier = !carrier.eligible;
            const isEligibleCarrier = carrier.eligible;

            const hasProductsSelected = selectedCoverages.length !== 0;

            const mappedCarrierProducts = this.mapCoverageTypes(carrier.products.map(p => p.name));
            const hasNoMatchingCarrierProducts = mappedCarrierProducts.every(
              carrierProduct => !selectedCoverageTypes.includes(carrierProduct),
            );
            const isEligibleCarrierWithNoMatchingProducts = isEligibleCarrier && hasNoMatchingCarrierProducts && hasProductsSelected;
            return isIneligibleCarrier || isEligibleCarrierWithNoMatchingProducts;
          })
          .map(carrier => ({
            ...carrier,
            message: messages.find(m => (m.metadata as any)?.carrier_id === carrier.id)?.target,
            /*
             * If shouldUseRecommendedCarriers is on, we should disable all ineligible carriers.
             * If shouldUseRecommendedCarriers is off, we should not disable ineligible carriers.
             */
            displayDisabledState: shouldUseRecommendedCarriers,
          }));
      }),
    );
  }

  getCarrierQuotes$(carrierId: string): Observable<IQuotesState[]> {
    return this.allSelectedQuotes$.pipe(map(allQuotes => allQuotes.filter(quote => quote.carrier.id === carrierId)));
  }

  getCarrierProductStatus$(
    quote: IQuotesState | undefined,
    carrierId: string,
    hasPreferredQuotesEnabled = false,
  ): Observable<IEligibleCarrierStatusInfo | null> {
    return this.getCarrierQuestionSet$(carrierId).pipe(
      map(questionSet => {
        if (quote) {
          return getQuoteStatus(quote, hasPreferredQuotesEnabled, questionSet?.preferred);
        } else if (questionSet && this.isCarrierQuestionSetInProgress(questionSet)) {
          return CarrierProductStatus[EligibleCarrierStatus.Info];
        }
        return null;
      }),
    );
  }

  getCarrierQuestionSet$(carrierId: string): Observable<IApplicationFormQuestionSet | undefined> {
    return this.selectorWrapper
      .getQuestionSets$()
      .pipe(map(questionSets => questionSets.find(set => set.question_set.tenant_id === carrierId) as IApplicationFormQuestionSet));
  }

  carrierIsFetchingQuote$(carrierId: string): Observable<boolean> {
    const fetchingQuotesStatus = [QuoteRequestRequestTypes.in_progress, QuoteRequestRequestTypes.unsent, QuoteRequestRequestTypes.sent];
    return this.selectorWrapper.getQuotes$().pipe(
      map((quotes: IQuotesState[]) => {
        const carrierQuoteRequestStatus = quotes.find(q => q.carrier.id === carrierId)?.request_status;
        return carrierQuoteRequestStatus ? fetchingQuotesStatus.includes(carrierQuoteRequestStatus) : false;
      }),
    );
  }

  /**
   * Navigates to the question set corresponding to the given carrier if one exists.
   * Also marks the current question complete set as complete if valid.
   * @param carrierId Carrier uuid
   */
  goToCarrierQuestionSet(carrierId: string): void {
    this.storeService.dispatch(goToCarrierQuestionSet(carrierId));
  }

  /**
   * Marks the current question set completed if question set is complete.
   */
  markActiveQuestionSetCompleted(): void {
    this.storeService.dispatch(markActiveQuestionSetCompleted);
  }

  /*
   * In this method we map enrollment data and appetite data into a common model.
   * We use enrollment data until the use has selected the coverage options.
   * As a result, we only map the appetite data for carrier products and it looked how we expected.
   */
  private mapRealTimeEligibilityCarrier(
    selectedCoverages: string[] = [],
    shouldUseRecommendedCarriers: boolean,
  ): (carrier: IRealTimeEligibilityCarrier) => IRealTimeEligibilityCarrier {
    const selectedCoverageTypes = this.mapCoverageTypes(selectedCoverages);
    return (carrier: IRealTimeEligibilityCarrier) => {
      const products = carrier.products.filter(product => this.hasCoverageType(product.name, selectedCoverageTypes));
      const hasProducts = !!products.length;
      const isAdditionalMarket = carrier.labels.includes(CarrierLabel.ADDITIONAL_MARKET);
      const displayDisabledState = shouldUseRecommendedCarriers && !(hasProducts && carrier.products.some(product => product.evaluated));

      return {
        ...carrier,
        products,
        isAdditionalMarket,
        displayDisabledState,
      };
    };
  }

  /*
   * Non-required questions are marked as completed by default.
   * When we are checking if the user answered any questions, we need to look for a required question being answered.
   */
  private isCarrierQuestionSetInProgress(questionSet: IApplicationFormQuestionSet): boolean {
    return questionSet.answers.some(question => question.required && question.is_completed && question.answered_by_user);
  }

  private mapCoverageTypes(products: string[]): TCoverageType[] {
    return products.map(product => CoverageTypesUtils.getCoverageType(product)).filter(Boolean) as TCoverageType[];
  }

  private hasCoverageType(maybeCoverageType: string, coverageTypes: TCoverageType[]): boolean {
    const coverageType = CoverageTypesUtils.getCoverageType(maybeCoverageType);
    return !!coverageType && coverageTypes.includes(coverageType);
  }

  /*
   * The methods named legacySortCarriers, and legacyCarrierCompare are taken from Terminal.
   * The idea being to keep legacy sorting when RC is off.
   */
  private legacySortCarriers(
    carriers: Array<IRealTimeEligibilityCarrier>,
    questionSets: Array<IApplicationFormQuestionSet>,
  ): Array<IRealTimeEligibilityCarrier> {
    /**
     * This logic assumes the first question set(s) are broker question sets,
     * and that MQS question sets do not appear. It's an approximation to guess
     * whether carrier question sets have been added so that we can sort by them.
     */
    const questionSetTenantIds = questionSets.map(question => question.question_set.tenant_id);
    const hasCompletedBrokerQuestionSets =
      questionSetTenantIds.length > 0 && !questionSetTenantIds.every(questionSetId => questionSetId === questionSetTenantIds[0]);

    return carriers.sort((a, b) =>
      hasCompletedBrokerQuestionSets
        ? this.legacyCarrierCompare(questionSetTenantIds.indexOf(a.id), questionSetTenantIds.indexOf(b.id))
        : this.legacyCarrierCompare(a.name.toLowerCase(), b.name.toLowerCase()),
    );
  }

  private legacyCarrierCompare(a: number | string, b: number | string): number {
    // If the carriers being sorted do not have question sets and do not have an index of value
    if (a === -1) {
      return 1;
    }

    if (b === -1) {
      return -1;
    }

    return a < b ? -1 : 1;
  }
}
