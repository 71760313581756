<mat-expansion-panel
  #expansionPanel
  [expanded]="(isPanelExpanded$ | async) === true"
  [disabled]="(answersAreValid$ | async) === false && (isPanelExpanded$ | async) === true"
  (opened)="onExpandPanel()"
  (closed)="onCollapsePanel()"
  [id]="questionGroupId$ | async"
  hideToggle="true"
  [ngClass]="{ 'primary-accordion': isPrimaryAccordion, 'emperor-ui-accordion-container': !isPrimaryAccordion }"
>
  <mat-expansion-panel-header class="header">
    <mat-panel-title class="emperor-ui-title">
      <ng-container *ngIf="questionGroupLabel$ | async as label">
        <span [emperorTooltip]="label">{{ label }}</span>
      </ng-container>
    </mat-panel-title>
    <mat-panel-description>
      <div *ngIf="isPrimaryAccordion" class="emperor-accordion-icon-wrapper">
        <emperor-icon *ngIf="answersAreValid$ | async" name="check_circle_2_1" class="emperor-ui-completed-icon"></emperor-icon>
        <emperor-icon
          *ngIf="(answersAreValid$ | async) === false"
          name="interface_alert_circle_1"
          class="emperor-ui-alert-icon"
        ></emperor-icon>
      </div>
      <h4 class="expanded-text-header">
        {{ (isPanelExpanded$ | async) ? 'HIDE' : 'SHOW' }}
      </h4>
      <div class="emperor-ui-accordion-toggle-container" [ngClass]="{ expanded: isPanelExpanded$ | async }">
        <emperor-icon name="keyboard_arrow_up"></emperor-icon>
        <emperor-icon name="keyboard_arrow_down"></emperor-icon>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="accordion-answer-container">
    <ng-template matExpansionPanelContent>
      <ng-container *ngFor="let answer of questionGroup?.answers; trackBy: trackAccordionAnswers">
        <ng-container *ngIf="isAnswerPartOfNestedGroup(answer); else dynamicInput">
          <bp-sdk-question-group-container [questionGroup]="getNestedGroup(answer)"></bp-sdk-question-group-container>
        </ng-container>
        <ng-template #dynamicInput>
          <bp-sdk-dynamic-input-redux-container
            [answer]="answer"
            [id]="'bp-sdk-question-group__' + answer.question.id"
          ></bp-sdk-dynamic-input-redux-container>
        </ng-template>
      </ng-container>
    </ng-template>
  </div>
</mat-expansion-panel>
