import { Component, ElementRef, forwardRef, Injector, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { EmperorThemeColor } from '@boldpenguin/emperor-presentational';
import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'emperor-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends InputFieldsetDisableDirective {
  @Input() outline = true;
  @Input() customLabel = false;
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() color = EmperorThemeColor.PRIMARY;
  @Input() withSupportingText = false;
  @Input() supportingText: string | undefined;
  @Input() required: boolean | undefined;
  /**
   * Removes the subscript wrapper so it doesn't take up space below the checkbox.
   * Can be added by consuming applications when hints and errors are not used
   */
  // Double negative on excludeSubscript is not ideal but allows consuming applications
  // to use `excludeSubscript` rather than `showSubscript="false"`
  @Input({ transform: coerceBooleanProperty }) excludeSubscript = false;

  constructor(injector: Injector, sanitizer: DomSanitizer, elementRef: ElementRef) {
    super(injector, sanitizer, elementRef);
  }

  // As a consequence of using ng-container,
  // run time does not have access to formControlDirective
  // So we have to abstract out the writeValue, but not have the
  // functionality performed here
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  writeValue() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (this.control.disabled !== isDisabled) {
      isDisabled ? this.control.disable() : this.control.enable();
    }
  }

  showErrorState(): boolean {
    if (this.required && this.control.value === false && this.control.touched) {
      return true;
    }
    return false;
  }
}
