import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IGooglePlacesAutocompleteComponent } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

import PlaceResult = google.maps.places.PlaceResult;

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-google-autocomplete',
  templateUrl: './bp-sdk-google-autocomplete.component.html',
  styleUrls: ['./bp-sdk-google-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkGoogleAutocompleteComponent
  extends BpSdkBaseComponentComponent
  implements OnChanges, OnInit, IGooglePlacesAutocompleteComponent
{
  /**
   * Autocomplete attribute value for input
   */
  @Input() autoComplete: string | null;
  /**
   * Initial input value
   */
  @Input() filter: string | null;
  /**
   * google api key, if no key library is not loaded
   */
  @Input() googleApiKey: string | null;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * maximum length
   */
  @Input() max: number | null;
  /**
   * minimum length
   */
  @Input() min: number | null;
  /**
   * Placeholder text
   */
  @Input() placeholder: string | null;

  public warning: string | undefined;

  // Test for strings that start like a post office box, but not
  // valid patterns like "Port" or "Post Avenue"
  private poBoxPattern = /^p\.?\s?o\.?\s?b(\.|ox)|post office box/i;

  ngOnInit() {
    super.ngOnInit();

    this.updateWarning(this.formControl.value);

    this.formControl.valueChanges.pipe(debounceTime(250), distinctUntilChanged(), untilDestroyed(this)).subscribe(detail => {
      this.updateWarning(detail);
      this.elementRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { detail, bubbles: true }));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).includes('filter')) {
      this.formControl.setValue(changes.filter.currentValue, {
        emitEvent: false,
      });
    }
  }

  voidAddressInputs(missingAddressComponents: string[]): void {
    const street1Components = ['street_number', 'route'];
    const isMissingStreet1Components = missingAddressComponents.some(component => street1Components.includes(component));
    if (isMissingStreet1Components) {
      this.clearFormControlValue();
    }
  }

  placeSelected(place: PlaceResult) {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('placesUpdate', {
        detail: place,
        bubbles: true,
      }),
    );
  }

  private updateWarning(detail): void {
    const poBoxWarning = this.poBoxPattern.test(detail);
    if (poBoxWarning) {
      this.warning = 'Many carriers reject P.O. Box addresses';
    } else if (!poBoxWarning && this.warning) {
      this.warning = undefined;
    }
  }
}
