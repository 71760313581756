import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InputDirective } from '../../directive/input.directive';
import { IIconPrefix } from '../../models';
import { IAutocompleteChoice } from '../../models/autocomplete-choice.model';

@UntilDestroy()
@Component({
  selector: 'emperor-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent<T> extends InputDirective implements OnInit, OnChanges {
  @Input() choices: IAutocompleteChoice<T>[] = [];
  @Input() disableDropdown = false;
  @Input() allowFallthrough = false;
  @Input() showNoChoicesFallback = true;
  @Input() filterChoices = true;
  @Input() errorText: string;
  @Input() iconPrefix: IIconPrefix = {
    showPrefix: false,
    icon: '',
  };

  @Output() optionSelected = new EventEmitter<IAutocompleteChoice<T>>();

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  filteredChoices$: Observable<IAutocompleteChoice<T>[]>;

  ngOnInit(): void {
    super.ngOnInit();
    this.filteredChoices$ = this.control?.valueChanges.pipe(
      startWith(this.control.value || ''),
      map(search => this.filter(search)),
      untilDestroyed(this),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.choices && !changes.choices.firstChange) {
      this.filteredChoices$ = of(changes.choices.currentValue);
    }
  }

  onAutocompleteBlur(event: FocusEvent) {
    // Selecting a mat-option should not trigger the onBlur validation
    if (event.relatedTarget && (event.relatedTarget as HTMLElement).nodeName.toLowerCase().includes('mat-option')) {
      event.preventDefault();
      return;
    }

    this.onBlur();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedChoice = this.choices.find(choice => choice.id === event.option.value.id);

    this.optionSelected.emit(selectedChoice);
    this.control?.setValue(selectedChoice?.label);
  }

  private filter(search: string): IAutocompleteChoice<T>[] {
    return this.choices.filter(choice => choice.label.includes(search) || !this.filterChoices);
  }

  public closePanel() {
    this.trigger.closePanel();
  }
}
