import { Pipe, PipeTransform } from '@angular/core';
import { IQuotesState } from '@boldpenguin/sdk';

@Pipe({
  name: 'quotePriceLabel',
})
export class QuotePriceLabelPipe implements PipeTransform {
  transform(quote: IQuotesState | undefined): string {
    if (quote?.annual_price) {
      return 'Annual premium';
    } else if (quote?.monthly_price) {
      return 'Monthly premium';
    }
    return '';
  }
}
