import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { arraysEqual } from '@boldpenguin/emperor-common';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';
import { CoverageTypesUtils } from '@boldpenguin/emperor-services';
import { IChoice, IProductListComponent } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, merge, timer } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  skip,
  skipUntil,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { convertChoicesToGenericChoiceType } from '../../utils/choiceToGenericType';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

const ADDITIONAL_PRODUCT_SELECTION_DEBOUNCE_MS = 750;
const ADDITIONAL_SERVER_UPDATE_DEBOUNCE_MS = 3000;
@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-product-list',
  templateUrl: './bp-sdk-product-list.component.html',
  styleUrls: ['./bp-sdk-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkProductListComponent extends BpSdkBaseComponentComponent implements OnChanges, OnInit, IProductListComponent {
  /**
   * Selected choice id
   */
  @Input() choiceId: string | null;
  /**
   * Selected choice ids
   */
  @Input()
  get choiceIds(): string[] {
    return this.storeChoiceIds$.value;
  }
  set choiceIds(currentChoiceIds: string[]) {
    this.storeChoiceIds$.next(currentChoiceIds);
  }
  /**
   * Choices to display
   */
  @Input() choices: IChoice[];
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Is multiple
   */
  @Input() isMultiple = true;

  @HostBinding('attr.data-test') qaAttributeHook: string | null = null;

  formArray: FormArray;
  showError$ = new Subject<boolean>();
  coverageTypesUtils = CoverageTypesUtils;
  emperorUiChoices: EmperorUISelectFormChoice[];

  private storeChoiceIds$ = new BehaviorSubject<string[]>([]);

  ngOnInit(): void {
    this.formControl.setValidators(Validators.required);

    this.showError$.next(this.touched);

    this.initChangeSubscriptions();

    if (this.code && this.answerId) {
      this.qaAttributeHook = `${this.code}-${this.answerId}`;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.touched?.currentValue) {
      this.showError$.next(this.choiceIds.length === 0);
    }

    if (changes.isReadOnly) {
      if (changes.isReadOnly.currentValue) {
        this.emperorUiChoices = this.mapChoicesToEmperorUIChoices(this.choices);
      }

      if (!changes.isReadOnly.currentValue && changes.isReadOnly.previousValue) {
        this.emperorUiChoices = [];
        this.updateChoiceControlValues();
      }
    }
  }

  private initChangeSubscriptions(): void {
    this.formArray = new FormArray(this.choices.map(choice => new FormControl(this.choiceIds.includes(choice.id))));

    /**
     * Subscribe to valid and distinct changes by the user, the
     * debounce prevents these expensive requests from spamming the server.
     */
    const formChanges$: Observable<boolean[]> = this.formArray.valueChanges.pipe(
      debounceTime(ADDITIONAL_PRODUCT_SELECTION_DEBOUNCE_MS),
      startWith(this.formArray.value),
      distinctUntilChanged(arraysEqual),
      skip(1),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    formChanges$.pipe(untilDestroyed(this)).subscribe(currentSelected => {
      const noneSelected = !currentSelected.some(s => s === true);
      this.showError$.next(noneSelected && this.touched);

      this.elementRef.nativeElement.dispatchEvent(
        new CustomEvent('valueUpdate', {
          detail: this.getSelectedProducts(currentSelected),
          bubbles: true,
        }),
      );
    });

    /**
     * Reconcile form (UI) and store values. The UI should update from
     * the store, but prioritize keeping the user's choices (form).
     */
    const storeChanges$: Observable<string[]> = this.storeChoiceIds$.pipe(
      distinctUntilChanged(arraysEqual),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    // update form with any store changes _before_ user input
    const initialServerChange$: Observable<string[]> = storeChanges$.pipe(skip(1), takeUntil(formChanges$.pipe(first())));

    // as form updates, check for (likely) resolved store value
    const serverResponseChange$: Observable<boolean> = merge(formChanges$, storeChanges$.pipe(skipUntil(formChanges$))).pipe(
      // cancel and restart if a new update emits
      switchMap(() =>
        timer(ADDITIONAL_SERVER_UPDATE_DEBOUNCE_MS).pipe(
          withLatestFrom(formChanges$, storeChanges$),
          map(([_count, debouncedSelected, storeProducts]) => {
            const currentSelected = this.formArray.value;
            const currentProducts = this.getSelectedProducts(currentSelected);
            const isFormDebouncePending = !arraysEqual(debouncedSelected, currentSelected);
            const valuesHaveDrifted = !arraysEqual(currentProducts, storeProducts);
            return !isFormDebouncePending && valuesHaveDrifted;
          }),
          first(),
          filter(shouldUpdate => shouldUpdate),
        ),
      ),
    );

    merge(initialServerChange$, serverResponseChange$)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateChoiceControlValues();
      });
  }

  private updateChoiceControlValues(): void {
    this.formArray.controls.forEach((_, index) => {
      this.formArray.at(index).setValue(this.choiceIds.includes(this.choices[index].id), {
        emitEvent: false,
      });
    });
  }

  private getSelectedProducts(selectedCoverages: boolean[]): string[] {
    return selectedCoverages.reduce((acc: string[], curr, i) => {
      if (curr === true) {
        acc.push(this.choices[i].id);
      }
      return acc;
    }, []);
  }

  private mapChoicesToEmperorUIChoices(choices: IChoice[]): EmperorUISelectFormChoice[] {
    this.setSelectedProductFromChoiceId();
    return convertChoicesToGenericChoiceType(choices);
  }

  private setSelectedProductFromChoiceId() {
    if (this.choiceIds?.length > 0) {
      this.formControl.setValue(this.choiceIds, { emitEvent: false });
    }
  }
}
