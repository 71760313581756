import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';
import { EmperorUISelectFormChoice } from '../../models';
import { BPErrorMessage, BPErrorState } from '../../utils/input-error-state';

@Component({
  selector: 'emperor-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanInputComponent),
      multi: true,
    },
  ],
})
export class BooleanInputComponent extends InputFieldsetDisableDirective {
  @Input() choices: EmperorUISelectFormChoice[];
  @Input() id: string;

  get isDisabled(): boolean {
    return this.control?.disabled;
  }

  showErrorState(): boolean {
    return BPErrorState(this.control);
  }
  showErrorMessages(): string {
    return BPErrorMessage(this.control.errors, this.errorText, this.errorMessageMap);
  }

  identifyRadioId(_index, choice: EmperorUISelectFormChoice) {
    return choice.id;
  }

  writeValue(value) {
    if (value !== this.control.value) {
      this.control.setValue(value);
    }
  }

  registerOnChange(fn: (_value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  onRadioClick(event: Event, choiceValue) {
    event.stopPropagation();
    this.control.setValue(choiceValue);
  }
}
