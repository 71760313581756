import { Component, Input } from '@angular/core';
import { IApplicationFormProcesses, IPendingProcessComponent } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-pending-process',
  templateUrl: './bp-sdk-pending-process.component.html',
  styleUrls: ['./bp-sdk-pending-process.component.scss'],
})
export class BpSdkPendingProcessComponent implements IPendingProcessComponent {
  @Input()
  pendingProcesses: IApplicationFormProcesses[];
}
