import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ITimelineStep, TimelineStepStatus } from '@boldpenguin/emperor-presentational';
import { SdkStoreService, SelectorWrapperService } from '@boldpenguin/emperor-services';
import { ApplicationFormStates, IQuestionSetNavigationComponent, LoadingStates, TQuestionSetNavigationItem } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const buildTimelineStep = (
  questionSet: TQuestionSetNavigationItem,
  status: TimelineStepStatus,
  subtasks: ITimelineStep[] = [],
  callback?: () => void,
): ITimelineStep => {
  const step: ITimelineStep = {
    status,
    label: questionSet.question_set.name,
    disabled: !questionSet.is_active,
    callback,
  };

  if (subtasks.length > 0) {
    step.subtasks = subtasks;
  }

  return step;
};

const calculateTimelineStepStatus = (
  questionSet: TQuestionSetNavigationItem,
  activeId: string,
  children?: TQuestionSetNavigationItem[],
): TimelineStepStatus => {
  let status = TimelineStepStatus.NOT_STARTED;
  if (questionSet.question_set_id === activeId || children?.find(c => c.question_set_id === activeId)) {
    status = TimelineStepStatus.IN_PROGRESS;
  } else if (questionSet.is_completed) {
    status = TimelineStepStatus.COMPLETED;
  }

  return status;
};

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-question-set-navigation',
  templateUrl: './bp-sdk-question-set-navigation.component.html',
  styleUrls: ['./bp-sdk-question-set-navigation.component.scss'],
})
export class BpSdkQuestionSetNavigationComponent implements OnInit, OnChanges, IQuestionSetNavigationComponent {
  /**
   * Potential question sets
   */
  @Input() set questionSets(questionSets: TQuestionSetNavigationItem[]) {
    if (questionSets) {
      if (this.haveQuestionSetsChanged(questionSets)) {
        this.questionSetsToDisplay$.next(questionSets);
      }
    } else {
      if (this.haveQuestionSetsChanged(questionSets)) {
        this.questionSetsToDisplay$.next([]);
      }
    }

    this._questionSets = questionSets;
  }
  /**
   * Active question set id
   */
  @Input() activeId: string;

  // Inputs this component is not using yet
  @Input() isNavigationEnabled: boolean;

  questionSetsToDisplay$: BehaviorSubject<TQuestionSetNavigationItem[]> = new BehaviorSubject<TQuestionSetNavigationItem[]>([]);
  activeId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  timelineSteps$: BehaviorSubject<ITimelineStep[]> = new BehaviorSubject<ITimelineStep[]>([]);
  showReviewQuotes$ = new BehaviorSubject<boolean>(false);
  isNotLoadingQuotes$ = new BehaviorSubject(false);
  areParentQuestionSetsInNavigationEnabled$ = new BehaviorSubject(false);

  @ViewChild('navList', { read: ElementRef }) navList: ElementRef;

  private _questionSets: TQuestionSetNavigationItem[];

  constructor(
    private elRef: ElementRef,
    private sdkStoreService: SdkStoreService,
    private selectorWrapperService: SelectorWrapperService,
  ) {}

  ngOnInit(): void {
    this.sdkStoreService.initStoreListener();
    this.selectorWrapperService
      .getApplicationForm$()
      .pipe(
        map(appForm => appForm?.state === ApplicationFormStates.Completed || appForm?.state === ApplicationFormStates.Quoted),
        untilDestroyed(this),
      )
      .subscribe(showReviewQuotes => {
        if (showReviewQuotes !== this.showReviewQuotes$.value) {
          this.showReviewQuotes$.next(showReviewQuotes);
        }
      });

    this.selectorWrapperService
      .selectAreParentQuestionSetsInNavigationEnabled$()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(areParentQuestionSetsInNavigationEnabled => {
        this.areParentQuestionSetsInNavigationEnabled$.next(areParentQuestionSetsInNavigationEnabled);
      });

    combineLatest([this.questionSetsToDisplay$, this.activeId$, this.showReviewQuotes$, this.areParentQuestionSetsInNavigationEnabled$])
      .pipe(untilDestroyed(this))
      .subscribe(([questionSets, activeId, showReviewQuotes, areParentQuestionSetsInNavigationEnabled]) => {
        const timelineSteps = this.mapQuestionSetsToTimeline(
          questionSets,
          activeId,
          showReviewQuotes,
          areParentQuestionSetsInNavigationEnabled,
        );
        this.timelineSteps$.next(timelineSteps);
      });

    this.selectorWrapperService
      .getQuotesState$()
      .pipe(
        map(quoteState => quoteState !== LoadingStates.Loading),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(isLoading => {
        this.isNotLoadingQuotes$.next(isLoading);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeId && !changes.activeId.firstChange) {
      this.activeId$.next(changes.activeId.currentValue);
    }
  }

  mapQuestionSetsToTimeline(
    questionSets: TQuestionSetNavigationItem[],
    activeId: string,
    showReviewQuotes: boolean,
    areParentQuestionSetsInNavigationEnabled: boolean,
  ): ITimelineStep[] {
    const validItems = questionSets.filter(set => set.answers.length > 0);
    const mappedSteps: ITimelineStep[] = [];

    if (areParentQuestionSetsInNavigationEnabled) {
      validItems.forEach((questionSet, _idx, arr) => {
        if (!questionSet.isChild) {
          if (questionSet.isParent) {
            const children = arr.filter(x => x.parentQuestionSetId === questionSet.id);
            const childSteps = children.map(child =>
              buildTimelineStep(child, calculateTimelineStepStatus(child, activeId), [], () => this.timelineStepClicked(child)),
            );
            const status = calculateTimelineStepStatus(questionSet, activeId, children);
            const timelineStep = buildTimelineStep(questionSet, status, childSteps, () => this.timelineStepClicked(questionSet));
            mappedSteps.push(timelineStep);
          } else {
            const status = calculateTimelineStepStatus(questionSet, activeId);
            mappedSteps.push(buildTimelineStep(questionSet, status, [], () => this.timelineStepClicked(questionSet)));
          }
        }
      });
    } else {
      validItems.forEach(questionSet => {
        const status = calculateTimelineStepStatus(questionSet, activeId);

        mappedSteps.push(buildTimelineStep(questionSet, status, [], () => this.timelineStepClicked(questionSet)));
      });
    }

    if (showReviewQuotes) {
      mappedSteps.push({
        status: TimelineStepStatus.COMPLETED,
        label: 'Review Quotes',
      });
    }

    return mappedSteps;
  }

  timelineStepClicked(questionSet: TQuestionSetNavigationItem) {
    const id = questionSet.question_set_id;
    this.activeId$.next(id);
    this.emitActiveIdUpdate(id);
  }

  emitActiveIdUpdate(id: string) {
    if (this.showReviewQuotes$.value && this.isNotLoadingQuotes$.value) {
      this.elRef.nativeElement.dispatchEvent(
        new CustomEvent('emperorQuotedTimelineStepClicked', {
          bubbles: true,
        }),
      );
    }
    this.elRef.nativeElement.dispatchEvent(
      new CustomEvent('activeIdUpdate', {
        detail: id,
        bubbles: true,
      }),
    );
  }

  private haveQuestionSetsChanged(newValue?: TQuestionSetNavigationItem[]): boolean {
    if (newValue == null || newValue.length !== (this._questionSets || []).length) {
      return true;
    }
    return newValue.some((newQs, index) => {
      const newQsExtract = this.extractChangeKeyValues(newQs);
      const oldQsExtract = this.extractChangeKeyValues(this._questionSets[index]);
      return newQsExtract.some((newExtractValue, innerIndex) => {
        return newExtractValue !== oldQsExtract[innerIndex];
      });
    });
  }

  private extractChangeKeyValues(questionSet: TQuestionSetNavigationItem) {
    if (questionSet == null) {
      return [];
    }
    const topLevelKeysToCheck = ['is_active', 'is_completed'];
    const questionSetKeysToCheck = ['code', 'name', 'owner_id', 'title', 'tenant_id'];
    const value: Array<string> = [];
    topLevelKeysToCheck.forEach(x => value.push(questionSet[x]));
    questionSetKeysToCheck.forEach(x => value.push(questionSet.question_set[x]));
    return value;
  }
}
