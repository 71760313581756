import {
  ApplicationRef,
  ComponentRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  Input,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Type,
  ChangeDetectorRef,
} from '@angular/core';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { EmperorTooltipComponent } from '../components/emperor-tooltip/emperor-tooltip.component';
import { EmperorTooltipOptions, EmperorTooltipPlacement } from '../models/tooltip.model';
import { defaultOptions } from '../options';

@Directive({
  selector: '[emperorTooltip]',
  exportAs: 'emperorTooltip',
})
export class EmperorTooltipDirective implements AfterViewInit, OnChanges, OnDestroy {
  hideTimeoutId: number;
  destroyTimeoutId: number;
  hideAfterClickTimeoutId: number;
  componentRef: ComponentRef<EmperorTooltipComponent>;
  createTimeoutId: number;
  showTimeoutId: number;
  elementPosition: DOMRect;
  options: EmperorTooltipOptions;
  _destroyDelay: number;
  _unsubscribe$ = new Subject<void>();
  mouseEnter$: Observable<Event>;
  focusIn$: Observable<Event>;
  mouseLeave$: Observable<Event>;
  focusOut$: Observable<Event>;
  scrollEvent$: Observable<Event>;

  @Input() emperorTooltip: string | undefined | null;
  @Input() tooltipPlacement: EmperorTooltipPlacement = 'top';
  @Input() tooltipDisabled = false;
  @Input() tooltipDelay = defaultOptions.showDelay;
  @Input() tooltipAnimationDuration = defaultOptions.animationDuration;
  @Input() closeTooltip: Observable<void> | undefined;

  get isTooltipDestroyed() {
    return this.componentRef && this.componentRef.hostView.destroyed;
  }

  get destroyDelay() {
    if (this._destroyDelay) {
      return this._destroyDelay;
    } else {
      return Number(this.options.hideDelay) + Number(this.options.animationDuration);
    }
  }

  set destroyDelay(value: number) {
    this._destroyDelay = value;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {
    this.options = { ...defaultOptions };
  }

  ngAfterViewInit() {
    this.options = {
      ...this.options,
      placement: this.tooltipPlacement,
      showDelay: this.tooltipDelay,
      animationDuration: this.tooltipAnimationDuration,
    };
    // If the tooltip is set AND the tooltip is not disabled, set up the listeners.
    if (!!this.emperorTooltip && !this.tooltipDisabled) {
      this.setUpListeners();
    }

    if (this.closeTooltip) {
      this.closeTooltip.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
        this.destroyTooltip();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!Object.keys(changes).includes('emperorTooltip') || changes.emperorTooltip.firstChange) {
      return;
    }

    if (!changes.emperorTooltip.currentValue) {
      this._unsubscribe$.next();
      return;
    }

    if (!changes.emperorTooltip.previousValue) {
      this.setUpListeners();
      return;
    }

    if (changes.emperorTooltip.previousValue && changes.emperorTooltip.currentValue && this.componentRef) {
      this.componentRef.instance.data = {
        ...this.componentRef.instance.data,
        message: changes.emperorTooltip.currentValue,
      };
      const cdr = this.componentRef.injector.get(ChangeDetectorRef);
      cdr.detectChanges();
      this.componentRef.instance.setPosition();
      return;
    }
  }

  ngOnDestroy() {
    this.destroyTooltip(true);
  }

  show() {
    if (this.tooltipDisabled) {
      return;
    }

    if (!this.componentRef || this.isTooltipDestroyed) {
      this.createTooltip();
    }
  }

  destroyTooltip(fast = false) {
    this.clearTimeouts();
    if (!this.isTooltipDestroyed) {
      this.hideTimeoutId = window.setTimeout(
        () => {
          this.hideTooltip();
        },
        fast ? 0 : this.options.hideDelay,
      );
      this.destroyTimeoutId = window.setTimeout(
        () => {
          if (!this.componentRef || this.isTooltipDestroyed) {
            return;
          }
          this.appRef.detachView(this.componentRef.hostView);
          this.componentRef.destroy();
        },
        fast ? 0 : this.destroyDelay,
      );
    }
  }

  private createTooltip() {
    this.clearTimeouts();
    this.elementPosition = this.elementRef.nativeElement.getBoundingClientRect();

    this.createTimeoutId = window.setTimeout(() => {
      this.appendComponentToBody(EmperorTooltipComponent);
    }, this.options.showDelay);

    this.showTimeoutId = window.setTimeout(() => {
      this.showTooltipElem();
    }, this.options.showDelay);
  }

  private clearTimeouts() {
    if (this.createTimeoutId) {
      clearTimeout(this.createTimeoutId);
    }

    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (this.destroyTimeoutId) {
      clearTimeout(this.destroyTimeoutId);
    }
  }

  private appendComponentToBody(component: Type<unknown>) {
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector) as ComponentRef<EmperorTooltipComponent>;

    this.componentRef.instance.data = {
      message: this.emperorTooltip,
      element: this.elementRef.nativeElement,
      elementPosition: this.elementPosition,
      options: this.options,
    };
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<HTMLElement>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  hideTooltip() {
    if (!this.componentRef || this.isTooltipDestroyed) {
      return;
    }
    this.componentRef.instance.show = false;
  }

  showTooltipElem() {
    this.clearTimeouts();
    this.componentRef.instance.show = true;
  }

  private setUpListeners() {
    this.mouseEnter$ = fromEvent(this.elementRef.nativeElement, 'mouseenter');
    this.focusIn$ = fromEvent(this.elementRef.nativeElement, 'focusin');
    this.focusOut$ = fromEvent(this.elementRef.nativeElement, 'focusout');
    this.mouseLeave$ = fromEvent(this.elementRef.nativeElement, 'mouseleave');
    this.scrollEvent$ = fromEvent(window, 'scroll');

    merge(this.mouseEnter$, this.focusIn$)
      .pipe(
        tap(() => this.show()),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();

    merge(this.mouseLeave$, this.focusOut$, this.scrollEvent$)
      .pipe(
        tap(() => this.destroyTooltip()),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }
}
