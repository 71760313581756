import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IQuoteAttachment } from '@boldpenguin/sdk';
import { EmperorUIButtonTypes } from '../button/models/button-types.model';
import { EmperorUIAlertType } from '../alert';
import { SafeHtml } from '@angular/platform-browser';

export interface QuotePresentationLineItemViewModel {
  icon: string;
  iconAlertType?: EmperorUIAlertType;
  price: string;
  name: string;
}

export interface QuotePresentationLabel {
  icon: string;
  iconAlertType?: EmperorUIAlertType;
  text: string;
  tooltip?: string;
}

@Component({
  selector: 'emperor-quote-presentation',
  templateUrl: './quote-presentation.component.html',
  styleUrls: ['./quote-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotePresentationComponent {
  @Input() headline: string;
  @Input() price?: string;
  @Input() priceMessage = '';
  @Input() costCycle: string;
  @Input() carrierName: string;
  @Input() carrierLogo: string;
  @Input() supplierLogo?: string;
  @Input() supplierName?: string;
  @Input() disclaimerHtml?: SafeHtml;
  @Input() lineItems: QuotePresentationLineItemViewModel[] = [];
  @Input() supportText?: string;
  @Input() showActionButton = false;
  @Input() disableActionButton = false;
  @Input() actionButtonType: EmperorUIButtonTypes = EmperorUIButtonTypes.Large;
  @Input() buttonText: string;
  @Input() quoteLabels: QuotePresentationLabel[];
  @Input() attachments?: IQuoteAttachment[];
  @Input() subtexts?: string[] = [];

  @Output() actionButtonClicked = new EventEmitter<void>();
  @Output() attachmentDownloadClicked = new EventEmitter<IQuoteAttachment>();

  readonly Math = Math;
  readonly emperorUIButtonTypes = EmperorUIButtonTypes;

  onActionButtonClicked() {
    this.actionButtonClicked.emit();
  }

  handleDownloadClick() {
    if (this.attachments?.length) {
      this.attachmentDownloadClicked.emit(this.attachments[0]);
    }
  }

  get displayButtonText(): string {
    return this.buttonText || `Checkout with ${this.carrierName}`;
  }

  get displayPrice(): boolean {
    return !!this.priceMessage || this.displayPriceAmount;
  }

  get displayPriceAmount(): boolean {
    return !!this.price || this.price === '0';
  }
}
