import { Component, Input } from '@angular/core';
import { EmperorThemeColor } from '../../../models';

@Component({
  selector: 'emperor-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() fullscreen = false;
  @Input() diameter = 100; // 100 is the default diameter for angular material's spinner
  @Input() value = 5; // Optional, only required if this is a progress-type spinner
  @Input() isProgress?: boolean;
  @Input() color: EmperorThemeColor = EmperorThemeColor.PRIMARY;
}
