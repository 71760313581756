<div class="emperor-ui-business-class-selector">
  <emperor-text-input
    [formControl]="formControl"
    [iconPrefix]="iconPrefix"
    [id]="answerId"
    [code]="code"
    label="What does this business do?"
    hint="Examples: You may search for a Handyman by its description, NAICS code 236118, SIC code 1521, or ISO code 95625."
    [hintWithIcon]="hintWithIcon"
  >
    <emperor-icon
      suffix
      class="emperor-ui-clear-icon"
      [class.disabled]="!hasBusinessClassFilter"
      name="remove_circle_2"
      (click)="clearSearch()"
      (keydown.enter)="clearSearch()"
      role="button"
      aria-label="Clear search"
      tabindex="0"
    ></emperor-icon>
  </emperor-text-input>

  <ng-container *ngIf="!loadingBcsChoices; else loading">
    <ng-container *ngIf="hasBusinessClassFilter; else hasntSearchedForNaic">
      <div class="emperor-ui-search-results">
        <bp-sdk-business-classification-selector-list-container
          *ngIf="hasAnyChoicesToDisplay$ | async; else noResults"
          [choices]="choices"
          [choiceId]="choiceId"
          [showNaicsCode]="false"
          [showConfidence]="false"
        >
        </bp-sdk-business-classification-selector-list-container>
      </div>
    </ng-container>
    <!-- Empty Naics state -->
    <ng-template #hasntSearchedForNaic>
      <div></div>
    </ng-template>
  </ng-container>

  <!-- No Results Available -->
  <ng-template #noResults>
    <div class="emperor-ui-no-results">
      No search results found for "{{ formControl.value }}" (<emperor-button
        class="emperor-ui-clear-search"
        [uiButtonType]="EmperorUIButtonTypes.Text"
        (click)="clearSearch()"
        >CLEAR SEARCH</emperor-button
      >)
    </div>
  </ng-template>

  <!-- Loading State -->
  <ng-template #loading>
    <div class="emperor-ui-loading-results">
      <emperor-spinner></emperor-spinner>
    </div>
  </ng-template>
</div>
