<fieldset>
  <legend *ngIf="!!label" class="emperor-ui-form-field-label" [innerHTML]="label"></legend>
  <div class="emperor-ui-on-off-button-container" [ngClass]="control.value ? 'on' : 'off'">
    <!-- On -->
    <div
      class="emperor-ui-on-off-button-choice on-button"
      [class.emperor-ui-checked]="control.value === true"
      [class.emperor-ui-button-disabled]="isDisabled"
      [attr.data-test]="qaAttributeHook"
    >
      <label
        >On
        <input
          type="radio"
          #emperorOnButton
          [formControl]="control"
          [attr.name]="id"
          [value]="true"
          [checked]="control.value === true"
          [attr.checked]="control.value === true"
          [attr.data-test]="qaAttributeHook"
          class="cdk-visually-hidden"
        />
      </label>
    </div>

    <!-- Off -->
    <div
      class="emperor-ui-on-off-button-choice off-button"
      [class.emperor-ui-checked]="control.value === false"
      [class.emperor-ui-button-disabled]="isDisabled"
      [attr.data-test]="qaAttributeHook"
    >
      <label
        >Off
        <input
          type="radio"
          #emperorOffButton
          [formControl]="control"
          [attr.name]="id"
          [value]="false"
          [checked]="control.value === false"
          [attr.checked]="control.value === false"
          [attr.data-test]="qaAttributeHook"
          class="cdk-visually-hidden"
        />
      </label>
    </div>
  </div>
  <div class="emperor-ui-on-off-subscript-wrapper emperor-ui-subscript-wrapper">
    <ng-container *ngIf="showErrorState()">
      <mat-error>{{ showErrorMessages() }}</mat-error>
    </ng-container>
    <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
    <mat-hint *ngIf="!!hintWithIcon">
      <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
    </mat-hint>
  </div>
</fieldset>
