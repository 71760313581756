<label [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-full-width emperor-ui-google-autocomplete">
  <input
    matInput
    emperorGoogleMapsAutocomplete
    [formControl]="control"
    [id]="id || ''"
    [attr.aria-label]="ariaLabel ? ariaLabel : label"
    [attr.aria-labelledby]="ariaLabelledBy"
    [attr.data-test]="qaAttributeHook"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (autocompleteSelected)="selectedPlace($event)"
    [placeholder]="placeholder ?? ''"
  />
  <mat-error emperorErrorHandler [errorText]="errorText" [errorMessageMap]="errorMessageMap" [id]="code || ''"></mat-error>
  <mat-hint class="emperor-ui-warning" *ngIf="warning$ | async as warning" data-testid="warning-hint">
    {{ warning }}
  </mat-hint>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
</mat-form-field>
