import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmperorIconRegistry } from './emperor-icon.registry';
import { EmperorIconComponent } from './emperor-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EmperorIconComponent],
  providers: [EmperorIconRegistry],
  exports: [EmperorIconComponent],
})
export class IconsModule {}
