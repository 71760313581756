export const CurrencyMaskOptions = {
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
    },
  },
};

export const CommaDelimitedNumberMaskOptions = {
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
    },
  },
};

export const PercentageMaskOptions = {
  mask: Number,
  min: 0,
  max: 100,
};

export const DateMaskOptions = {
  mask: 'mm/dd/yyyy',
};

export const PhoneNumberMaskOptions = {
  mask: '(000) 000-0000',
};

export const FeinMaskOptions = {
  mask: '##-###0000',
  definitions: {
    '#': /[*\d]/,
  },
};

export const SsnMaskOptions = {
  mask: '###-##-0000',
  definitions: {
    '#': /[*\d]/,
  },
};

export const NoMaskOptions = { mask: /.*/ };
