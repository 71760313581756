<emperor-button
  [uiButtonType]="emperorUIButtonTypes.FabLarge"
  [disabled]="buttonDisabled"
  (click)="click()"
  (disabledClick)="onDisabledClick()"
  tooltip="Go to next step"
  tooltipPlacement="top"
  [closeTooltip]="closeTooltip$"
  class="emperor-ui-question-set-next-button"
>
  <!-- Loading Icon -->
  <emperor-spinner [diameter]="24" *ngIf="spinnerActive; else forward">
  </emperor-spinner>
  <!-- Forward Icon -->
  <ng-template #forward>
    <emperor-icon class="emperor-ui-arrow-forward" name="arrow_forward"></emperor-icon>
  </ng-template>
</emperor-button>
