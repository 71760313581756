import { Pipe, PipeTransform } from '@angular/core';
import { IQuotesState } from '@boldpenguin/sdk';

@Pipe({
  name: 'quotePrice',
})
export class QuotePricePipe implements PipeTransform {
  transform(quote: IQuotesState | undefined): string {
    return quote?.annual_price || quote?.monthly_price || '';
  }
}
