import { InjectionToken } from '@angular/core';

/**
 * Provider token for SDK Wrapper Module configuration
 */
export const SDK_WRAPPER_MODULE_CONFIG = new InjectionToken<SdkWrapperModuleConfig>('SDK_WRAPPER_MODULE_CONFIG');

/**
 * This can be passed to the SDKModule so providers for config values can be registered
 */
export interface SdkWrapperModuleConfig {
  shouldDisplayAnswerSource?: boolean;
}
