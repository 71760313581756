<div class="emperor-ui-quote-presentation">
  <h3>{{ headline }}</h3>

  <ng-container *ngIf="subtexts">
    <div *ngFor="let subtext of subtexts" class="emperor-ui-subtext">
      {{ subtext }}
    </div>
  </ng-container>

  <div class="emperor-ui-quote-label-container" *ngIf="!!quoteLabels?.length">
    <div
      class="emperor-ui-quote-label"
      *ngFor="let quoteLabel of quoteLabels"
      [class]="quoteLabel.iconAlertType"
      [emperorTooltip]="quoteLabel.tooltip"
    >
      <emperor-icon *ngIf="quoteLabel.icon" [name]="quoteLabel.icon"></emperor-icon>{{ quoteLabel.text }}
    </div>
  </div>
  <div class="emperor-ui-carrier-logo-container" *ngIf="carrierLogo || supplierLogo">
    <ng-container *ngIf="supplierLogo">
      <img class="emperor-ui-carrier-logo" [src]="supplierLogo" [alt]="supplierName" />
      <div class="emperor-ui-vertical-divider"></div>
    </ng-container>
    <img class="emperor-ui-carrier-logo" [src]="carrierLogo" [alt]="carrierName" *ngIf="carrierLogo" />
  </div>
  <div class="emperor-ui-errors">
    <ng-content select="[errors]"></ng-content>
  </div>
  <div class="emperor-ui-price" *ngIf="displayPrice">
    <span class="emperor-ui-price-message" *ngIf="priceMessage; else priceTemplate">{{ priceMessage }}</span>
    <ng-template #priceTemplate>
      <ng-container *ngIf="displayPriceAmount">
        <div class="emperor-ui-price-amount">
          <span class="emperor-ui-dollars">${{ price || '0' | displayPrice : 'dollars' }}</span>
          <span class="emperor-ui-cents">.{{ price || '0' | displayPrice : 'cents' }}</span>
        </div>
        <div class="emperor-ui-cycle">{{ costCycle }}</div>
      </ng-container>
    </ng-template>
  </div>
  <div class="emperor-ui-disclaimer" *ngIf="!!disclaimerHtml" [innerHTML]="disclaimerHtml"></div>
  <hr class="emperor-ui-divider" *ngIf="lineItems.length > 0" [ngClass]="{ 'no-price': !displayPrice }" />
  <div class="emperor-ui-line-item" *ngFor="let lineItem of lineItems">
    <emperor-icon [name]="lineItem.icon" [class]="lineItem.iconAlertType"></emperor-icon>
    <span class="emperor-ui-description">{{ lineItem.name }}</span>
    <span class="emperor-line-item-value">{{ lineItem.price }}</span>
  </div>

  <emperor-button
    *ngIf="attachments?.length"
    class="emperor-ui-attachment-button"
    [uiButtonType]="emperorUIButtonTypes.Link"
    (click)="handleDownloadClick()"
  >
    <div class="emperor-ui-attachment">
      <emperor-icon name="data_download_9"></emperor-icon>
      <span class="emperor-ui-attachment-text">Download Quote</span>
    </div>
  </emperor-button>

  <div class="emperor-ui-bind-info-container" *ngIf="showActionButton || !!supportText">
    <emperor-button
      *ngIf="showActionButton"
      (click)="onActionButtonClicked()"
      [uiButtonType]="actionButtonType"
      [disabled]="disableActionButton"
      >{{ displayButtonText }}</emperor-button
    >
    <div class="emperor-ui-help-text" *ngIf="!!supportText">
      <emperor-icon name="operator_male"></emperor-icon>
      <span>{{ supportText }}</span>
    </div>
  </div>
</div>
