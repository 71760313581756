<emperor-month-year-input
  [min]="$any(minDate$ | async)"
  [max]="$any(maxDate$ | async)"
  [placeholder]="placeholder || ''"
  [label]="label"
  [id]="answerId"
  [formControl]="formControl"
  [ariaLabelledBy]="labelledBy"
  [errorText]="errorText"
  icon="calendar_1"
  [hintWithIcon]="hintWithIcon"
  [hint]="helpText"
  [code]="code"
  [errorMessageMap]="errorMessageMap"
  [isReadOnly]="isReadOnly"
></emperor-month-year-input>
