import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'emperor-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  /**
   * Progress between 0 and 100
   */
  @Input() progressPercent = 0;

  /**
   *
   */
  @Input() indeterminate = false;
}
