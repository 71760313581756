<emperor-google-autocomplete-input
  [label]="label"
  [placeholder]="placeholder || ''"
  [formControl]="formControl"
  [ariaLabelledBy]="labelledBy"
  [errorText]="errorText"
  [valueToDisplay]="filter"
  [hint]="helpText"
  [hintWithIcon]="hintWithIcon"
  [id]="answerId"
  [code]="code"
  (autocompletedSelected)="placeSelected($event)"
  [isReadOnly]="isReadOnly"
  [warning]="warning"
></emperor-google-autocomplete-input>
