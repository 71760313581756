import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmperorUIAlertType } from '../../models/alert-types.model';
import { IMessage, MessageLevel } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorMessageAlertComponent {
  @Input() message: IMessage;
  public EmperorUIAlertType = EmperorUIAlertType;

  messageLevelToUiAlertType(level: MessageLevel): EmperorUIAlertType {
    switch (level) {
      case MessageLevel.error:
        return EmperorUIAlertType.Fail;
      case MessageLevel.warning:
        return EmperorUIAlertType.Warn;
      case MessageLevel.info:
        return EmperorUIAlertType.Success;
    }
  }
}
