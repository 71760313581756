import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { ErrorState, IMappedQuestionGroupAnswers } from '@boldpenguin/sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { DriverService } from '../../service/driver.service';
import { containsPrefilledAnswer } from '../../utils/prefill';

@Component({
  selector: 'emperor-bp-sdk-driver-group',
  templateUrl: './bp-sdk-driver-group.component.html',
  styleUrls: ['./bp-sdk-driver-group.component.scss'],
  providers: [{ provide: DriverService, useFactory: () => new DriverService() }],
})
//TODO: implements IDriverGroupComponent when beta is finished
export class BpSdkDriverGroupComponent implements OnInit {
  uiButtonType = EmperorUIButtonTypes;

  @Input()
  set group(group: IMappedQuestionGroupAnswers) {
    this.driverService.setQuestionGroup(group);
  }

  @Input()
  canDelete: boolean;

  @Input()
  errors: typeof ErrorState;

  isPanelExpanded$ = new BehaviorSubject(true);
  isPrefilled$: Observable<boolean>;

  constructor(private elementRef: ElementRef, public driverService: DriverService) {}

  ngOnInit(): void {
    this.isPrefilled$ = this.driverService.answers$.pipe(containsPrefilledAnswer());
  }

  handleDelete() {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('deleteItem', {
        bubbles: true,
      }),
    );
  }

  panelOpen() {
    this.isPanelExpanded$.next(true);
  }

  panelClose() {
    this.isPanelExpanded$.next(false);
  }

  onDisabledClick() {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('validateQuestionSet', {
        bubbles: true,
      }),
    );
  }
}
