<label [id]="labelId" [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-full-width emperor-ui-text-input">
  <textarea
    matInput
    [id]="id || ''"
    [formControl]="control"
    [attr.aria-label]="ariaLabel ? ariaLabel : sanitizedLabelText"
    [attr.aria-labelledby]="emperorInputAriaLabeledBy"
    [attr.data-test]="qaAttributeHook"
    [placeholder]="placeholder ?? ''"
  >
  </textarea>
  <mat-error emperorErrorHandler [errorText]="errorText" [errorMessageMap]="errorMessageMap" [id]="code || ''"></mat-error>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
</mat-form-field>
