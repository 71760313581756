export const repeatingNumerals = (value: string) => {
  const repeatingRegEx = new RegExp(/\b(\d)\1+\b/);
  return repeatingRegEx.test(value);
};

export const containsNumericSequence = (value: string | null) => {
  const digitsOnly = value?.replace(/\D/g, '') || '';
  const sequentialRegEx = new RegExp(/^123456789[0]?$/);
  return sequentialRegEx.test(digitsOnly);
};

export const startsWith1 = (value: string | null) => {
  return value ? value[0] === '1' : false;
};
