<mat-card-title
  class="emperor-ui-eligibility-carrier-card-header"
  [ngClass]="{ interactive }"
  [attr.role]="interactive ? 'button' : null"
  [attr.tabindex]="interactive ? 0 : null"
  (click)="interactive && handleCarrierNameClick()"
  (keydown.enter)="interactive && handleCarrierNameClick()"
  (keydown.space)="interactive && handleCarrierNameClick()"
>
  <ng-content></ng-content>
</mat-card-title>
