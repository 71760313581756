import { ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SdkStoreService } from '@boldpenguin/emperor-services';
import {
  IAccordionComponent,
  IAnswer,
  IMappedQuestionGroupAnswers,
  ValidationStatus,
  getGroupId,
  setAddressValidationStatus,
  setAddressValidationTriggerOverridden
} from '@boldpenguin/sdk';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-accordion',
  templateUrl: './bp-sdk-accordion.component.html',
  styleUrls: ['./bp-sdk-accordion.component.scss'],
})
export class BpSdkAccordionComponent implements IAccordionComponent, OnInit, OnChanges {
  @ViewChild('expansionPanel') panel: MatExpansionPanel;
  @Input()
  questionGroup: IMappedQuestionGroupAnswers;
  @Input() startPanelExpanded = true;
  @Input() addressValidationStatus: ValidationStatus;

  answersAreValid$ = new BehaviorSubject<boolean>(false);
  isPanelExpanded$: BehaviorSubject<boolean>;
  questionGroupId$ = new BehaviorSubject<string>('');
  questionGroupLabel$ = new BehaviorSubject<string>('');

  isPrimaryAccordion: boolean;
  isValidatingAddress = false;

  /**
   * This event is adding a new answer pool.
   * The function onAddItem is UX for that event
   * @param $event parent answer pool id | null
   */
  @HostListener('document:addItem', ['$event'])
  onAddItem($event) {
    const { detail } = $event;
    const newParentAdded: boolean = !detail && this.isPrimaryAccordion;
    const childAddedToOtherParent: boolean = this.isPrimaryAccordion && detail !== this.questionGroup.parentQuestionAnswer.answer_pool_id;
    const isSiblingOfNewChild: boolean =
      !this.isPrimaryAccordion && detail === this.questionGroup.parentQuestionAnswer.parent_answer_pool_id;

    if (this.answersAreValid$.value) {
      if (newParentAdded || childAddedToOtherParent || isSiblingOfNewChild) {
        this.onCollapsePanel();
      }
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private storeService: SdkStoreService) {}

  ngOnInit(): void {
    this.isPanelExpanded$ = new BehaviorSubject(this.startPanelExpanded);
    this.isPrimaryAccordion = !this.questionGroup?.parentQuestionAnswer.parent_answer_pool_id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['questionGroup']) {
      if (!changes['questionGroup'].currentValue) {
        return;
      }
      this.isPrimaryAccordion = !this.questionGroup.parentQuestionAnswer.parent_answer_pool_id;

      const valid = this.areAllAnswersValid(changes['questionGroup'].currentValue);
      if (this.answersAreValid$.value !== valid) {
        this.answersAreValid$.next(valid);
        this.changeDetectorRef.detectChanges();
      }
      const groupId = getGroupId(changes['questionGroup'].currentValue);
      if (this.questionGroupId$.value !== groupId) {
        this.questionGroupId$.next(groupId);
      }

      const label =
        changes['questionGroup'].currentValue.parentQuestionAnswer.value ||
        changes['questionGroup'].currentValue.parentQuestionAnswer.question.text;
      if (this.questionGroupLabel$.value !== label) {
        this.questionGroupLabel$.next(label);
      }

      //only open panel if panel is already closed to prevent excessive dom updates
      if (!valid && this.isPanelExpanded$?.value === false) {
        this.onExpandPanel();
      }
    }

    if (changes['addressValidationStatus']) {
      if (
        (this.addressValidationStatus === ValidationStatus.COMPLETED || this.addressValidationStatus === ValidationStatus.PROCEED_NEXT) &&
        this.isValidatingAddress
      ) {
        this.panel.close();
        this.isPanelExpanded$.next(false);
        this.isValidatingAddress = false;
      }
    }
  }

  isAnswerPartOfNestedGroup(answer): boolean {
    return !!this.questionGroup.nestedGroupsWithAnswers.get(answer.id);
  }

  getNestedGroup(answer: IAnswer): IMappedQuestionGroupAnswers {
    return this.questionGroup.nestedGroupsWithAnswers.get(answer.id) as IMappedQuestionGroupAnswers;
  }

  trackAccordionAnswers(index, answer: IAnswer) {
    return answer.id;
  }

  onExpandPanel() {
    this.isPanelExpanded$.next(true);
  }

  onCollapsePanel() {
    if(this.addressValidationStatus === ValidationStatus.REQUIRED) {
      this.panel.open();
      this.isValidatingAddress = true;
      this.storeService.dispatch(setAddressValidationTriggerOverridden(true));
      this.storeService.dispatch(
        setAddressValidationStatus({
          validationStatus: ValidationStatus.READY_TO_VALIDATE,
          addressValidationId: this.questionGroup.poolId ?? this.questionGroup.groupId,
        }),
      );
    } else {
      this.isPanelExpanded$.next(false);
    }
  }

  private areAllAnswersValid(questionGroup: IMappedQuestionGroupAnswers): boolean {
    return questionGroup.answers.every(answer => {
      if (questionGroup.nestedGroupsWithAnswers.has(answer.id)) {
        return this.areAllAnswersValid(questionGroup.nestedGroupsWithAnswers.get(answer.id) as IMappedQuestionGroupAnswers);
      }
      return this.isAnswerComplete(answer);
    });
  }

  private isAnswerComplete(answer: IAnswer) {
    return answer.is_completed || !answer.required;
  }
}
