import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmperorCardComponent } from './card.component';
import { EmperorCardHeaderComponent } from './components/card-header/card-header.component';
import { EmperorCardFooterComponent } from './components/card-footer/card-footer.component';
import { EmperorCardContentComponent } from './components/card-content/card-content.component';

export * from './card.component';
export * from './components/card-header/card-header.component';
export * from './components/card-footer/card-footer.component';
export * from './components/card-content/card-content.component';

const declarations = [EmperorCardComponent, EmperorCardHeaderComponent, EmperorCardFooterComponent, EmperorCardContentComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule],
})
export class EmperorCardModule {}
