import { Inject, Injectable, Optional } from '@angular/core';
import { SdkWrapperModuleConfig, SDK_WRAPPER_MODULE_CONFIG } from '../models';

// This is intentionally not provided in 'root' because the configuration token comes from
// the SDKWrapperModule, and the service must be provided there
@Injectable()
export class SdkWrapperModuleConfigService {
  get shouldDisplayAnswerSource() {
    return this._shouldDisplayAnswerSource;
  }

  private _shouldDisplayAnswerSource = true;

  constructor(
    @Optional()
    @Inject(SDK_WRAPPER_MODULE_CONFIG)
    config?: SdkWrapperModuleConfig,
  ) {
    // Nullish coalescing will only use the default value for undefined/null, but not for false
    this._shouldDisplayAnswerSource = config?.shouldDisplayAnswerSource ?? true;
  }
}
