import { Component, HostBinding, inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { RouterLinkActive } from '@angular/router';
import { EmperorThemeColor } from '../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[emperor-nav-link]',
  templateUrl: './nav-link.component.html',
  styleUrls: ['../nav-button/nav-button.component.scss', './nav-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [RouterLinkActive],
})
export class NavLinkComponent extends MatAnchor implements OnInit, OnChanges {
  @Input() set primary(isPrimary: boolean) {
    this.color = isPrimary ? EmperorThemeColor.PRIMARY : undefined;
  }
  private readonly routerLinkActiveDirective: RouterLinkActive = inject(RouterLinkActive, { self: true });
  @Input() rounded: 'start' | 'end' | false = 'end';

  @HostBinding('class.emperor-ui-nav-button') navButton = true;
  @HostBinding('class.emperor-ui-nav-link') navLink = true;
  @HostBinding('class.emperor-ui-nav-link-not-rounded') get linkNotRounded(): boolean {
    return this.rounded === false;
  }
  @HostBinding('class.emperor-ui-nav-link-rounded-start') get linkRoundedStart(): boolean {
    return this.rounded === 'start';
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.disabled) {
      this.disabled = false;
      console.warn('emperor-nav-link does not support disabling links at this time');
    }
  }

  ngOnInit(): void {
    this.routerLinkActiveDirective.routerLinkActive = 'emperor-ui-nav-link-active';
  }
}
