import { NgModule } from '@angular/core';
import { DynamicCoverageButtonComponent } from './components/dynamic-coverage-button/dynamic-coverage-button.component';
import { DynamicCoverageDialogComponent } from './components/dynamic-coverage-dialog/dynamic-coverage-dialog.component';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@boldpenguin/emperor-icons';
import {
  EmperorButtonModule,
  EmperorCardModule,
  EmperorDialogModule,
  EmperorDialogRefService,
  EmperorSpinnerModule,
  PresentationalModule,
} from '@boldpenguin/emperor-presentational';
import { FormFieldsModule } from '@boldpenguin/emperor-form-fields';
import { ServicesModule } from '@boldpenguin/emperor-services';

export * from './components/dynamic-coverage-button/dynamic-coverage-button.component';
export * from './components/dynamic-coverage-dialog/dynamic-coverage-dialog.component';

@NgModule({
  declarations: [DynamicCoverageButtonComponent, DynamicCoverageDialogComponent],
  imports: [
    CommonModule,
    EmperorButtonModule,
    EmperorSpinnerModule,
    EmperorCardModule,
    EmperorDialogModule,
    PresentationalModule,
    IconsModule,
    FormFieldsModule,
    ServicesModule,
  ],
  exports: [DynamicCoverageButtonComponent, DynamicCoverageDialogComponent],
  providers: [EmperorDialogRefService],
})
export class DynamicCoverageModule {}
