import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmperorAlertIconMap, EmperorUIAlertType } from '../../models/alert-types.model';

@Component({
  selector: 'emperor-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
  @Input() icon: string | undefined;
  @Input() hideIcon = false;
  @Input() dismissTooltip = '';
  @Input() set uiAlertType(value: EmperorUIAlertType) {
    this._uiAlertType = value;
    this.iconName = this.getIconNameWithAlertTypeFallback();
  }
  get uiAlertType() {
    return this._uiAlertType;
  }

  iconName = this.getIconNameWithAlertTypeFallback();
  private _uiAlertType = EmperorUIAlertType.Info;

  @Output() dismiss = new EventEmitter<void>();

  ngOnInit(): void {
    this.iconName = this.getIconNameWithAlertTypeFallback();
  }

  /**
   * Order of precedence is: icon Input, alert type default
   */
  private getIconNameWithAlertTypeFallback(): string {
    return this.icon ?? EmperorAlertIconMap[this.uiAlertType];
  }
}
