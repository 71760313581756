/* eslint-disable no-extra-boolean-cast */
import { Injectable } from '@angular/core';
import { BpSdkRedux, BpSdkStore, getStore } from '@boldpenguin/sdk';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SdkStoreService {
  private _state$ = new BehaviorSubject<BpSdkStore | null>(null);

  public get state$(): Observable<BpSdkStore> {
    return this._state$.pipe(filter((state): state is BpSdkStore => state !== null));
  }

  constructor() {
    const store = getStore();
    if (store) {
      this.initStoreListener();
    }
  }

  unsubscribeFromStore: () => void;
  store: BpSdkRedux;

  initStoreListener() {
    this.store = getStore();
    // Make sure that if someone else is calling
    // initStoreListener and _not_ checking for the
    // store first, we don't do anything.
    if (this.store) {
      // If we previously were subscribed to the store, unsubscribe
      if (!!this.unsubscribeFromStore) {
        this.unsubscribeFromStore();
      }

      // set up the subscription so any changes from the bp-sdk store will
      // get pushed into our behavior subject
      this.unsubscribeFromStore = this.store.subscribe(() => {
        this._state$.next(this.store.getState());
      });

      // seed the behavior subject with our current sdk store state
      this._state$.next(this.store.getState());
    }
  }

  dispatch(action: AnyAction | AsyncThunkAction<any, any, any>) {
    this.store.dispatch(action);
  }
}
