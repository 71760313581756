/* eslint-disable @angular-eslint/no-output-on-prefix */
import { IHintWithIcon } from './../models/hint-with-icon.model';
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-output-native */
import {
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Directive()
export class InputDirective implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;
  control: FormControl;
  qaAttributeHook: string | null = null;
  emperorInputAriaLabeledBy = '';
  labelId?: string;
  sanitizedLabelText = '';

  /**
   * Numerical min for number inputs
   *
   * Could move to text input
   */
  @Input() min: number | null;
  /**
   * Numerical min for number inputs
   *
   * Could move to text input
   */
  @Input() max: number | null;
  /**
   * Placeholder for input
   */
  @Input() placeholder: string | null;
  /**
   * Regex expression for validation
   */
  @Input() pattern: string | null;
  @Input() formControlName: string | undefined;
  @Input() formControl: FormControl | undefined;
  @Input() label: string | null;
  @Input() labelMaxWordCount: number | null;
  /**
   * Label that will be set on aria-label
   *
   * If undefined the label will be place in this attribute
   */
  @Input() ariaLabel: string | null;
  @Input() ariaLabelledBy: string | null;
  @Input() ariaDescribedBy: string | null;
  /**
   * Override for standard validation.
   *
   * If this is present and the form is invalid and touched,
   * this text will be rendered instead of the invalid validation.
   */
  @Input() errorText: string | null;
  /**
   * Custom error messages for validation errors.
   *
   * Given an object that maps a validation error key to a given
   * string, if the form is invalid, touched, and the validation
   * key is present in the map, the corresponding string will be
   * displayed.
   *
   * This does not supersede an errorText.
   */
  @Input() errorMessageMap: { [index: string]: string } | null;
  @Input() id: string | undefined;
  /**
   * Text that is displayed below the input box.
   * Hint text are sanitized to render into HTML
   */
  @Input() hint: string | null;
  /**
   * Text that is displayed below the input box with an icon.
   * Hint text are sanitized to render into HTML
   *
   * If both hint and hintWithIcon are defined, hintWithIcon takes precedence and hint will not display
   */
  @Input() hintWithIcon: IHintWithIcon | undefined;
  /**
   * BP unique question or answer identifier.
   *
   * Used to see if a component has been reused on the page.
   */
  @Input() code: string | null;
  /**
   * Used to make input readonly via marking the formcontrol disabled.
   */
  @Input() isReadOnly: boolean;
  /**
   * Emits input focus
   */
  @Output() focus = new EventEmitter();
  /**
   * Emit input blur
   */
  @Output() blur = new EventEmitter();

  constructor(private injector: Injector, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.control = this.getControl() as FormControl;
    this.sanitizeHint();
    this.updateQaAttributeHook();
    this.updateLabelIdProperties();
    this.sanitizeLabelText();
  }

  /**
   * Watches for changes to formControl and formControlName to refetch the control.
   * Watches for changes in label to determine the label position
   * Watches for changes in hint to re-sanitize.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if ((changes.formControl && !changes.formControl.firstChange) || (changes.formControlName && !changes.formControlName.firstChange)) {
      this.control = this.getControl() as FormControl;
    }

    if (changes.label && !changes.label.firstChange) {
      this.sanitizeLabelText();
    }

    if (changes.ariaLabelledBy && !changes.ariaLabelledBy.firstChange) {
      this.updateLabelIdProperties();
    }

    if (changes.hint && !changes.hint.firstChange) {
      this.sanitizeHint();
    }

    if (changes.id && !changes.id.firstChange) {
      this.updateQaAttributeHook();
      this.updateLabelIdProperties();
    }

    if (changes.code && !changes.code.firstChange) {
      this.updateQaAttributeHook();
    }
    if (changes.isReadOnly) {
      this.handleIsReadOnlyChange(changes.isReadOnly.currentValue, changes.isReadOnly.previousValue);
    }
  }

  /**
   * Return the formControl of the parent. Either through assignment of the
   * passed in form control, or getting it from the control container via formControlName
   * @returns
   */
  getControl(): AbstractControl | null | undefined {
    if (this.formControl) {
      return this.formControl;
    } else if (this.formControlName) {
      const controlContainer = this.injector.get(ControlContainer);
      return controlContainer.control?.get(this.formControlName);
    }
  }

  /**
   * ControlValueAccessor function
   *
   * @param fn
   */
  registerOnTouched(fn: unknown): void {
    this.formControlDirective.valueAccessor?.registerOnTouched(fn);
  }

  /**
   * ControlValueAccessor function
   *
   * @param fn
   */
  registerOnChange(fn: unknown): void {
    this.formControlDirective.valueAccessor?.registerOnChange(fn);
  }

  /**
   * ControlValueAccessor function
   *
   * @param obj
   */
  writeValue(obj: unknown): void {
    this.formControlDirective.valueAccessor?.writeValue(obj);
  }

  /**
   * ControlValueAccessor function
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    if (this.formControlDirective?.valueAccessor?.setDisabledState) {
      this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    }
  }

  onChange = (_value: unknown) => {};
  onTouch = () => {};

  protected cleanValue(value: string): string {
    return value;
  }

  onBlur() {
    this.blur.emit();
    this.control.updateValueAndValidity();
    this.onTouch();
  }

  onFocus() {
    this.focus.emit();
  }

  private updateLabelIdProperties(): void {
    this.labelId = `${this.id}_label`;
    this.emperorInputAriaLabeledBy = this.ariaLabelledBy ? `${this.ariaLabelledBy} ${this.labelId}` : this.labelId;
  }

  private sanitizeHint() {
    if (this.hint) {
      this.sanitizer.sanitize(SecurityContext.HTML, this.hint);
    }
  }

  private sanitizeLabelText(): void {
    this.sanitizedLabelText = this.label?.replace(/<[^>]+>/g, '') || '';
  }

  private updateQaAttributeHook(): void {
    if (this.code && this.id) {
      this.qaAttributeHook = `${this.code}-${this.id}`;
    }
  }

  private handleIsReadOnlyChange(newValue, prevValue): void {
    if (newValue == prevValue) {
      return;
    }
    const currentState = this.getControl()?.disabled;
    const isReadOnly = !!newValue;
    if (isReadOnly === currentState) {
      return;
    }
    isReadOnly ? this.getControl()?.disable() : this.getControl()?.enable();
  }
}
