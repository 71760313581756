/* eslint-disable max-len */
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

import { FormFieldsModule } from '@boldpenguin/emperor-form-fields';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { ServicesModule } from '@boldpenguin/emperor-services';
import { BpSdkBaseComponentComponent } from './components/bp-sdk-base-component/bp-sdk-base-component.component';
import { BpSdkTextInputComponent } from './components/bp-sdk-text-input/bp-sdk-text-input.component';
import { BpSdkAddItemComponent } from './components/bp-sdk-add-item/bp-sdk-add-item.component';
import { BpSdkSelectInputComponent } from './components/bp-sdk-select-input/bp-sdk-select-input.component';
import { BpSdkMaskedInputComponent } from './components/bp-sdk-masked-input/bp-sdk-masked-input.component';
import { BpSdkRadioInputComponent } from './components/bp-sdk-radio-input/bp-sdk-radio-input.component';
import { BpSdkDateInputComponent } from './components/bp-sdk-date-input/bp-sdk-date-input.component';
import { BpSdkProductListComponent } from './components/bp-sdk-product-list/bp-sdk-product-list.component';
import { BpSdkCheckboxInputComponent } from './components/bp-sdk-checkbox-input/bp-sdk-checkbox-input.component';
import { BpSdkAutocompleteInputComponent } from './components/bp-sdk-autocomplete-input/bp-sdk-autocomplete-input.component';
import { BpSdkGoogleAutocompleteComponent } from './components/bp-sdk-google-autocomplete/bp-sdk-google-autocomplete.component';
import { BpSdkBusinessClassificationSelectorComponent } from './components/bp-sdk-business-classification-selector/bp-sdk-business-classification-selector.component';
import { BpSdkBusinessClassificationSelectorNaicsGroupHeaderComponent } from './components/bp-sdk-business-classification-selector-naics-group-header/bp-sdk-business-classification-selector-naics-group-header.component';
import { BpSdkBusinessClassificationSelectorCardContentComponent } from './components/bp-sdk-business-classification-selector-card-content/bp-sdk-business-classification-selector-card-content.component';
import { BcsNaicsHeaderDialogComponent } from './components/bp-sdk-business-classification-selector-naics-group-header/bcs-naics-header-dialog/bcs-naics-header-dialog.component';
import { BpSdkQuestionSetNavigationComponent } from './components/bp-sdk-question-set-navigation/bp-sdk-question-set-navigation.component';
import {
  EmperorButtonModule,
  EmperorTimelineModule,
  EmperorSpinnerModule,
  EmperorTooltipModule,
  EmperorQuotePresentationModule,
  EmperorCardModule,
  EmperorDialogModule,
  PresentationalModule,
} from '@boldpenguin/emperor-presentational';
import { BpSdkBackButtonComponent } from './components/bp-sdk-back-button/bp-sdk-back-button.component';
import { BpSdkIncrementInputComponent } from './components/bp-sdk-increment-input/bp-sdk-increment-input.component';
import { BpSdkQuestionSetNextButtonComponent } from './components/bp-sdk-question-set-next-button/bp-sdk-question-set-next-button.component';
import { BpSdkQuoteRequestComponent } from './components/bp-sdk-quote-request/bp-sdk-quote-request.component';
import { BpSdkQuestionSetHeaderComponent } from './components/bp-sdk-question-set-header/bp-sdk-question-set-header.component';
import { BpSdkSocketDisconnectionDialogComponent } from './components/bp-sdk-socket-disconnection-dialog/bp-sdk-socket-disconnection-dialog.component';
import { SdkWrapperModuleConfig, SDK_WRAPPER_MODULE_CONFIG } from './models';
import { SdkWrapperModuleConfigService } from './service/sdk-wrapper-module-config.service';
import { BpSdkVehicleEditButtonComponent } from './components/bp-sdk-vehicle-edit-button/bp-sdk-vehicle-edit-button.component';
import { BpSdkVehicleSaveButtonComponent } from './components/bp-sdk-vehicle-save-button/bp-sdk-vehicle-save-button.component';
import { BpSdkVehicleDeleteButtonComponent } from './components/bp-sdk-vehicle-delete-button/bp-sdk-vehicle-delete-button.component';
import { BpSdkVehiclePanelComponent } from './components/bp-sdk-vehicle-panel/bp-sdk-vehicle-panel.component';
import { BpSdkDriverGroupComponent } from './components/bp-sdk-driver-group/bp-sdk-driver-group.component';
import { BpSdkAccordionComponent } from './components/bp-sdk-accordion/bp-sdk-accordion.component';
import { BpSdkEmptyComponent } from './components/bp-sdk-empty/bp-sdk-empty.component';
import { BpSdkSlimBusinessClassSelectorComponent } from './components/bp-sdk-slim-business-class-selector/bp-sdk-slim-business-class-selector.component';
import { BcsExamplesPipe } from './components/bp-sdk-slim-business-class-selector/bcs-examples.pipe';
import { BpSdkSlimBusinessClassSelectorListComponent } from './components/bp-sdk-slim-business-class-selector-list/bp-sdk-slim-business-class-selector-list.component';
import { BpSdkPendingProcessComponent } from './components/bp-sdk-pending-process/bp-sdk-pending-process.component';
import { BpSdkCreateAppFormButtonComponent } from './components/bp-sdk-create-app-form-button/bp-sdk-create-app-form-button.component';
import { BpSdkQuestionPoolDeleteButtonComponent } from './components/bp-sdk-question-pool-delete-button/bp-sdk-question-pool-delete-button.component';
import { BpSdkCoverageGroupComponent } from './components/bp-sdk-coverage-group/bp-sdk-coverage-group.component';
import { BpSdkParentQuestionPoolSelectorComponent } from './components/bp-sdk-parent-question-pool-selector/bp-sdk-parent-question-pool-selector.component';
import { BpSdkQuestionSetComponent } from './components/bp-sdk-question-set/bp-sdk-question-set.component';
import { BpSdkMessageOutletComponent } from './components/bp-sdk-message-outlet/bp-sdk-message-outlet.component';
import { BpSdkGoogleAddressValidationComponent } from './components/bp-sdk-google-address-validation/bp-sdk-google-address-validation.component';
import { AddressValidationDialogService } from './service/address-validation-dialog.service';
import { BpSdkMonthYearInputComponent } from './components/bp-sdk-month-year-input/bp-sdk-month-year-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormFieldsModule,
    MatExpansionModule,
    IconsModule,
    ServicesModule,
    EmperorTimelineModule,
    EmperorButtonModule,
    EmperorTimelineModule,
    EmperorSpinnerModule,
    EmperorTooltipModule,
    EmperorQuotePresentationModule,
    EmperorCardModule,
    EmperorDialogModule,
    PresentationalModule,
  ],
  declarations: [
    BpSdkTextInputComponent,
    BpSdkBaseComponentComponent,
    BpSdkAddItemComponent,
    BpSdkSelectInputComponent,
    BpSdkMaskedInputComponent,
    BpSdkRadioInputComponent,
    BpSdkDateInputComponent,
    BpSdkBackButtonComponent,
    BpSdkQuestionSetNavigationComponent,
    BpSdkProductListComponent,
    BpSdkCheckboxInputComponent,
    BpSdkBusinessClassificationSelectorComponent,
    BpSdkBusinessClassificationSelectorNaicsGroupHeaderComponent,
    BpSdkBusinessClassificationSelectorCardContentComponent,
    BpSdkAutocompleteInputComponent,
    BpSdkGoogleAddressValidationComponent,
    BpSdkGoogleAutocompleteComponent,
    BcsNaicsHeaderDialogComponent,
    BpSdkIncrementInputComponent,
    BpSdkQuestionSetNextButtonComponent,
    BpSdkQuoteRequestComponent,
    BpSdkQuestionSetHeaderComponent,
    BpSdkSocketDisconnectionDialogComponent,
    BpSdkVehicleEditButtonComponent,
    BpSdkVehicleSaveButtonComponent,
    BpSdkVehicleDeleteButtonComponent,
    BpSdkVehiclePanelComponent,
    BpSdkDriverGroupComponent,
    BpSdkAccordionComponent,
    BpSdkEmptyComponent,
    BpSdkSlimBusinessClassSelectorComponent,
    BcsExamplesPipe,
    BpSdkSlimBusinessClassSelectorListComponent,
    BpSdkPendingProcessComponent,
    BpSdkCreateAppFormButtonComponent,
    BpSdkQuestionPoolDeleteButtonComponent,
    BpSdkCoverageGroupComponent,
    BpSdkParentQuestionPoolSelectorComponent,
    BpSdkQuestionSetComponent,
    BpSdkMessageOutletComponent,
    BpSdkMonthYearInputComponent,
  ],
  exports: [
    BpSdkBaseComponentComponent,
    BpSdkAddItemComponent,
    BpSdkSelectInputComponent,
    BpSdkQuestionSetNavigationComponent,
    BpSdkMaskedInputComponent,
    BpSdkRadioInputComponent,
    BpSdkDateInputComponent,
    BpSdkBackButtonComponent,
    BpSdkProductListComponent,
    BpSdkCheckboxInputComponent,
    BpSdkBusinessClassificationSelectorComponent,
    BpSdkBusinessClassificationSelectorNaicsGroupHeaderComponent,
    BpSdkBusinessClassificationSelectorCardContentComponent,
    BpSdkAutocompleteInputComponent,
    BpSdkGoogleAddressValidationComponent,
    BpSdkGoogleAutocompleteComponent,
    BpSdkIncrementInputComponent,
    BpSdkQuestionSetNextButtonComponent,
    BpSdkQuoteRequestComponent,
    BpSdkQuestionSetHeaderComponent,
    BpSdkSocketDisconnectionDialogComponent,
    BpSdkVehicleEditButtonComponent,
    BpSdkVehicleSaveButtonComponent,
    BpSdkVehicleDeleteButtonComponent,
    BpSdkCreateAppFormButtonComponent,
    BpSdkSlimBusinessClassSelectorComponent,
    BcsExamplesPipe,
    BpSdkQuestionSetComponent,
    BpSdkSlimBusinessClassSelectorListComponent,
    BpSdkMessageOutletComponent,
    BpSdkMonthYearInputComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SdkWrapperModuleConfigService, AddressValidationDialogService],
})
export class SDKWrapperModule {
  static forRoot(config?: SdkWrapperModuleConfig | undefined): ModuleWithProviders<SDKWrapperModule> {
    return {
      ngModule: SDKWrapperModule,
      providers: [
        {
          provide: SDK_WRAPPER_MODULE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
