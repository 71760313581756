import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { InputDirective } from '../../directive/input.directive';

import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'emperor-google-autocomplete-input',
  templateUrl: './google-autocomplete-input.component.html',
  styleUrls: ['./google-autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleAutocompleteInputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GoogleAutocompleteInputComponent extends InputDirective {
  @Input() valueToDisplay: string | null;
  @Output() autocompletedSelected = new EventEmitter<PlaceResult>();

  private _warning$ = new BehaviorSubject<string | undefined>(undefined);
  readonly warning$ = this._warning$.asObservable();

  @Input() set warning(warning: string | undefined) {
    this._warning$.next(warning);
  }

  selectedPlace(place: PlaceResult) {
    // Force update the address 1 field to be what google returns.
    const replacementValue = this.extractStreet1Value(place);
    if (replacementValue) {
      this.control.setValue(replacementValue);
    }
    this.autocompletedSelected.emit(place);
  }

  private extractStreet1Value(place): string | null {
    const address_pieces = place.address_components.reduce(
      (acc, itm) => {
        if (itm.types.indexOf('street_number') !== -1) {
          acc.number = itm.long_name;
        }
        if (itm.types.indexOf('route') !== -1) {
          acc.street = itm.long_name;
        }
        return acc;
      },
      { number: undefined, street: undefined },
    );
    if (address_pieces.number != null && address_pieces.street != null) {
      return `${address_pieces.number} ${address_pieces.street}`;
    }
    return null;
  }
}
