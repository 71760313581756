import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { arraysEqual } from '@boldpenguin/emperor-common';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';
import { IChoice, ISelectInputComponent } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, pairwise, startWith } from 'rxjs';
import { convertChoicesToGenericChoiceType } from '../../utils/choiceToGenericType';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-select-input',
  templateUrl: './bp-sdk-select-input.component.html',
  styleUrls: ['./bp-sdk-select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkSelectInputComponent extends BpSdkBaseComponentComponent implements OnChanges, OnInit, ISelectInputComponent {
  /**
   * Autocomplete attribute value for input
   */
  @Input() autoComplete: string | null;
  /**
   * 	Choice id selected. Used for single selection.
   */
  @Input() choiceId: string | null;
  /**
   * 	Choice ids selected. Used for multiple selection.
   */
  @Input() choiceIds: string[];
  /**
   *  Choices to display
   */
  @Input() choices: IChoice[];
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Whether to allow multi-select
   */
  @Input() multiple = false;
  /**
   * Placeholder to display
   */
  @Input() placeholder: string;
  /**
   * Use alias for choices
   */
  @Input() useAlias = false;
  /**
   * Current value
   */
  @Input() value: string | null;

  mappedChoices$ = new BehaviorSubject<EmperorUISelectFormChoice[]>([]);
  hasFocus$ = new BehaviorSubject(false);

  ngOnInit(): void {
    super.ngOnInit();

    this.setInitialValue();

    this.mapChoices(this.choices, []);

    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        pairwise(),
        filter(([prev, next]) => this.filterValue(prev, next)),
        untilDestroyed(this),
      )
      .subscribe(([_prev, next]) => {
        const detail = Array.isArray(next) ? next : [next];
        this.elementRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { detail, bubbles: true }));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).some(k => k === 'choiceId')) {
      if (this.formControl.value !== changes.choiceId.currentValue && !this.hasFocus$.value) {
        this.formControl.setValue(changes.choiceId.currentValue, {
          emitEvent: false,
        });
      }
    }
    if (this.multiple && Object.keys(changes).some(k => k === 'choiceIds') && !this.hasFocus$.value) {
      if (!arraysEqual(this.formControl.value, this.choiceIds)) {
        this.formControl.setValue(changes.choiceIds.currentValue, {
          emitEvent: false,
        });
      }
    }
    if (Object.keys(changes).includes('choices') && !changes.choices.firstChange) {
      this.mapChoices(changes.choices.currentValue, changes.choices.previousValue);
    }
  }

  mapChoices(currentChoices: IChoice[], previousChoices: IChoice[]) {
    if (Array.isArray(currentChoices) && Array.isArray(previousChoices)) {
      const formattedChoices = convertChoicesToGenericChoiceType(currentChoices, this.useAlias);
      const formattedCurrentChoices = convertChoicesToGenericChoiceType(previousChoices, this.useAlias);
      if (!arraysEqual(formattedChoices, formattedCurrentChoices)) {
        this.mappedChoices$.next(formattedChoices);
      }
    }
  }

  onFocus() {
    this.hasFocus$.next(true);
  }

  onBlur() {
    this.hasFocus$.next(false);
  }

  private setInitialValue() {
    if (this.choiceId) {
      this.formControl.setValue(this.choiceId, { emitEvent: false });
    } else if (this.choiceIds?.length > 0) {
      this.formControl.setValue(this.choiceIds, { emitEvent: false });
    }
  }

  private filterValue(prev: string | string[], next: string | string[]): boolean {
    if (this.multiple) {
      return !arraysEqual(prev, next);
    }
    return prev !== next;
  }
}
