import { Component } from '@angular/core';
import { markCoverageLimitAccordionsAsExpanded } from '@boldpenguin/emperor-common';
import {
  EmperorCardHeaderColor,
  EmperorDialogConfig,
  EmperorDialogRef,
  EmperorDialogSize,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { SdkStoreService, SelectorWrapperService } from '@boldpenguin/emperor-services';
import { IApplicationFormQuestionSet, ICarrier, updateActiveQuestionSet } from '@boldpenguin/sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DynamicCoveragesEvent } from '../dynamic-coverage-button/dynamic-coverage-button.component';

@Component({
  selector: 'emperor-dynamic-coverage-dialog',
  templateUrl: './dynamic-coverage-dialog.component.html',
  styleUrls: ['./dynamic-coverage-dialog.component.scss'],
})
export class DynamicCoverageDialogComponent {
  quoteId: string;
  appFormId: string;
  carrier: ICarrier;
  showSpinner$ = new BehaviorSubject(false);

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly EmperorDialogSize = EmperorDialogSize;

  constructor(
    private dialogRef: EmperorDialogRef,
    config: EmperorDialogConfig,
    private sdkStoreService: SdkStoreService,
    private selectorWrapperService: SelectorWrapperService,
  ) {
    this.quoteId = config?.data?.quoteId;
    this.appFormId = config?.data?.appFormId;
    this.carrier = config?.data?.carrier;
  }

  onCloseDialog(): void {
    this.dialogRef.close(DynamicCoveragesEvent.CANCELLED);
  }

  findCorrectQuestionSet(subdomain: string): Observable<IApplicationFormQuestionSet | undefined> {
    return this.selectorWrapperService.getQuestionSets$().pipe(
      take(1),
      map(questionSets => {
        const questionSet = questionSets.find(qs => qs?.question_set?.subdomain === subdomain);
        return questionSet != null ? questionSet : undefined;
      }),
    );
  }

  onSaveChanges(): void {
    this.showSpinner$.next(true);

    markCoverageLimitAccordionsAsExpanded(this.appFormId);

    this.findCorrectQuestionSet(this.carrier.code)
      .pipe(take(1))
      .subscribe(questionSet => {
        if (questionSet) {
          this.sdkStoreService.dispatch(updateActiveQuestionSet(questionSet?.question_set_id));
        }
        this.showSpinner$.next(false);
        this.dialogRef.close(DynamicCoveragesEvent.SELECTED);
      });
  }
}
