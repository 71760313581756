export * from './module-config.model';
export * from './source.model';
export * from './bp-sdk-question-set.model';

export enum CarrierCode {
  HISCOX = 'hiscox',
}

export enum AnalyticsTrackingEventName {
  ModifyPrefilledValue = 'ModifyPrefilledValue',
  AddressValidation_CloseDialogClicked = 'AddressValidationCloseDialogClicked',
  AddressValidation_DialogOpened = 'AddressValidationDialogOpened'
}
