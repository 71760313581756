import { Component, ElementRef, forwardRef, Injector, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { EmperorThemeColor } from '@boldpenguin/emperor-presentational';
import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';
import { EmperorUISelectFormChoice } from '../../models';
import { BPErrorMessage, BPErrorState } from '../../utils/input-error-state';

@Component({
  selector: 'emperor-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent extends InputFieldsetDisableDirective {
  @Input() choices: EmperorUISelectFormChoice[];
  @Input() withSupportingText = false;
  @Input() color = EmperorThemeColor.PRIMARY;

  constructor(injector: Injector, sanitizer: DomSanitizer, elementRef: ElementRef) {
    super(injector, sanitizer, elementRef);
  }

  showErrorState(): boolean {
    return BPErrorState(this.control);
  }
  showErrorMessages(): string {
    return BPErrorMessage(this.control.errors, this.errorText, this.errorMessageMap);
  }

  identifyRadioId(index: number, choice: EmperorUISelectFormChoice): string {
    return choice.id;
  }
}
