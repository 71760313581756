import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ICheckboxInputComponent } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, skip, startWith } from 'rxjs';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-checkbox-input',
  templateUrl: './bp-sdk-checkbox-input.component.html',
  styleUrls: ['./bp-sdk-checkbox-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkCheckboxInputComponent extends BpSdkBaseComponentComponent implements OnInit, OnChanges, ICheckboxInputComponent {
  /**
   * Question value
   */
  @Input() value: boolean | null;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Is read only?
   */
  @Input() override isReadOnly: boolean;

  ngOnInit(): void {
    super.ngOnInit();

    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        distinctUntilChanged(),
        skip(1),
        untilDestroyed(this),
      )
      .subscribe(value => {
        this.elementRef.nativeElement.dispatchEvent(
          new CustomEvent('valueUpdate', {
            detail: value,
            bubbles: true,
          }),
        );
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).includes('value')) {
      if (changes.value.currentValue !== this.formControl.value) {
        this.formControl.setValue(changes.value.currentValue, {
          emitEvent: false,
        });
      }
    }
  }
}
