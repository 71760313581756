<emperor-dialog
  #card
  [color]="color"
  [backdropDismiss]="backdropDismiss"
  [uiDialogSize]="uiDialogSize"
  [ariaLabelledBy]="ariaLabelledBy"
  [ariaDescribeby]="ariaDescribeby"
  [class.force-to-front]="forceToFront"
>
  <ng-template emperorDialogInsertion></ng-template>
</emperor-dialog>
