import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IQuestionSetHeaderComponent } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-question-set-header',
  templateUrl: './bp-sdk-question-set-header.component.html',
  styleUrls: ['./bp-sdk-question-set-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkQuestionSetHeaderComponent implements IQuestionSetHeaderComponent {
  @Input() applicationId: string;
  @Input() code: string;
  @Input() name: string;
  @Input() setId: string;
  @Input() setTitle: string;
  @Input() isActive: boolean;
  @Input() isCompleted: boolean;
  @Input() tenantId: string;
}
