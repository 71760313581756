import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IAutocompleteChoice, IIconPrefix } from '@boldpenguin/emperor-form-fields';
import { IAutocompleteInputComponent, IChoice, QuestionFieldType } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-autocomplete-input',
  templateUrl: './bp-sdk-autocomplete-input.component.html',
  styleUrls: ['./bp-sdk-autocomplete-input.component.scss'],
})
export class BpSdkAutocompleteInputComponent extends BpSdkBaseComponentComponent implements OnInit, OnChanges, IAutocompleteInputComponent {
  /**
   * Allow text to fall through if no selected choices
   */
  @Input() allowFallthrough = false;
  /**
   * Selected choice id
   */
  @Input() choiceId: string | null;
  /**
   * Choices to display
   */
  @Input() choices: IChoice[];
  /**
   * Disables dropdown
   */
  @Input() disableDropdown = false;
  /**
   * Show choices
   */
  @Input() showChoices = false;
  /**
   * Field type
   */
  @Input() fieldType: QuestionFieldType;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Placeholder text
   */
  @Input() placeholder: string | null;
  /**
   * Should show no choices fallback if list is empty
   */
  @Input() showNoChoicesFallback = true;

  mappedChoices$ = new BehaviorSubject<[]>([]);

  readonly iconPrefix: IIconPrefix = {
    showPrefix: true,
    icon: 'search_1',
  };

  ngOnInit(): void {
    super.ngOnInit();

    this.setInitialValue();
    this.formControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged(), untilDestroyed(this))

      .subscribe(search => {
        this.emitFilterUpdate(search);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.choiceId) {
      const selectedChoice = this.choices.find(choice => choice.id === this.choiceId);

      const hasValueBeenCleared = !!changes.choiceId.previousValue && !this.choiceId;

      // In fall-through scenarios, the text value is the answer value,
      // and the selected choice could be undefined. In that case, the control
      // value should not be updated because it would remove the text value.
      // However, if the choiceId has been reset externally, the value should
      // be reset here because it was done intentionally rather than as a side
      // effect of not finding the selected choice.
      if (!this.allowFallthrough || hasValueBeenCleared) {
        this.formControl.setValue(selectedChoice?.value, {
          emitEvent: false,
        });
      }
    }

    if (changes.choices) {
      this.mappedChoices$.next(
        changes.choices.currentValue.map((c: IChoice) => ({
          id: c.id,
          label: c.value,
          value: c,
        })),
      );
    }
  }

  handleBlur(): void {
    if (!this.choiceId || this.allowFallthrough) {
      const matchedChoices = this.choices.filter(choice => choice.value === this.formControl.value);

      if (matchedChoices.length === 0) {
        if (this.allowFallthrough && this.formControl.value) {
          this.elementRef.nativeElement.dispatchEvent(
            new CustomEvent('valueUpdate', {
              detail: [this.formControl.value],
              bubbles: true,
            }),
          );
        } else {
          this.clearSearch();
        }
      }
    }
  }

  handleFocus(): void {
    if (this.choices.length === 1 && this.choices[0].value === this.formControl.value) {
      this.emitFilterUpdate(this.formControl.value);
    }
  }

  clearSearch(): void {
    this.clearSelectedOption();
    this.formControl.reset();
    this.formControl.markAsTouched();
  }

  private clearSelectedOption(): void {
    // Fallthrough is for CA. Sending `null` to years/makes/model sdk components
    // was causing them to have a `required` validation error making it so that
    // when a new value bubbled through, it wouldn't save because of the error
    if (!this.allowFallthrough) {
      this.elementRef.nativeElement.dispatchEvent(
        new CustomEvent('valueUpdate', {
          detail: [null],
          bubbles: true,
        }),
      );
    }
  }

  onOptionSelected(choice: IAutocompleteChoice<IChoice>) {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('valueUpdate', {
        detail: [choice.id],
        bubbles: true,
      }),
    );
  }

  private setInitialValue() {
    if (this.choiceId) {
      const selectedChoice = this.choices.find(choice => choice.id === this.choiceId);
      if (selectedChoice) {
        this.formControl.setValue(selectedChoice?.value, {
          emitEvent: false,
        });
      } else if (this.allowFallthrough) {
        this.formControl.setValue(this.choiceId, { emitEvent: false });
      }
    }
  }

  private emitFilterUpdate(search: string) {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('filterUpdate', {
        detail: {
          id: this.questionId,
          value: search ? search.toLowerCase() : '',
        },
        bubbles: true,
      }),
    );
  }
}
