import { Injectable } from '@angular/core';
import { IAuthenticatorConfig, IUser, logout as authLogout, getItem, loggedOut as storeLogout } from '@boldpenguin/sdk';
import { Observable, combineLatest, filter, race, timer } from 'rxjs';
import { delay, distinctUntilChanged, first, map, shareReplay } from 'rxjs/operators';
import { SelectorWrapperService } from './selector-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isLoggedIn$: Observable<boolean>;

  get token(): string | null {
    return getItem('token');
  }

  get authorization(): string {
    return `Bearer ${this.token}`;
  }

  constructor(private readonly selectorWrapperService: SelectorWrapperService) {
    this.isLoggedIn$ = combineLatest({
      isUserAuthenticated: this.selectorWrapperService.isUserAuthenticated$(),
      isReady: this.selectorWrapperService.isSdkReady$(),
    }).pipe(
      filter(({ isReady }) => isReady),
      map(({ isUserAuthenticated }) => isUserAuthenticated),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  /**
   * Determine if user is authenticated, handling the case
   * when local state is not initialized.
   *
   * 1a. State loads and user is authenticated
   * 1b. State loads and user is NOT authenticated
   * 2.  State did not load within 1s timeout, assume user is NOT authenticated
   *
   * @returns Observable<boolean> emits once, true if user is authenticated
   */
  isAuthenticated(): Observable<boolean> {
    return race([this.isLoggedIn$.pipe(), timer(1000).pipe(map(() => false))]).pipe(first());
  }

  /**
   * Logout user session and reset local state
   *
   * @param config IAuthenticatorConfig (@boldpenguin/sdk)
   * @returns Observable<boolean> emits when user is logged out
   */
  logout(config: IAuthenticatorConfig): Observable<boolean> {
    const loggedOut$ = this.isLoggedIn$.pipe(
      filter(authed => !authed),
      first(),
      delay(0),
    );
    authLogout(config).then(() => {
      window.BpSdk?.store.dispatch(storeLogout());
    });
    return loggedOut$;
  }

  /**
   * @deprecated Will be removed in a future major version release
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  updateLoggedInStatus(status: boolean) {}

  hasPermission(user: IUser, route: string, action: string, object: any = null): boolean {
    if (!user?.extra?.raw_info?.permissions) {
      return false;
    }
    const {
      extra: {
        raw_info: { permissions },
      },
    } = user;
    let model_matches = false;
    let object_matches = object === null;
    for (const permission of permissions) {
      const _obj = permission.split(':')[0];
      const _id = permission.split(':')[1];
      const _m = permission.split(':')[2];
      const _a = permission.split(':')[3];
      if (_m === route && _a === action) {
        model_matches = true;

        if (object) {
          switch (_obj) {
            case 'Tenant':
              if (_id === object.tenant_id) {
                object_matches = true;
              }
              break;

            case 'Owner':
              if (_id === object.owner_id) {
                object_matches = true;
              }
              break;

            case 'UserGroup':
              if (_id === object.user_group_id) {
                object_matches = true;
              }
              break;
          }
        } else {
          break;
        }
      }
    }
    return model_matches && object_matches;
  }
}
