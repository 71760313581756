import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SelectorWrapperService } from '@boldpenguin/emperor-services';
import { IMessage } from '@boldpenguin/sdk';
import { Observable } from 'rxjs';

@Component({
  selector: 'emperor-bp-sdk-message-outlet',
  templateUrl: './bp-sdk-message-outlet.component.html',
  styleUrls: ['./bp-sdk-message-outlet.component.scss'],
})
export class BpSdkMessageOutletComponent implements OnInit, OnChanges {

  @Input() answerId: string;
  messages$: Observable<IMessage[]>;

  constructor(private selectorWrapperService: SelectorWrapperService) {}

  ngOnInit() {
    this.buildObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.answerId && !changes.answerId.isFirstChange()) {
      this.buildObservable();
    }
  }

  private buildObservable() {
    this.messages$ = this.selectorWrapperService.getInlineMessagesForAnswerId$(this.answerId);
  }
}
