import { Injectable } from '@angular/core';
import { IAnswer, IMappedQuestionGroupAnswers, areAnswersValid, getGroupId } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

const caJsonRefType = 'ca_json';
const vehicleQuestionRefType = 'ca_flow';

export const questionCodeMap = {
  vehicleFlow: 'mqs_vehicle_flow',
  vin: 'mqs_vehicle_vin',
};

export interface AnswerIdMap {
  vehicleFlow: string;
  vin: string;
}

@UntilDestroy()
@Injectable()
export class VehicleService {
  isEditingTypeOfVehicle$ = new BehaviorSubject<boolean>(false);
  finishedEditingTypeOfVehicle$: Observable<boolean>;

  questionGroupId$ = new BehaviorSubject<string>('');
  questionGroup$ = new BehaviorSubject<IMappedQuestionGroupAnswers | undefined>(undefined);
  vehicleFlow$: Observable<IAnswer | undefined>;

  answersAreValid$ = new BehaviorSubject<boolean>(false);
  answers$ = new BehaviorSubject<IAnswer[]>([]);
  answerIdMap$: Observable<AnswerIdMap>;
  vinValue$: Observable<string>;

  constructor() {
    this.finishedEditingTypeOfVehicle$ = this.answers$.pipe(
      map(answers => answers.find(answer => answer.question.reference_type === caJsonRefType)),
      map((answer: IAnswer | undefined) => (answer ? answer.value !== null : false)),
      startWith(false),
      distinctUntilChanged(),
      untilDestroyed(this),
    );

    this.vehicleFlow$ = this.questionGroup$.pipe(
      filter(qg => !!qg),
      map(qg => qg?.answers.find(a => a.question.reference_type === vehicleQuestionRefType && a.answer_pool_id === qg.poolId)),
      distinctUntilChanged((a1, a2) => a1?.choice_id === a2?.choice_id),
    );

    this.answerIdMap$ = this.questionGroup$.pipe(
      map(questionGroup => {
        const answers = questionGroup?.answers ?? [];
        return {
          vin: this.getVinAnswerId(answers),
          vehicleFlow: this.getAnswerIdForCode(answers, questionCodeMap.vehicleFlow),
        };
      }),
      untilDestroyed(this),
    );

    this.vinValue$ = combineLatest({
      questionGroup: this.questionGroup$,
      answerId: this.answerIdMap$,
    }).pipe(
      map(({ questionGroup, answerId }) => {
        return questionGroup?.answers.find(ans => ans.id == answerId.vin)?.value || '';
      }),
      distinctUntilChanged(),
      untilDestroyed(this),
    );
  }

  private getVinAnswerId(answers: IAnswer[]): string {
    const answerId = this.getAnswerIdForCode(answers, questionCodeMap.vin);
    if (answerId !== '') {
      return answerId;
    } else {
      const re = new RegExp(/^mqs_vehicle_vin_\d+$/);
      const answer = answers.find(answer => re.test(answer.question.code));
      return answer?.id || '';
    }
  }

  private getAnswerIdForCode(answers: IAnswer[], questionCode: string): string {
    const answer = answers.find(answer => answer.question.code === questionCode);
    return answer?.id ?? '';
  }

  updateAnswers(answers: IAnswer[]): void {
    this.answers$.next(answers);
    const valid = areAnswersValid(answers);

    if (valid !== this.answersAreValid$.value) {
      this.answersAreValid$.next(valid);
    }
  }

  updateEditingState(isEditing: boolean): void {
    this.isEditingTypeOfVehicle$.next(isEditing);
  }

  updateQuestionGroup(questionGroup: IMappedQuestionGroupAnswers) {
    this.questionGroup$.next(questionGroup);
    this.questionGroupId$.next(getGroupId(questionGroup));
  }
}
