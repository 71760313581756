/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  OnDestroy,
  Optional,
  Renderer2,
  Type,
  ViewChild,
} from '@angular/core';
import { EmperorDialogRef } from '../../dialog-ref';
import { Subject } from 'rxjs';
import { DialogInsertionDirective } from '../../directives/dialog-insertion.directive';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { EmperorThemeColor } from '../../../models';
import { EmperorDialogSize } from '../../models/dialog-size.model';
import { EmperorDialogConfig } from '../../services/emperor-dialog-ref.service';

@Component({
  selector: 'emperor-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrls: ['./dynamic-dialog.component.scss'],
})
export class DynamicDialogComponent implements AfterViewInit, OnDestroy {
  private readonly _onClose = new Subject<any>();

  componentRef: ComponentRef<any>;
  childComponentType: Type<any>;
  onClose = this._onClose.asObservable();
  color: EmperorThemeColor = EmperorThemeColor.PRIMARY;
  uiDialogSize: EmperorDialogSize | undefined;
  backdropDismiss = true;
  ariaLabelledBy = '';
  ariaDescribeby = '';
  forceToFront = false;

  @ViewChild(DialogInsertionDirective) insertionPoint: DialogInsertionDirective;

  @ViewChild('card', { read: ElementRef }) cardElement: ElementRef;

  overlayScrollStrategy: ScrollStrategy;

  // case for hitting escape key
  @HostListener('document:keydown.escape', ['$event']) onEscapeKeydownHandler(event: KeyboardEvent) {
    event && event.stopPropagation();
    this.dialogRef.close();
  }

  // handling clicks and detecting outside clicked
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const classes = Array.from((event?.target as HTMLElement | undefined)?.classList || []);
    if (classes.includes('backdrop')) {
      this.dialogRef.close();
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogRef: EmperorDialogRef,
    private renderer: Renderer2,
    overlay: Overlay,
    @Optional() config: EmperorDialogConfig,
  ) {
    this.overlayScrollStrategy = overlay.scrollStrategies.block();
    if (config) this.assignConfigValues(config);
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private loadChildComponent(componentType: Type<any>) {
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentType);
    this.renderer.setStyle(this.componentRef.location.nativeElement, 'display', 'contents');
  }

  private assignConfigValues(config: EmperorDialogConfig): void {
    this.color = config?.color || EmperorThemeColor.PRIMARY;
    this.backdropDismiss = config.backdropDismiss == null ? true : config.backdropDismiss;
    this.uiDialogSize = config.uiDialogSize;
    this.ariaLabelledBy = config.ariaLabelledBy || '';
    this.ariaDescribeby = config.ariaDescribeby || '';
    this.forceToFront = config.forceToFront || false;
  }
}
