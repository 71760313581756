import { Injectable, Optional } from '@angular/core';

export class SDKCustomElementServiceConfig {
  elementsToNotOverride: string[];
}

@Injectable()
export class EmperorSdkCustomElementsService {
  filteredElementsList: string[];
  constructor(@Optional() config: SDKCustomElementServiceConfig) {
    if (config) {
      this.filteredElementsList = config.elementsToNotOverride;
    } else {
      this.filteredElementsList = [];
    }
  }
}
