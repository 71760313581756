<label [id]="labelId" [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-full-width emperor-ui-masked-input">
  <span *ngIf="iconPrefix && iconPrefix?.showPrefix" matIconPrefix>
    <emperor-icon class="emperor-ui-mask-icon" [name]="iconPrefix.icon"></emperor-icon>
  </span>
  <input
    matInput
    [id]="id || ''"
    [imask]="mask"
    [unmask]="true"
    [formControl]="control"
    [type]="type"
    [attr.inputmode]="mode"
    [attr.aria-labelledby]="emperorInputAriaLabeledBy"
    [attr.data-test]="qaAttributeHook"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [placeholder]="placeholder ?? ''"
  />
  <div class="emperor-ui-mask-suffix" *ngIf="suffix$ | async as suffix" matTextSuffix>
    {{ suffix }}
  </div>
  <mat-error emperorErrorHandler [errorText]="errorText" [errorMessageMap]="errorMessageMap" [id]="code || ''"></mat-error>
  <mat-hint class="emperor-ui-warning" *ngIf="warning$ | async as warning" data-testid="warning-hint">
    {{ warning }}
  </mat-hint>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
</mat-form-field>
