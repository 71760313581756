import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmperorDialogRefService } from '@boldpenguin/emperor-presentational';
import { IBusinessClassificationSelectorNaicsGroupHeaderComponent } from '@boldpenguin/sdk';
import { BcsNaicsHeaderDialogComponent } from './bcs-naics-header-dialog/bcs-naics-header-dialog.component';

@Component({
  selector: 'emperor-bp-sdk-business-classification-selector-naics-group-header',
  templateUrl: './bp-sdk-business-classification-selector-naics-group-header.component.html',
  styleUrls: ['./bp-sdk-business-classification-selector-naics-group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkBusinessClassificationSelectorNaicsGroupHeaderComponent
  implements IBusinessClassificationSelectorNaicsGroupHeaderComponent
{
  /**
   * NAICS code
   */
  @Input() naicsCode: string;
  /**
   * NAICS industry description
   */
  @Input() naicsDescription: string;
  /**
   * NAICS explanation for popover
   */
  @Input() naicsExplanation: string;

  constructor(private emperorDialogService: EmperorDialogRefService) {}

  showDialog() {
    this.emperorDialogService.open(BcsNaicsHeaderDialogComponent, {
      data: {
        naicsDescription: this.naicsDescription,
        naicsExplanation: this.naicsExplanation,
      },
    });
  }
}
