<emperor-button
  (click)="moveBackward()"
  [uiButtonType]="EmperorButtonType.Link"
  [disabled]="isPreviousDisabled"
  ariaLabel="Previous item"
>
  <emperor-icon name="arrow_back" [class.disabled]="isPreviousDisabled"></emperor-icon>
</emperor-button>
<div class="meatball-container">
  <button
    *ngFor="let meatball of navigationMeatballs; let i = index"
    class="meatball"
    [class.selected]="isCurrentItem(i)"
    (click)="selectItem(i)"
    [attr.aria-label]="getItemAriaLabel(i)"
    type="button"
  ></button>
</div>
<emperor-button
  (click)="moveForward()"
  [uiButtonType]="EmperorButtonType.Link"
  [disabled]="isNextDisabled"
  ariaLabel="Next item"
>
  <emperor-icon name="arrow_forward" [class.disabled]="isNextDisabled"></emperor-icon>
</emperor-button>
