
export enum EligibleCarrierStatus {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Preferred = 'preferred',
  Warning = 'warning',
  NotOptedIn = 'not-opted-in',
  Incomplete = 'incomplete',
  OffPlatform = 'off-platform',
}

export enum EligibleCarrierStatusDefaultMessages {
  InProgress = 'In progress...',
  NotStarted = 'Not started',
  OutOfAppetite = 'Out of appetite',
  Quoted = 'Quoted',
  Completed = 'Completed',
  Error = 'Error',
  Preferred = 'Preferred carrier',
}

export const OfflineCarrierToPortalUrlMap = {
  attuneoffplatform: 'https://app.attuneinsurance.com/login',
  biberk: 'https://www.biberk.com/partners/',
  cna: 'https://www.cnacentral.com/',
  cnaoffplatform: 'https://www.cnacentral.com/',
  kemperoffplatform: 'http://agentportal.kemper.com/',
  pieoffplatform: 'https://partner.pieinsurance.com/sign-in/',
  threeoffplatform: 'https://threeinsurance.com/bold-penguin-quote-wizard',
};

export const CarrierProductStatus: {
  [key: string]: IEligibleCarrierStatusInfo;
} = {
  [EligibleCarrierStatus.Preferred]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.Preferred,
    status: EligibleCarrierStatus.Preferred,
    icon: 'star_circle',
  },
  [EligibleCarrierStatus.Success]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.Quoted,
    status: EligibleCarrierStatus.Success,
    icon: 'check_circle_2_2',
  },
  [EligibleCarrierStatus.Warning]: {
    status: EligibleCarrierStatus.Warning,
    icon: 'interface_alert_circle_1',
  },
  [EligibleCarrierStatus.Info]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.InProgress,
    status: EligibleCarrierStatus.Info,
    icon: 'clock',
  },
  [EligibleCarrierStatus.OffPlatform]: {
    status: EligibleCarrierStatus.OffPlatform,
    icon: 'login_2',
  },
};

export interface IEligibleCarrierStatusInfo {
  statusMessage?: EligibleCarrierStatusDefaultMessages | string;
  status: EligibleCarrierStatus;
  icon?: string;
  iconType?: string;
}
