import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  IAnswer,
  IApplicationFormQuestionSet,
  IDynamicQuestionGroup,
  IDynamicQuestionPoolMap,
  IMappedQuestionGroupAnswers,
  IQuestionGroup,
  IQuestionSetComponent,
  QuestionFieldType,
  QuestionGroupType,
  QuestionTag,
  answersForAQuestionGroupWithAnsAsKey,
  buildClassList,
  compareBoolean,
  getParentIds,
  isFeatureEnabled,
  sortByQuestionOrder,
  upsertQuestionPoolGroups,
} from '@boldpenguin/sdk';

import { SdkStoreService } from '@boldpenguin/emperor-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { IEmperorQuestionSetDivision } from '../../models';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-question-set',
  templateUrl: './bp-sdk-question-set.component.html',
  styleUrls: ['./bp-sdk-question-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkQuestionSetComponent implements IQuestionSetComponent, OnInit, OnChanges {
  @Input() saveHandler: (questionSet: IApplicationFormQuestionSet) => void;
  @Input() questionSet: IApplicationFormQuestionSet;
  @Input() areResourcesReady: boolean;
  @Input() locked: boolean;
  @Input() canSave: boolean;
  @Input() shouldRenderQuestionGroup: (mappedQuestionGroups: Map<string, IMappedQuestionGroupAnswers>, answer: IAnswer) => boolean;
  @Input() questionGroupsWithAnswersAsKey: Map<string, IMappedQuestionGroupAnswers>;

  questions: IAnswer[] | IDynamicQuestionGroup[];
  questionGroups: IDynamicQuestionPoolMap;
  parentIds: string[] = [];

  questionSetDivisions$ = new BehaviorSubject<IEmperorQuestionSetDivision[]>([]);
  featureEnabled$ = new BehaviorSubject<boolean>(false);

  constructor(private store: SdkStoreService) {}

  @HostListener('document:saveQuestionSet')
  onSaveQuestionSet() {
    this.saveHandler(this.questionSet);
  }

  ngOnInit(): void {
    this.store.state$
      .pipe(
        map(state => isFeatureEnabled(state, 'question_set_divisions')),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(enabled => this.featureEnabled$.next(enabled));
    this.onQuestionSetChange(this.questionSet);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionSet && !changes.questionSet.firstChange) {
      this.onQuestionSetChange(changes.questionSet.currentValue);
    }
  }

  preventRefresh = (e: Event) => {
    e.preventDefault();
    return false;
  };

  inQuestionGroup(answer: IAnswer): boolean {
    return this.questionSet.question_set.question_groups.some((qg: IQuestionGroup): boolean => {
      return qg.question_ids.some((id: string) => id === answer.question.id);
    });
  }

  identifyAnswerId(_index, answer: IAnswer) {
    return answer.id;
  }

  identifyDivisionIndex(index) {
    return index;
  }

  setClass(): string {
    return buildClassList('bp-sdk-question-set bp-sdk-question-set__input', {
      'bp-sdk-coverage-width': this.questionSet?.order === 1,
    });
  }

  getQuestionGroup(answer) {
    return answersForAQuestionGroupWithAnsAsKey(answer, this.questionGroupsWithAnswersAsKey);
  }

  private onQuestionSetChange(questionSet: IApplicationFormQuestionSet) {
    this.questionGroups = upsertQuestionPoolGroups(questionSet, this.questionGroups);
    this.parentIds = getParentIds(this.questionGroups);
    this.questionSetDivisions$.next(this.sortDivisions([...(questionSet?.answers ?? [])].sort(sortByQuestionOrder)));
  }

  private sortDivisions(answers: IAnswer[]): IEmperorQuestionSetDivision[] {
    if (!this.featureEnabled$.value) {
      return [{ answers: this.sortAnswers(answers), id: '', addPadding: true }];
    }
    const divisionMap: { [id: string]: { answerIds: string[]; isCoverage: boolean } } = {};
    this.questionGroupsWithAnswersAsKey.forEach(group => {
      const targetedTypes: string[] = [
        QuestionGroupType.Accordion,
        QuestionGroupType.Vehicle,
        QuestionGroupType.Driver,
        QuestionGroupType.AutoCollapsedAccordion,
      ];
      if (targetedTypes.includes(group.group_type) && group.poolId) {
        divisionMap[group.parentQuestionAnswer.id] = { answerIds: this.getAnswerKeysOnMap(group), isCoverage: false };
        divisionMap[group.parentQuestionAnswer.id].answerIds.push(group.parentQuestionAnswer.id);
      } else if (group.group_type === QuestionGroupType.Coverage) {
        divisionMap[group.parentQuestionAnswer.id] = { answerIds: this.getAnswerKeysOnMap(group), isCoverage: true };
        divisionMap[group.parentQuestionAnswer.id].answerIds.push(group.parentQuestionAnswer.id);
      }
    });
    if (Object.values(divisionMap).length === 0) {
      return [{ answers, id: '', addPadding: true }];
    } else {
      return answers
        .reduce((divisions: IEmperorQuestionSetDivision[], answer) => {
          for (const division in divisionMap) {
            if (divisionMap[division].answerIds.includes(answer.id)) {
              return (divisions = this.addToDivision(divisions, division, answer, divisionMap[division].isCoverage, false));
            }
          }
          if (answer.question.field_type === QuestionFieldType.Anchor && this.renderAnchorQuestion(answer)) {
            divisions = this.addToDivision(divisions, answer.id, answer, false, true);
          } else {
            divisions = this.addToDivision(divisions, '', answer, false, true);
          }
          return divisions;
        }, [])
        .filter(d => !d.answers.every(a => a.question.field_type === QuestionFieldType.Anchor && !this.renderAnchorQuestion(a)))
        .sort((a, b) => {
          if (b.isCoverage) {
            return -1;
          }
          return (
            answers.find(answer => a.id === answer.id)?.question.order ??
            a.answers[0].question.order - (answers.find(answer => b.id === answer.id)?.question.order ?? b.answers[0].question.order)
          );
        });
    }
  }

  private sortAnswers(answers: IAnswer[]): IAnswer[] {
    return [...answers].sort((answer1, answer2) => compareBoolean(this.isCoverageAnswer(answer2), this.isCoverageAnswer(answer1)));
  }

  private isCoverageAnswer(answer: IAnswer): boolean {
    return answer.question.field_type === QuestionFieldType.Anchor && answer.question.tags.includes(QuestionTag.CoverageLimit);
  }

  private getAnswerKeysOnMap(mappedQuestionGroup: IMappedQuestionGroupAnswers): string[] {
    const answerIds = mappedQuestionGroup.answers.map(a => a.id);
    if (mappedQuestionGroup.nestedGroupsWithAnswers.size > 0) {
      mappedQuestionGroup.nestedGroupsWithAnswers.forEach(group => answerIds.push(...this.getAnswerKeysOnMap(group)));
    }
    return answerIds;
  }

  private addToDivision(
    divisions: IEmperorQuestionSetDivision[],
    key: string,
    answer: IAnswer,
    isCoverage: boolean,
    addPadding: boolean,
  ): IEmperorQuestionSetDivision[] {
    const index = divisions.findIndex(d => d.id === key);
    if (index > -1) {
      divisions[index].answers.push(answer);
    } else {
      divisions.push({ answers: [answer], id: key, isCoverage, addPadding });
    }
    return divisions;
  }

  private renderAnchorQuestion(answer): boolean {
    return !!this.questionSet.question_set.question_pools.find(p => p.parent_question_id === answer.question.id);
  }
}
