import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IIconPrefix } from '@boldpenguin/emperor-form-fields';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { IBusinessClassificationSelectorComponent, IChoice, QuestionFieldType } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SdkWrapperModuleConfigService } from '../../service/sdk-wrapper-module-config.service';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-business-classification-selector',
  templateUrl: './bp-sdk-business-classification-selector.component.html',
  styleUrls: ['./bp-sdk-business-classification-selector.component.scss'],
})
export class BpSdkBusinessClassificationSelectorComponent
  extends BpSdkBaseComponentComponent
  implements OnInit, OnChanges, IBusinessClassificationSelectorComponent
{
  /**
   * Answer id
   */
  @Input() answerId: string;
  /**
   * Question id
   */
  @Input() questionId: string;
  /**
   * Question code
   */
  @Input() code: string;
  /***
   * Selected choice id
   */
  @Input() choiceId: string;
  /***
   * Choices to display
   */
  @Input() choices: IChoice[] = [];
  /**
   * Field type
   */
  @Input() fieldType: QuestionFieldType;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Placeholder to display
   */
  @Input() placeholder = '';
  /**
   * Is required
   */
  @Input() required = false;
  /**
   * Error messages
   */
  @Input() errors: string[];
  /**
   * Error text
   */
  @Input() errorText: string | null;
  /**
   * Aria label id's that describes input for screen readers
   */
  @Input() labelId = '';
  /**
   * Aria label for groups
   */
  @Input() labelledBy = '';
  /**
   * Show choices
   */
  @Input() showChoices = false;
  /**
   * Is touched
   */
  @Input() touched = false;
  /**
   * Is pristine
   */
  @Input() pristine = true;
  /**
   * Is answer in complete state
   */
  @Input() isCompleted = false;
  /**
   * Label to use
   */
  @Input() label: string;
  /**
   * Should show Naics code
   */
  @Input() showNaicsCode: boolean;
  /**
   * Should show confidence
   */
  @Input() showConfidence: boolean;
  /**
   * Selected choices array
   */
  @Input() selectedChoices: IChoice[];
  @Input() isReadOnly: boolean;

  @Input() isRefilteringEnabled;

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly iconPrefix: IIconPrefix = {
    showPrefix: true,
    icon: 'search_1',
  };
  formControl = new FormControl('');
  selectedFilter$: BehaviorSubject<IChoice | undefined> = new BehaviorSubject<IChoice | undefined>(undefined);
  hasBusinessClassFilter = false;
  loadingBcsChoices = true;
  hasAnyChoicesToDisplay$ = new BehaviorSubject<boolean>(false);
  protected searchDelayTime = 250;

  constructor(protected elRef: ElementRef, @Optional() sdkWrapperModuleConfigService: SdkWrapperModuleConfigService) {
    super(elRef, sdkWrapperModuleConfigService);
  }

  ngOnInit() {
    super.ngOnInit();

    // Watches the input for changes
    this.formControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => this.clearNoMatchScreen()),
        debounceTime(this.searchDelayTime),
        untilDestroyed(this),
      )
      .subscribe((filter: string) => this.updateListOfNaics(filter));

    // If we have a choiceId at this point, we want to go into the mode where we display the choices.
    // This is primarily for when we are switch between question sets without reloading the page
    if (this.choiceId) {
      const choice = this.getValueForChoiceId(this.choiceId);
      this.formControl.setValue(choice);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    // Check if the choices have changed, including the order
    const choicesChanged = this.didChoicesChange(changes);
    const isInitialLoad = this.isFirstTimeLoading(changes);

    // If they did change, or if the change was from [] -> [], indicate we are done loading
    // The second case ([] -> []) is necessary for if someone searches and gets no results initially
    // The first time results are loading mimics the second case ([] -> []).
    //   - To prevent showing the no results section early, check if showChoices is being set to true
    if ((choicesChanged || this.choicesChangeWasEmpty(changes)) && !isInitialLoad) {
      this.loadingBcsChoices = false;
    }

    // In this case, we need to do some slightly weird logic to determine if
    // we should be displaying the list of choices. In some cases, the SDK
    // will give us back a choice representing the selected item, but
    // it won't have all the full information (i.e. description) that we want
    // to display. So if there is only one item in the list, it's id matches
    // our currently selected id, and there's no description on the choice, we
    // treat it like the list is empty
    if (changes.choices || changes.choiceId) {
      const mostRecentChoices = changes.choices?.currentValue ?? this.choices;
      const mostRecentChoiceId = changes.choiceId?.currentValue ?? this.choiceId;

      const hasAnyChoicesToDisplay =
        mostRecentChoices.length > 0 &&
        !(mostRecentChoices.length === 1 && mostRecentChoices[0].id === mostRecentChoiceId && !mostRecentChoices[0].explanation);
      this.hasAnyChoicesToDisplay$.next(hasAnyChoicesToDisplay);
    }
  }

  @HostListener('valueUpdate', ['$event'])
  onUpdateValue(event: CustomEvent): void {
    // The child component emits an array of values, but this component is single select only
    const choiceId = (event.detail ?? [])[0];
    if (choiceId) {
      const choice = this.getValueForChoiceId(choiceId);
      this.formControl.setValue(choice, { emitEvent: false });
    }
  }

  protected updateListOfNaics(search: string | null): void {
    this.hasBusinessClassFilter = !!search && search.length > 0;

    if (this.hasBusinessClassFilter) {
      this.loadingBcsChoices = true;

      this.elRef.nativeElement.dispatchEvent(
        new CustomEvent('filterUpdate', {
          detail: {
            value: search,
            id: this.questionId,
          },
          bubbles: true,
        }),
      );
    }
  }

  private clearNoMatchScreen(): void {
    if (this.choices.length === 0) {
      this.hasBusinessClassFilter = false;
    }
  }

  clearSearch() {
    this.formControl.reset();
    this.hasBusinessClassFilter = false;
    this.loadingBcsChoices = false;
  }

  // Given a choice id, get the value by going through the choices
  private getValueForChoiceId(choiceId: string): string {
    const combinedChoices = [...(this.choices ?? []), ...(this.selectedChoices ?? [])];

    return combinedChoices?.find(c => c.id === choiceId)?.value ?? '';
  }

  // Check if the choice change was from [] => []
  private choicesChangeWasEmpty(changes: SimpleChanges) {
    return (
      !!changes.choices &&
      changes.choices.currentValue &&
      changes.choices.previousValue &&
      changes.choices.currentValue.length === 0 &&
      changes.choices.previousValue.length === 0
    );
  }

  private didChoicesChange(changes: SimpleChanges) {
    return !!changes.choices && (changes.choices.firstChange || !this.isEqual(changes.choices.currentValue, changes.choices.previousValue));
  }

  private isFirstTimeLoading(changes: SimpleChanges): boolean {
    if (changes.showChoices) {
      return changes.showChoices.previousValue === undefined && changes.showChoices.currentValue;
    }

    return false;
  }

  isEqual(a1: IChoice[], a2: IChoice[]) {
    const objectsEqual = (o1, o2) => {
      if (!o1 || !o2) {
        return false;
      }
      return typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
    };
    return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
  }
}
