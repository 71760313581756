<div class="emperor-slim-bcs">
  <emperor-text-input
    [formControl]="formControl"
    [iconPrefix]="iconPrefix"
    [id]="answerId"
    [code]="code"
    label="What kind of work do you do?"
    hint="Examples: Handyman, Landscaping, Electrical Contractor, Courier or enter an industry group"
    [hintWithIcon]="hintWithIcon"
    (focus)="focusInput()"
  >
    <emperor-icon
      suffix
      class="emperor-ui-clear-icon"
      [class.disabled]="!hasBusinessClassFilter"
      name="remove_circle_2"
      (click)="clearSearch()"
      (keydown.enter)="clearSearch()"
      role="button"
      aria-label="Clear search"
      tabindex="0"
    ></emperor-icon>
  </emperor-text-input>
  <ng-container *ngIf="hasBusinessClassFilter; else hasntSearchedForNaic">
    <div class="naics-click-overlay" (click)="clearPopup()"></div>
    <div class="emperor-ui-search-results">
      <ng-container *ngIf="!loadingBcsChoices; else loading">
        <bp-sdk-business-classification-selector-list-container
          *ngIf="hasAnyChoicesToDisplay$ | async; else noResults"
          [choices]="choices"
          [choiceId]="choiceId"
          [showConfidence]="showConfidence"
          [showNaicsCode]="showNaicsCode"
        ></bp-sdk-business-classification-selector-list-container>
      </ng-container>
    </div>
  </ng-container>
  <!-- Empty Naics state -->
  <ng-template #hasntSearchedForNaic>
    <div></div>
  </ng-template>

  <!-- No Results Available -->
  <ng-template #noResults>
    <div class="emperor-ui-no-results">
      No search results found for "{{ formControl.value }}" (<emperor-button
        class="emperor-ui-clear-search"
        [uiButtonType]="EmperorUIButtonTypes.Text"
        (click)="clearSearch()"
        >CLEAR SEARCH</emperor-button
      >)
    </div>
  </ng-template>

  <!-- Loading State -->
  <ng-template #loading>
    <div class="emperor-ui-loading-results">
      <emperor-spinner></emperor-spinner>
    </div>
  </ng-template>
</div>
