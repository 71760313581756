<fieldset>
  <legend *ngIf="!!label" [innerHTML]="label" class="emperor-ui-form-field-label"></legend>
  <div class="emperor-ui-boolean-choices-container">
    <ng-container *ngFor="let choice of choices; trackBy: identifyRadioId">
      <div class="emperor-ui-boolean-choice" [ngClass]="{ 'emperor-ui-checked': control.value === choice.value, disabled: isDisabled }">
        <label [for]="choice.id">{{ choice.label }}</label>
        <input
          (click)="onRadioClick($event, choice.value)"
          type="radio"
          [id]="choice.id"
          [formControl]="control"
          [attr.name]="id"
          [value]="choice.value"
          [checked]="control.value === choice.value"
          [attr.data-test]="qaAttributeHook"
          class="cdk-visually-hidden"
        /></div
    ></ng-container>
  </div>
  <div class="emperor-ui-subscript-wrapper">
    <ng-container *ngIf="showErrorState()">
      <mat-error>{{ showErrorMessages() }}</mat-error>
    </ng-container>
    <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
    <mat-hint *ngIf="!!hintWithIcon">
      <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
    </mat-hint>
  </div>
</fieldset>
