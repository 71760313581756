<emperor-card-header [color]="EmperorCardHeaderColor.NONE">
  <div class="header-container">
    <emperor-icon
      class="close-icon"
      emperorDialogClose
      name="close_1"
      role="button"
      tabindex="0"
      aria-label="Close coverage customization dialog"
    ></emperor-icon>
  </div>
</emperor-card-header>
<emperor-card-content class="card-content" [scrollable]="true" [innerHTML]="content"></emperor-card-content>
<emperor-card-footer  [color]="EmperorCardHeaderColor.NONE">
  <emperor-button [uiButtonType]="EmperorUIButtonTypes.Text" (click)="onCloseDialog()">CLOSE</emperor-button>
</emperor-card-footer>
