import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';
import { BehaviorSubject } from 'rxjs';
import { cleanDateString } from '@boldpenguin/sdk';
import { IHintWithIcon } from '@boldpenguin/emperor-form-fields';
import { startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from '../bp-sdk-date-input/bp-sdk-date-input.component';
import { lightFormat } from 'date-fns';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-month-year-input',
  templateUrl: './bp-sdk-month-year-input.component.html',
  styleUrls: ['./bp-sdk-month-year-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BpSdkMonthYearInputComponent extends BpSdkBaseComponentComponent implements OnChanges, OnInit {
  /**
   * Help text
   */
  @Input() helpText: string | null;
  /**
   * Is sensitive flag
   */
  @Input() isSensitive: boolean;

  protected _maxDate$ = new BehaviorSubject<string | null>(null);
  maxDate$ = this._maxDate$.asObservable();
  /**
   * maximum date value
   */
  @Input() set maxDate(max: string | null) {
    this._maxDate$.next(max != null ? cleanDateString(max) : null);
  }

  protected _minDate$ = new BehaviorSubject<string | null>(null);
  readonly minDate$ = this._minDate$.asObservable();
  /**
   * minimum date value
   */
  @Input() set minDate(min: string | null) {
    this._minDate$.next(min != null ? cleanDateString(min) : null);
  }
  /**
   * Placeholder to display
   */
  @Input() placeholder = 'MM/YYYY';
  /**
   * Question text
   */
  @Input() text: string | null;
  /**
   * question value
   */
  @Input() value: string | null;
  /**
   * An object to map custom error messages to certain types of errors
   */
  @Input() errorMessageMap: { [index: string]: string } | null;

  /**
   * Text that is displayed below the input box with an icon.
   */
  @Input() hintWithIcon: IHintWithIcon | undefined;

  ngOnInit(): void {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(startWith(this.getNewFormControlValue(this.value)), untilDestroyed(this)).subscribe((value: string | Date) => {
      // ignore falsy values, masked dates, and invalid dates
      if (!value || (value instanceof Date && isNaN(value as unknown as number))) {
        // Ignore if this is a blur on sensitive completed dates
        if (this.isSensitive && this.isCompleted && this.formControl.pristine) {
          return;
        }

        this.elementRef.nativeElement.dispatchEvent(
          new CustomEvent('valueUpdate', {
            detail: '',
            bubbles: true,
          }),
        );
        return;
      }

      if (this.isMaskedDateString(value.toString())) {
        return;
      }

      this.elementRef.nativeElement.dispatchEvent(
        new CustomEvent('valueUpdate', {
          detail: this.convertDateToString(value) ?? '',
          bubbles: true,
        }),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).includes('value')) {
      this.handleValueChanges(changes.value);
    }

    if (changes.pattern && !changes.pattern.firstChange) {
      this.setupValidators();
    }
  }

  setupValidators() {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      if (!this.isSensitive) {
        validators.push(Validators.required);
      } else {
        validators.push(CustomValidators.sensitiveRequired(this.isCompleted));
      }
    }
    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  private handleValueChanges(valueChange: SimpleChange): void {
    if (this.isMaskedDateString(valueChange.currentValue)) {
      return;
    }

    if (!this.valuesEqual(this.formControl.value, valueChange.currentValue)) {
      this.formControl.setValue(this.getNewFormControlValue(valueChange.currentValue));
    }
  }

  private isMaskedDateString(date: string | null): boolean {
    // The initial masked date value will be a string equal to: "**/****"
    return !!date && date.includes('*');
  }

  private getNewFormControlValue(value: Date | string | null | undefined): Date | null {
    if (value == null) return null;
    if(typeof value === 'string') return this.convertToDate(value);

    return value;
  }

  private convertDateToString(value: Date | string | null): string | null {
    if (!value) return null;
    if (typeof value === 'string'){
      if(value.length === 7) return value;

      value = new Date(value);
    }
    if(isNaN(value as unknown as number)) return null;

    return lightFormat(value, 'MM/yyyy')
  }

  private convertToDate(value: string | undefined): Date | null {
     if(value == null) return null;
     const monthNumber = value.substring(0,2);
     const year = value.substring(3)
     return new Date(`${year}-${monthNumber}-01T00:00:00.0`)
  }

  private valuesEqual(value1: Date | string | null, value2: Date | string | null) {
    return this.convertDateToString(value1) === this.convertDateToString(value2);
  }
}
