import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { createCustomElement, NgElementConfig } from '@angular/elements';
import { BpSdkAccordionComponent } from './components/bp-sdk-accordion/bp-sdk-accordion.component';
import { BpSdkAddItemComponent } from './components/bp-sdk-add-item/bp-sdk-add-item.component';
import { BpSdkAutocompleteInputComponent } from './components/bp-sdk-autocomplete-input/bp-sdk-autocomplete-input.component';
import { BpSdkBackButtonComponent } from './components/bp-sdk-back-button/bp-sdk-back-button.component';
import { BpSdkBusinessClassificationSelectorNaicsGroupHeaderComponent } from './components/bp-sdk-business-classification-selector-naics-group-header/bp-sdk-business-classification-selector-naics-group-header.component';
import { BpSdkBusinessClassificationSelectorComponent } from './components/bp-sdk-business-classification-selector/bp-sdk-business-classification-selector.component';
import { BpSdkCoverageGroupComponent } from './components/bp-sdk-coverage-group/bp-sdk-coverage-group.component';
import { BpSdkCheckboxInputComponent } from './components/bp-sdk-checkbox-input/bp-sdk-checkbox-input.component';
import { BpSdkDateInputComponent } from './components/bp-sdk-date-input/bp-sdk-date-input.component';
import { BpSdkDriverGroupComponent } from './components/bp-sdk-driver-group/bp-sdk-driver-group.component';
import { BpSdkEmptyComponent } from './components/bp-sdk-empty/bp-sdk-empty.component';
import { BpSdkGoogleAutocompleteComponent } from './components/bp-sdk-google-autocomplete/bp-sdk-google-autocomplete.component';
import { BpSdkGoogleAddressValidationComponent } from './components/bp-sdk-google-address-validation/bp-sdk-google-address-validation.component';
import { BpSdkIncrementInputComponent } from './components/bp-sdk-increment-input/bp-sdk-increment-input.component';
import { BpSdkMaskedInputComponent } from './components/bp-sdk-masked-input/bp-sdk-masked-input.component';
import { BpSdkPendingProcessComponent } from './components/bp-sdk-pending-process/bp-sdk-pending-process.component';
import { BpSdkProductListComponent } from './components/bp-sdk-product-list/bp-sdk-product-list.component';
import { BpSdkQuestionSetHeaderComponent } from './components/bp-sdk-question-set-header/bp-sdk-question-set-header.component';
import { BpSdkQuestionSetNavigationComponent } from './components/bp-sdk-question-set-navigation/bp-sdk-question-set-navigation.component';
import { BpSdkQuestionSetNextButtonComponent } from './components/bp-sdk-question-set-next-button/bp-sdk-question-set-next-button.component';
import { BpSdkQuoteRequestComponent } from './components/bp-sdk-quote-request/bp-sdk-quote-request.component';
import { BpSdkRadioInputComponent } from './components/bp-sdk-radio-input/bp-sdk-radio-input.component';
import { BpSdkSelectInputComponent } from './components/bp-sdk-select-input/bp-sdk-select-input.component';
import { BpSdkTextInputComponent } from './components/bp-sdk-text-input/bp-sdk-text-input.component';
import { BpSdkVehicleDeleteButtonComponent } from './components/bp-sdk-vehicle-delete-button/bp-sdk-vehicle-delete-button.component';
import { BpSdkVehicleEditButtonComponent } from './components/bp-sdk-vehicle-edit-button/bp-sdk-vehicle-edit-button.component';
import { BpSdkVehiclePanelComponent } from './components/bp-sdk-vehicle-panel/bp-sdk-vehicle-panel.component';
import { BpSdkVehicleSaveButtonComponent } from './components/bp-sdk-vehicle-save-button/bp-sdk-vehicle-save-button.component';
import { BpSdkCreateAppFormButtonComponent } from './components/bp-sdk-create-app-form-button/bp-sdk-create-app-form-button.component';
import { EmperorSdkCustomElementsService, SDKCustomElementServiceConfig } from './service/emperor-sdk-custom-elements.service';
import { BpSdkQuestionPoolDeleteButtonComponent } from './components/bp-sdk-question-pool-delete-button/bp-sdk-question-pool-delete-button.component';
import { BpSdkParentQuestionPoolSelectorComponent } from './components/bp-sdk-parent-question-pool-selector/bp-sdk-parent-question-pool-selector.component';
import { BpSdkMessageOutletComponent } from './components/bp-sdk-message-outlet/bp-sdk-message-outlet.component';
import { BpSdkMonthYearInputComponent } from './components/bp-sdk-month-year-input/bp-sdk-month-year-input.component';

export interface CustomSDKElement {
  name: string;
  elmConstructor: CustomElementConstructor;
}

// Allow these to be "any" since there a lot of different component types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sdkComponentTagToReplacementComponent = new Map<string, any>([
  ['bp-sdk-external-text-input', BpSdkTextInputComponent],
  ['bp-sdk-external-add-item-container', BpSdkAddItemComponent],
  ['bp-sdk-external-select-input', BpSdkSelectInputComponent],
  ['bp-sdk-external-masked-input', BpSdkMaskedInputComponent],
  ['bp-sdk-external-radio-input', BpSdkRadioInputComponent],
  ['bp-sdk-external-date-input', BpSdkDateInputComponent],
  ['bp-sdk-external-question-set-header', BpSdkQuestionSetHeaderComponent],
  ['bp-sdk-external-business-classification-selector', BpSdkBusinessClassificationSelectorComponent],
  ['bp-sdk-external-business-classification-selector-naics-group-header', BpSdkBusinessClassificationSelectorNaicsGroupHeaderComponent],
  ['bp-sdk-external-question-set-navigation', BpSdkQuestionSetNavigationComponent],
  ['bp-sdk-external-back-button', BpSdkBackButtonComponent],
  ['bp-sdk-external-next-button', BpSdkQuestionSetNextButtonComponent],
  ['bp-sdk-external-product-list', BpSdkProductListComponent],
  ['bp-sdk-external-checkbox-input', BpSdkCheckboxInputComponent],
  ['bp-sdk-external-autocomplete-input', BpSdkAutocompleteInputComponent],
  ['bp-sdk-external-google-places-autocomplete-container', BpSdkGoogleAutocompleteComponent],
  ['bp-sdk-external-google-address-validation', BpSdkGoogleAddressValidationComponent],
  ['bp-sdk-external-increment-input', BpSdkIncrementInputComponent],
  ['bp-sdk-external-quote-request', BpSdkQuoteRequestComponent],
  ['bp-sdk-external-vehicle-delete-button', BpSdkVehicleDeleteButtonComponent],
  ['bp-sdk-external-vehicle-save-button', BpSdkVehicleSaveButtonComponent],
  ['bp-sdk-external-vehicle-panel', BpSdkVehiclePanelComponent],
  ['bp-sdk-external-vehicle-edit-button', BpSdkVehicleEditButtonComponent],
  ['bp-sdk-external-driver-group', BpSdkDriverGroupComponent],
  ['bp-sdk-external-accordion', BpSdkAccordionComponent],
  ['bp-sdk-external-coverage-group', BpSdkCoverageGroupComponent],
  ['bp-sdk-external-vehicle-question-set-button', BpSdkEmptyComponent],
  ['bp-sdk-external-driver-question-set-button', BpSdkEmptyComponent],
  ['bp-sdk-external-pending-process', BpSdkPendingProcessComponent],
  ['bp-sdk-external-create-app-form-button', BpSdkCreateAppFormButtonComponent],
  ['bp-sdk-external-delete-question-pool-item', BpSdkQuestionPoolDeleteButtonComponent],
  ['bp-sdk-external-parent-question-pool-selector', BpSdkParentQuestionPoolSelectorComponent],
  ['bp-sdk-external-message-outlet', BpSdkMessageOutletComponent],
  ['bp-sdk-external-month-year-input', BpSdkMonthYearInputComponent],
]);

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [EmperorSdkCustomElementsService],
})
export class EmperorSDKComponentReplacementModule {
  static forRoot(config: SDKCustomElementServiceConfig): ModuleWithProviders<EmperorSDKComponentReplacementModule> {
    return {
      ngModule: EmperorSDKComponentReplacementModule,
      providers: [{ provide: SDKCustomElementServiceConfig, useValue: config }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: EmperorSDKComponentReplacementModule, private injector: Injector) {
    if (parentModule) {
      throw Error('EmperorSDKComponentReplacementModule is already loaded. Add this to top level Module');
    }

    const service = this.injector.get(EmperorSdkCustomElementsService);
    const ngElementConfig = {
      injector: this.injector,
    } as NgElementConfig;

    sdkComponentTagToReplacementComponent.forEach((component, name) => {
      if (!service.filteredElementsList.includes(name)) {
        const element = createCustomElement(component, ngElementConfig);
        customElements.define(name, element);
      }
    });
  }
}
