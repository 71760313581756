import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { EmperorThemeColor, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { generateId } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-create-app-form-button',
  templateUrl: './bp-sdk-create-app-form-button.component.html',
  styleUrls: ['./bp-sdk-create-app-form-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BpSdkCreateAppFormButtonComponent {
  /**
   * Create app form button text
   */
  @Input() text = 'Create App';
  /**
   * Is button disabled
   */
  @Input() disabled: boolean;
  /**
   * button id
   */
  @Input()
  buttonId: string = generateId(8);

  color: EmperorThemeColor = EmperorThemeColor.PRIMARY;
  uiButtonType: EmperorUIButtonTypes = EmperorUIButtonTypes.Large;
  constructor(private elementRef: ElementRef) {}

  click() {
    this.elementRef.nativeElement.dispatchEvent(new CustomEvent('click', { bubbles: true }));
  }
}
