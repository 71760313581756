import { FormControl, ValidationErrors } from '@angular/forms';
import { repeatingNumerals, containsNumericSequence, startsWith1 } from './phone-regex';

export const phoneValidator = (ctrl: FormControl): ValidationErrors | null => {
  if (ctrl.value && (repeatingNumerals(ctrl.value) || containsNumericSequence(ctrl.value))) {
    return { phone: 'Invalid phone number' };
  }
  if (ctrl.value && startsWith1(ctrl.value)) {
    return { phoneStartsWith1: 'Area code cannot start with 1' };
  }
  return null;
};
