import { Component, Input } from '@angular/core';
import { EmperorThemeColor, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { IDeleteQuestionPoolItemComponent } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-question-pool-delete-button',
  templateUrl: './bp-sdk-question-pool-delete-button.component.html',
  styleUrls: ['./bp-sdk-question-pool-delete-button.component.scss'],
})
export class BpSdkQuestionPoolDeleteButtonComponent implements IDeleteQuestionPoolItemComponent {
  @Input() text: string;
  @Input() handleRemoveItem: () => void;

  emperorThemeColor = EmperorThemeColor;
  emperorButtonUITypes = EmperorUIButtonTypes;

  deletePool() {
    this.handleRemoveItem();
  }
}
