export enum ProductFeatureType {
  AppetiteSearch = 'agent_appetite_search',
  CoverageSelectorWithAppetite = 'coverage_selector_with_appetite',
  EnhancedRte = 'agent_terminal_enhanced_RTE',
  HybridAppetite = 'hybrid_appetite',
  LogrocketSessions = 'logrocket_sessions',
  BulkAddCarrierQuestionSets = 'bulk_add_carrier_question_sets',
  ImproveCarrierAppointments = 'improve_carrier_appointments',
  UseRecommendedCarriers = 'use_recommended_carriers',
  EmbeddedReportingExchangeDashboard = 'embedded_reporting_exchange_dashboard',
  CarrierDynamicLimits = 'carrier_dynamic_limits',
}
