import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';

function scrollFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const matSelectScrollStrategyProvider = {
  provide: MAT_SELECT_SCROLL_STRATEGY,
  useFactory: scrollFactory,
  deps: [Overlay],
};
export const matAutocompleteScrollStrategyProvider = {
  provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  useFactory: scrollFactory,
  deps: [Overlay],
};
