import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { SelectorWrapperService } from '@boldpenguin/emperor-services';
import { IQuotesState, IRealTimeEligibilityCarrier } from '@boldpenguin/sdk';
import { Observable, distinctUntilChanged, shareReplay } from 'rxjs';
import { ProductFeatureType } from '../../models';
import { RealTimeEligibilityService } from '../../services/real-time-eligibility.service';

@Component({
  selector: 'emperor-eligibility-panel',
  templateUrl: './eligibility-panel.component.html',
  styleUrls: ['./eligibility-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EligibilityPanelComponent implements OnInit {
  eligibleCarriers$: Observable<IRealTimeEligibilityCarrier[]>;
  ineligibleCarriers$: Observable<IRealTimeEligibilityCarrier[]>;
  bulkAddCarrierQuestionSetsEnabled$: Observable<boolean>;
  useRecommendedCarriersEnabled$: Observable<boolean>;

  @Output()
  goToQuote: EventEmitter<IQuotesState | undefined> = new EventEmitter();

  @Output()
  panelLoaded: EventEmitter<void> = new EventEmitter();

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;

  constructor(
    readonly realTimeEligibilityService: RealTimeEligibilityService,
    private readonly selectorWrapperService: SelectorWrapperService,
  ) {}

  ngOnInit(): void {
    //hook for mixpanel event
    this.panelLoaded.emit();

    this.bulkAddCarrierQuestionSetsEnabled$ = this.selectorWrapperService
      .isFeatureEnabled$(ProductFeatureType.BulkAddCarrierQuestionSets)
      .pipe(distinctUntilChanged());

    this.useRecommendedCarriersEnabled$ = this.selectorWrapperService
      .isFeatureEnabled$(ProductFeatureType.UseRecommendedCarriers)
      .pipe(distinctUntilChanged());

    this.eligibleCarriers$ = this.realTimeEligibilityService
      .getEligibleCarriers$()
      .pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));

    this.ineligibleCarriers$ = this.realTimeEligibilityService
      .getIneligibleCarriers$()
      .pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
  }

  onGoToQuote(quote?: IQuotesState): void {
    this.goToQuote.emit(quote);
  }

  trackByCarrierId(_index: number, carrier: IRealTimeEligibilityCarrier) {
    return carrier.id;
  }
}
