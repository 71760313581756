import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { map, Observable, of } from 'rxjs';
import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';

@Component({
  selector: 'emperor-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DateInputComponent extends InputFieldsetDisableDirective implements OnInit {
  @Input() icon: string;
  isMobileScreenSize$: Observable<boolean> = of(false);

  constructor(injector: Injector, sanitizer: DomSanitizer, elementRef: ElementRef, private breakpointObserver: BreakpointObserver) {
    super(injector, sanitizer, elementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isMobileScreenSize$ = this.breakpointObserver.observe('(max-width: 390px)').pipe(map(state => state.matches));
  }
}
