<emperor-autocomplete
  [allowFallthrough]="allowFallthrough"
  [choices]="(mappedChoices$ | async)!"
  [disableDropdown]="disableDropdown"
  [showNoChoicesFallback]="showNoChoicesFallback"
  [label]="label"
  [placeholder]="placeholder || ''"
  [formControl]="formControl"
  [ariaLabelledBy]="labelledBy"
  [errorText]="errorText!"
  [hint]="helpText"
  [hintWithIcon]="hintWithIcon"
  [id]="answerId"
  [code]="code"
  [iconPrefix]="iconPrefix"
  [isReadOnly]="isReadOnly"
  (optionSelected)="onOptionSelected($event)"
  (blur)="handleBlur()"
  (focus)="handleFocus()"
>
  <emperor-icon
    suffix
    class="emperor-ui-clear-icon"
    [class.disabled]="!formControl.value"
    name="remove_circle_2"
    (click)="clearSearch()"
    (keydown.enter)="clearSearch()"
    (keydown.space)="clearSearch()"
    aria-label="Clear search"
    role="button"
    tabindex="0"
  ></emperor-icon
></emperor-autocomplete>
