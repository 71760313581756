<emperor-carrier-product-eligibility-wrapper [interactive]="(isInteractive$ | async)!" (goToQuote)="handleProductClick()">
  <div *ngIf="quote?.supplier_name" class="emperor-ui-coverage-supplier">
    <div class="emperor-ui-supplier-logo">
      <figure>
        <img class="image" [src]="quote?.supplier_logo_url" />
      </figure>
    </div>
    <div class="emperor-ui-supplier-name" [ngClass]="{ 'emperor-ui-muted-text': carrier.displayDisabledState }">
      {{ quote?.supplier_name }}
    </div>
  </div>

    <div class="emperor-ui-coverage-title" [ngClass]="{ 'emperor-ui-muted-text': carrier.displayDisabledState }">
      {{ productName }}
      <emperor-icon
        *ngIf="showTooltip && !!quote"
        class="emperor-ui-coverage-tooltip"
        name="interface_information_1"
        [emperorTooltip]="isBundle ? (quote.products | formatProductBundle : false) : offPlatformTooltip"
      ></emperor-icon>
    </div>

    <div class="emperor-ui-coverage-details">
      <div class="emperor-ui-price-details">
        <ng-container *ngIf="showPrice$ | async">
          <div *ngIf="quote" class="emperor-ui-price">
            {{ quote | quotePrice | currency : 'USD' : 'symbol' : '0.2' }}
          </div>
          <div class="emperor-ui-price-label">
            {{ quote | quotePriceLabel }}
          </div>
        </ng-container>
      </div>

      <emperor-coverage-eligibility-icon
        *ngIf="(isCarrierQuoteFetching$ | async) === false"
        [status]="(quoteStatus$ | async)!"
      ></emperor-coverage-eligibility-icon>
      <emperor-spinner *ngIf="isCarrierQuoteFetching$ | async" emperorTooltip="submitting quote…" [diameter]="35"></emperor-spinner>
    </div>
</emperor-carrier-product-eligibility-wrapper>
