<label [id]="labelId" [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-full-width emperor-ui-autocomplete">
  <emperor-icon
    *ngIf="iconPrefix.showPrefix"
    matIconPrefix
    class="emperor-ui-autocomplete-prefix-icon"
    [name]="iconPrefix.icon"
  ></emperor-icon>
  <input
    matInput
    [matAutocomplete]="auto"
    type="text"
    [id]="id || ''"
    [formControl]="control"
    [attr.aria-label]="ariaLabel ? ariaLabel : sanitizedLabelText"
    [attr.aria-labelledby]="emperorInputAriaLabeledBy"
    [attr.data-test]="qaAttributeHook"
    (blur)="onAutocompleteBlur($event)"
    (focus)="onFocus()"
    [placeholder]="placeholder ?? ''"
  />
  <mat-autocomplete #auto (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of filteredChoices$ | async" [value]="option">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-error emperorErrorHandler [errorText]="errorText" [errorMessageMap]="errorMessageMap" [id]="code || ''"></mat-error>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
  <!-- TODO(mdc-migration): matSuffix is deprecated, so we may need to distinguish between matIconSuffix and matTextSuffix (RN) -->
  <span matSuffix>
    <ng-content select="[suffix]"></ng-content>
  </span>
</mat-form-field>
