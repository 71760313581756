<emperor-select
  [placeholder]="placeholder || ''"
  [choices]="(mappedChoices$ | async)!"
  [multi]="multiple"
  [formControl]="formControl"
  [ariaLabelledBy]="labelledBy"
  [label]="label"
  [id]="answerId"
  [errorText]="errorText"
  [hint]="helpText"
  [hintWithIcon]="hintWithIcon"
  [code]="code"
  [isReadOnly]="isReadOnly"
  (focus)="onFocus()"
  (blur)="onBlur()"
></emperor-select>
