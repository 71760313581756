<emperor-text-input
  class="emperor-text-input-spacing"
  [formControl]="searchControl"
  [placeholder]="question.placeholder"
  [iconPrefix]="{ showPrefix: true, icon: 'search' }"
>
  <emperor-icon
    suffix
    aria-label="Clear"
    class="emperor-ui-clear-icon"
    [class.disabled]="!searchControl.value"
    name="remove_circle_2"
    (click)="clearSearch()"
    (keydown.enter)="clearSearch()"
    (keydown.space)="clearSearch()"
    role="button"
    tabindex="0"
  >
  </emperor-icon>
</emperor-text-input>

<ng-container *ngIf="filteredChoices$ | async as filteredChoices; else noResults">
  <div *ngIf="filteredChoices.length > 0; else noResults" class="searchable-selection-list">
    <mat-selection-list *ngIf="shouldDisplaySelectAll(filteredChoices)" color="primary" [formControl]="selectAllControl">
      <mat-list-option [value]="true" checkboxPosition="before" disableRipple="true"> Select All </mat-list-option>
    </mat-selection-list>
    <mat-selection-list color="primary" (selectionChange)="selectionChange($event)">
      <mat-list-option
        *ngFor="let choice of filteredChoices; trackBy: trackByChoiceId"
        [value]="choice.value"
        checkboxPosition="before"
        disableRipple="true"
        [selected]="isSelected(choice.value)"
      >
        {{ choice.label }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</ng-container>
<ng-template #noResults>
  <mat-option disabled="true"> No results </mat-option>
</ng-template>
