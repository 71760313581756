<form (submit)="preventRefresh($event)">
  <fieldset class="bp-sdk-question-set-fieldset-wrapper" [disabled]="locked">
    <ng-container *ngFor="let division of questionSetDivisions$ | async; trackBy: identifyDivisionIndex">
      <div class="emperor-ui-application-container-division" [ngClass]="{ 'emperor-ui-pool-division': !division.addPadding }">
        <ng-container *ngFor="let answer of division.answers; trackBy: identifyAnswerId">
          <ng-container *ngIf="!inQuestionGroup(answer)">
            <div [className]="setClass()" [attr.data-test]="answer.id">
              <bp-sdk-dynamic-input-redux-container [answer]="answer"></bp-sdk-dynamic-input-redux-container>
            </div>
          </ng-container>
          <ng-container *ngIf="shouldRenderQuestionGroup(questionGroupsWithAnswersAsKey, answer)">
            <bp-sdk-question-group-container [questionGroup]="getQuestionGroup(answer)"></bp-sdk-question-group-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </fieldset>
</form>
