import { IHintWithIcon } from './../../models/hint-with-icon.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'emperor-hint-with-icon',
  templateUrl: './hint-with-icon.component.html',
  styleUrls: ['./hint-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintWithIconComponent {
  @Input() hintWithIcon: IHintWithIcon;
}
