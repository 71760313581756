// todo: add comment block, what's the authoritative source here?
export type TCoverageType =
  | 'BANDM'
  | 'BLDR'
  | 'Bond'
  | 'BOAT'
  | 'BOP'
  | 'CA'
  | 'CAVN'
  | 'CL'
  | 'DIC'
  | 'DOL'
  | 'CRIME'
  | 'EO'
  | 'EPLI'
  | 'FLOOD'
  | 'GARAG'
  | 'GL'
  | 'LL'
  | 'MARINE'
  | 'MPL'
  | 'OCP'
  | 'Other'
  | 'POLL'
  | 'PROP'
  | 'SE'
  | 'UL'
  | 'WC'
  | 'GL, PROP';

type CovMetadata = {
  // todo: what is the abbreviation source? what should the abbr be used for (or not used for)?
  /**
   * Short abbrevation (2-5 letters) of the coverage name
   */
  abbreviation: string;

  /**
   * Brief description (1-2 sentences) of the coverage type
   */
  description?: string;

  /**
   * Emperor icon name
   */
  icon: string;

  /**
   * Coverage choice value from the Master Question Set (MQS)
   */
  mqsCoverageType: string;

  /**
   * Human-friendly name
   */
  name: string;

  /**
   * Name used by Partner Engine for product keys
   */
  productKeyName?: string
};

/**
 * @private Exported for internal lib use only
 *
 * See for MQS coverage types: https://developers.boldpenguin.com/docs/api/mqs/
 */
export class CoveragesMetadata {
  private static metadata: Record<TCoverageType, CovMetadata> = {
    BANDM: {
      abbreviation: 'BANDM',
      description: undefined,
      icon: 'coverage_boiler_machinery',
      mqsCoverageType: 'Boiler & Machinery',
      name: 'Boiler & Machinery',
    },
    BLDR: {
      abbreviation: 'BLDR',
      description: undefined,
      icon: 'coverage_builders_risk',
      mqsCoverageType: "Builder's Risk",
      name: "Builder's Risk",
    },
    BOAT: {
      abbreviation: 'BOAT',
      description: undefined,
      icon: 'coverage_watercraft',
      mqsCoverageType: 'Watercraft',
      name: 'Watercraft',
    },
    Bond: {
      name: 'Bond',
      mqsCoverageType: 'Bond',
      abbreviation: 'BOND',
      description:
        'Bonds, also known as commercial or business bonds, are agreements that protect customers by ensuring that a contracted business will fulfill its obligations and are generally required by local, state or federal law (or certain clients) to get a license or permit to do work within certain industries.',
      icon: 'coverage_bond',
    },
    BOP: {
      name: 'Business Owners Policy',
      mqsCoverageType: 'BOP',
      abbreviation: 'BOP',
      description:
        'A Business Owner’s Policy (BOP) combines Commercial Property and General Liability (GL) insurance into one insurance policy. In addition to General Liability it includes coverage for claims resulting from fire, theft or other covered perils.',
      icon: 'coverage_business_owners_policy',
      productKeyName: "Business Owner's Policy"
    },
    CA: {
      name: 'Commercial Auto',
      mqsCoverageType: 'Commercial Auto',
      abbreviation: 'CA',
      description:
        'Commercial Auto (CA) coverage insures a business for liability damages caused by vehicles operated by its employees in the course of business, and physical damage caused to vehicles insured by the business owner.',
      icon: 'coverage_commercial_auto',
    },
    CAVN: {
      abbreviation: 'CAVN',
      description: undefined,
      icon: 'coverage_aviation',
      mqsCoverageType: 'Aviation',
      name: 'Aviation',
    },
    CL: {
      name: 'Cyber Liability',
      mqsCoverageType: 'Cyber Liability',
      abbreviation: 'CL',
      description:
        'Cyber Liability (CL) coverage insures businesses against damages caused by data breaches or other malicious cyber attacks against computer systems.',
      icon: 'coverage_cyber_liability',
    },
    CRIME: {
      name: 'Crime',
      mqsCoverageType: 'Crime',
      abbreviation: 'CRIME',
      description:
        'Crime insurance (CRIME) protects a business from criminal acts committed by its employees against the business or its clients such as employee theft, forgery, robbery and fraud.',
      icon: 'coverage_crime',
    },
    DIC: {
      abbreviation: 'DIC',
      description: undefined,
      icon: 'coverage_difference_in_conditions',
      mqsCoverageType: 'Difference in Conditions',
      name: 'Difference in Conditions',
    },
    DOL: {
      name: 'Directors & Officers Liability',
      mqsCoverageType: 'Directors & Officers Liability',
      abbreviation: 'D&O',
      description:
        'Directors and Officers Liability (D&O) coverage insures individuals from personal losses if they are personally sued as a result of serving as a director or officer of a business or nonprofit organization.',
      icon: 'coverage_directors_officers',
      productKeyName: 'Directors & Officers Liability',
    },
    EO: {
      name: 'Professional Liability',
      mqsCoverageType: 'Professional Liability (E&O)',
      abbreviation: 'PL',
      description:
        'Professional Liability (PL) coverage, also known as Errors and Omissions (E&O), protects businesses against claims related to misrepresentation, negligence, and errors in the business’s professional capacity.',
      icon: 'coverage_professional_liability',
    },
    EPLI: {
      name: 'Employment Practices Liability',
      mqsCoverageType: 'Employment Practices Liability',
      abbreviation: 'EPLI',
      description:
        'Employment Practice Liability (EPLI) insures a business from employee, applicant or third party allegations of discrimination, wrongful termination, harassment or other employment-related issues.',
      icon: 'coverage_employment_practice_liability',
    },
    FLOOD: {
      name: 'Flood Liability',
      mqsCoverageType: 'Commercial Flood',
      abbreviation: 'FLOOD',
      description:
        'Commercial Flood insurance provides coverage for buildings, the contents in a building, or both, for losses directly caused by flooding.',
      icon: 'coverage_commercial_flood',
    },
    GARAG: {
      abbreviation: 'GARAG',
      description: undefined,
      icon: 'coverage_garage_dealers',
      mqsCoverageType: 'Garage & Dealers',
      name: 'Garage & Dealer',
    },
    GL: {
      name: 'General Liability',
      mqsCoverageType: 'Liability',
      abbreviation: 'GL',
      description:
        'General Liability (GL) coverage insures businesses against claims for property damage or personal and advertising injury caused by your employees, services, or business operations.',
      icon: 'coverage_general_liability',
    },
    LL: {
      abbreviation: 'LL',
      description: undefined,
      icon: 'coverage_liquor_liability',
      mqsCoverageType: 'Liquor Liability',
      name: 'Liqour',
    },
    MARINE: {
      abbreviation: 'MARINE',
      description: undefined,
      icon: 'coverage_inland_marine',
      mqsCoverageType: 'Inland Marine',
      name: 'Inland Marine',
    },
    MPL: {
      name: 'Miscellaneous Professional Liability',
      mqsCoverageType: 'Miscellaneous Professional Liability',
      abbreviation: 'MPL',
      description:
        'Miscellaneous Professional Liability (MPL) coverage insures professional service providers and their employees from claims alleging wrongful acts, errors, or omissions in the course of their work that is generally not covered by a General Liability policy.',
      icon: 'miscellaneous_professional',
    },
    OCP: {
      abbreviation: 'OCP',
      description: undefined,
      icon: 'coverage_owners_contractors_protective_liability',
      mqsCoverageType: 'Owners & Contractors Protective Liability',
      name: 'Owners & Contractors Protective Liability',
    },
    Other: {
      name: 'Other',
      abbreviation: 'OTHER',
      icon: `coverage_other`,
      mqsCoverageType: '',
    },
    POLL: {
      abbreviation: 'POLL',
      description: undefined,
      icon: 'coverage_pollution_liability',
      mqsCoverageType: 'Pollution Liability',
      name: 'Pollution Liability',
    },
    PROP: {
      abbreviation: 'PROP',
      description: undefined,
      icon: 'coverage_commercial_property',
      mqsCoverageType: 'Commercial Property',
      name: 'Commercial Property',
    },
    SE: {
      abbreviation: 'SE',
      description: undefined,
      icon: 'coverage_special_events',
      mqsCoverageType: 'Special Events',
      name: 'Special Events',
    },
    UL: {
      name: 'Umbrella',
      mqsCoverageType: 'Umbrella',
      abbreviation: 'UMB',
      description:
        'Umbrella Insurance (UMB) provides excess liability coverage to help pay for eligible catastrophic business losses that exceed underlying policy liability limits.',
      icon: 'coverage_umbrella',
    },
    WC: {
      name: 'Workers Compensation',
      mqsCoverageType: 'Workers Compensation',
      abbreviation: 'WC',
      description:
        'Workers’ Compensation (WC) coverage provides benefits such as wage loss and disability to employees who get sick or injured from work-related incidents.',
      icon: 'coverage_workers_compensation',
    },
    'GL, PROP': {
      name: 'General Liability & Commercial Property',
      mqsCoverageType: 'BOP',
      abbreviation: 'GL, PROP',
      icon: 'coverage_business_owners_policy',
      productKeyName: 'General Liability & Commercial Property'
    }
  };

  private static coverageTypeAlternates: Record<TCoverageType, string[]> = {
    BANDM: ['Boiler & Machinery'],
    BLDR: ["Builder's Risk"],
    BOAT: ['Watercraft'],
    Bond: ['BOND', 'Surety Bond'],
    BOP: ['Business Owners Policy', "Business Owner's Policy"],
    CA: ['Business Auto', 'Commercial Auto'],
    CAVN: ['Aviation'],
    CL: ['Cyber Liability', 'Cyber'],
    CRIME: ['Crime'],
    DIC: ['Difference in Conditions'],
    DOL: ['Directors and Officers', 'Directors & Officers', 'Directors & Officers Liability', 'D&O'],
    EO: ['E&O', 'PL', 'Professional Liability', 'Professional Liability (E&O)', 'Errors & Omissions', 'Errors & Omissions Insurance'],
    EPLI: ['Employment Practice Liability', 'Employment Practices Liability'],
    FLOOD: ['CFI', 'Commercial Flood Insurance', 'Commercial Flood'],
    GARAG: ['Garage & Dealers'],
    GL: ['CGL', 'Liability', 'General Liability'],
    LL: ['Liquor Liability'],
    MARINE: ['Inland Marine'],
    MPL: ['Miscellaneous Professional Liability'],
    OCP: ['Owners & Contractors Protective Liability'],
    Other: ['OTHER', 'Other/Not Sure'],
    POLL: ['Pollution Liability'],
    PROP: ['CP', 'Commercial Property'],
    SE: ['Special Events'],
    UL: ['UMB', 'Umbrella'],
    WC: ['Workers Comp', 'Workers Compensation', "Worker's Compensation Insurance"],
    'GL, PROP': ['GL + PROP', 'General Liability + Commercial Property', 'General Liability & Commercial Property', 'Commercial Property + General Liability', 'Commercial Property & General Liability'],
  };
  private static coverageTypeAlternatesIterable = Object.entries(this.coverageTypeAlternates) as [TCoverageType, string[]][];

  /**
   * Get metadata about a product searching known coverage types and their alternate names and abbreviations.
   *
   * @param product short abbreviation, human-friendly name, or an alternate abbreviation or name
   * @returns CovMetadata if found
   */
  public static getMetadata(product: string): CovMetadata | undefined {
    const coverageType: TCoverageType | undefined = this.getMetadataKey(product);
    if (coverageType) {
      return this.metadata[coverageType];
    }
  }

  /**
   * Get coverage type key for a product searching known coverage types and their alternate names and abbreviations.
   *
   * @param product short abbreviation, human-friendly name, or an alternate abbreviation or name
   * @returns CovMetadata if found
   */
  public static getMetadataKey(product: string): TCoverageType | undefined {
    if (!product) {
      return;
    }
    return this.coverageTypeAlternates[product]
      ? (product as TCoverageType)
      : this.coverageTypeAlternatesIterable.find(([, alternates]) => alternates.includes(product))?.[0];
  }

  public static getProductKeyName(coverageType: TCoverageType): string | undefined {
    const metadata = this.getMetadata(coverageType)
    return metadata?.productKeyName || metadata?.name
  }
}
