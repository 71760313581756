import { Injectable } from '@angular/core';

/**
 * Provides a hook for consumers to perform async tasks on a question set
 * before saving and navigating next.
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionSetInterceptorService {
    private _interceptors: Record<string, () => Promise<void>> = {};
  
    /**
     * Add async interceptor to be called before saving the current active
     * question set and navigating next. Consumers decide how to handle multiple
     * async processes--chain, race, all, or a combination--but must return a single
     * Promise. Same for errors: if an error can be ignored, consumers should catch
     * so the returned Promise resolves.
     * 
     * @param questionSetId {string} id from IQuestionSet (not IApplicationFormQuestionSet)
     * @param promiseCallback {Promise<void>}
     */
    public setSaveInterceptor(questionSetId: string, promiseCallback: () => Promise<void>) {
      this._interceptors[questionSetId] = promiseCallback;
    }
    
    public hasSaveInterceptor(questionSetId: string): boolean {
      return !!this._interceptors[questionSetId];
    }
  
    public callSaveInterceptor(questionSetId: string): Promise<void> {
      if (!this.hasSaveInterceptor(questionSetId)) {
        return Promise.resolve();
      }
      return this._interceptors[questionSetId]();
    }
}