import { Injectable } from '@angular/core';
import {
  ApplicationFormStates,
  BpSdkPermissionAction,
  BpSdkPermissions,
  IAnswer,
  IAppetiteCarrier,
  IAppetiteProduct,
  IApplicationFormQuestionSet,
  IApplicationFormSourceMetadata,
  IAuthState,
  ICarrier,
  ICarrierProductBucket,
  ICarrierWithProducts,
  IChoice,
  IChoiceState,
  IConfigOptions,
  IErrorState,
  IFeatureState,
  IFormState,
  ILocalState,
  IMessage,
  IPartnerEngineConfig,
  IProduct,
  IProductDetail,
  IQuestionGroupState,
  IQuestionPool,
  IQuestionSetState,
  IQuoteRequest,
  IQuoteableCarriersProduct,
  IQuotesState,
  IRealTimeEligibilityCarrier,
  IRefilter,
  IRefilterState,
  IResourceMap,
  ITerminalTheme,
  IUser,
  IVehicleConfigurationOptions,
  IVehicleMakeOptions,
  IVehicleModelOptions,
  IVehicleOptionsState,
  IVehicleSelection,
  IVehicleVinOptions,
  IVehicleYearOptions,
  IsTrailerSupportEnabled,
  LoadingStates,
  QuoteRequestRequestTypes,
  SdkHealthStates,
  WebSocketConnectionState,
  areDetailedRelevanceChipsEnabled,
  areQuotesLoading,
  areQuotesReadyForDisplay,
  doesUserOwnApplicationForm,
  findActiveAnswerById,
  findActiveAnswersByGroup,
  findActiveAnswersByQuestionGroup,
  findActiveAnswersByQuestionPool,
  getAllEnrolledCarriers,
  getAllQuestionSetResources,
  getAllRTECarriers,
  getAllUsersPermissions,
  getAnsweredRefiltersByQuestionId,
  getAnswersResourceState,
  getAnswersState,
  getAppetite,
  getApplicationFeatures,
  getApplicationForm,
  getApplicationFormProgress,
  getApplicationFormProgressForwardOnly,
  getApplicationFormState,
  getApplicationSource,
  getApplicationState,
  getAuth,
  getCarriersProductsBuckets,
  getChoices,
  getCompletedQuestionSets,
  getConfigOptions,
  getCoverageTypeAnswer,
  getCoverageTypes,
  getCurrentActiveQuestionSet,
  getCurrentActiveQuestionSetId,
  getCurrentCarrierProductBucket,
  getDisplayFriendlyVehicleOptionsSelection,
  getEligibleCarriers,
  getEligibleCarriersProducts,
  getEligibleCarriersWithProducts,
  getEligibleOfflineCarriers,
  getEligibleOfflineCarriersProducts,
  getEligibleOfflineCarriersWithProducts,
  getEligibleOnlineCarriers,
  getEligibleOnlineCarriersProducts,
  getEligibleOnlineCarriersWithProducts,
  getEligibleRTECarriers,
  getEmail,
  getFirstName,
  getFormId,
  getGoogleApiToken,
  getIncompleteQuestionSets,
  getIneligibleRTECarriers,
  getInlineMessagesForAnswerId,
  getLastName,
  getMessages,
  getMessagesForApplicationForm,
  getMessagesForCurrentQuestionSet,
  getMessagesForQuoteRequest,
  getMessagesForStepperStepQuestionSet,
  getName,
  getNonEmptyQuestionSets,
  getPartnerEngineConfig,
  getProductsForCarrier,
  getQuestionPoolInActiveQuestionSetByAnswer,
  getQuestionPoolState,
  getQuestionPoolStateById,
  getQuestionSetByCode,
  getQuestionSetById,
  getQuestionSetState,
  getQuestionSets,
  getQuoteById,
  getQuoteId,
  getQuotes,
  getQuotesByProduct,
  getQuotesByProductId,
  getQuotesByProductName,
  getQuotesByRequestStatus,
  getQuotesState,
  getRefilters,
  getRefiltersByQuestionId,
  getRefreshCount,
  getSdkHealth,
  getSdkState,
  getSdkStatus,
  getSdkWebSocketConnectionState,
  getSelectedChoices,
  getTenantLogo,
  getTenantName,
  getTenantThemeName,
  getTerminalTheme,
  getUnansweredRefiltersByQuestionId,
  getUserId,
  getVehicleConfigurationsResourceMap,
  getVehicleMakesResourceMap,
  getVehicleModelsResourceMap,
  getVehicleOptions,
  getVehicleOptionsConfigurations,
  getVehicleOptionsConfigurationsMap,
  getVehicleOptionsMakes,
  getVehicleOptionsMakesMap,
  getVehicleOptionsMap,
  getVehicleOptionsModels,
  getVehicleOptionsModelsMap,
  getVehicleOptionsResourceMap,
  getVehicleOptionsSelection,
  getVehicleOptionsSelectionsMap,
  getVehicleOptionsVin,
  getVehicleOptionsVinsMap,
  getVehicleOptionsYears,
  getVehicleOptionsYearsMap,
  getVehicleSelectionState,
  getVehicleSelectionsResourceMap,
  getVehicleVinsResourceMap,
  getVehicleYearsResourceMap,
  getViewStateFeatures,
  getVinSelectionState,
  getWebsocketsCableToken,
  hasCompletedQuestionSetAnswerHasChanged,
  isActiveQuestionSetLoading,
  isAutocompleteEnabled,
  isDebugMode,
  isFeatureEnabled,
  isFormCloned,
  isFormInactive,
  isFormLoaded,
  isFormLoading,
  isFormLocked,
  isFormTakenOver,
  isQuestionSetLoading,
  isSdkAuthenticated,
  isSdkAuthenticatedAndUserLoaded,
  isSdkConfigured,
  isSdkLoading,
  isSdkReady,
  isSdkTokenLoaded,
  isSdkUserLoaded,
  isUserAuthenticated,
  isUserImpersonating,
  selectAllQuotes,
  selectAnswersByQuestionCode,
  selectAnswersByReferenceType,
  selectAreParentQuestionSetsInNavigationEnabled,
  selectAuthExpiration,
  selectBusinessClassification,
  selectEligibleAdditionalMarketCarriersWithProducts,
  selectErrors,
  selectIncompleteCarrierQuestionSetsAsQuoteRequests,
  selectIsActiveQuestionSetPendingProcess,
  selectIsQuestionSetNavigationEnabled,
  selectOfflineEligibleCarriersAsQuoteRequests,
  selectOptInRequiredCarriers,
  selectParentQuestionSets,
  selectQuestionSetsState,
  selectToken,
  selectTokenExpiration,
  selectUser,
  userHasPermission,
} from '@boldpenguin/sdk';
import { CombinedState } from 'redux';
import { Observable, map } from 'rxjs';
import { SdkStoreService } from './sdk-store.service';

@Injectable({
  providedIn: 'root',
})
export class SelectorWrapperService {
  constructor(private sdkStoreService: SdkStoreService) {}

  // Answers

  findActiveAnswerById$(id: string): Observable<IAnswer | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => findActiveAnswerById(state, id)));
  }

  findActiveAnswersByQuestionPool$(poolId?: string): Observable<IAnswer[]> {
    return this.sdkStoreService.state$.pipe(map(state => findActiveAnswersByQuestionPool(state, poolId)));
  }

  findActiveAnswersByQuestionGroup$(groupId: string): Observable<IAnswer[]> {
    return this.sdkStoreService.state$.pipe(map(state => findActiveAnswersByQuestionGroup(state, groupId)));
  }

  findActiveAnswersByGroup$(props: { groupId: string; poolId?: string }): Observable<IAnswer[]> {
    return this.sdkStoreService.state$.pipe(map(state => findActiveAnswersByGroup(state, props)));
  }
  selectAnswersByQuestionCode$(questionSetCode: string): Observable<IAnswer[]> {
    return this.sdkStoreService.state$.pipe(map(state => selectAnswersByQuestionCode(state, questionSetCode)));
  }

  selectAnswerByReferenceType$(referenceType: string): Observable<IAnswer[]> {
    return this.sdkStoreService.state$.pipe(map(state => selectAnswersByReferenceType(state, referenceType)));
  }

  selectBusinessClassification$(): Observable<string | undefined> {
    return this.sdkStoreService.state$.pipe(map(selectBusinessClassification));
  }

  // appetite
  getAllEnrolledCarriers$(): Observable<IAppetiteCarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getAllEnrolledCarriers));
  }

  // auth
  getAuth$(): Observable<IAuthState> {
    return this.sdkStoreService.state$.pipe(map(getAuth));
  }

  getRefreshCount$(): Observable<number> {
    return this.sdkStoreService.state$.pipe(map(getRefreshCount));
  }

  getUser$(): Observable<IUser | undefined> {
    return this.sdkStoreService.state$.pipe(map(selectUser));
  }

  getToken$(): Observable<string | undefined> {
    return this.sdkStoreService.state$.pipe(map(selectToken));
  }

  getTokenExpiration$(): Observable<number> {
    return this.sdkStoreService.state$.pipe(map(selectTokenExpiration));
  }

  getAuthExpiration$(): Observable<number> {
    return this.sdkStoreService.state$.pipe(map(selectAuthExpiration));
  }

  getUserId$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getUserId));
  }

  getEmail$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getEmail));
  }

  getName$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getName));
  }

  getFirstName$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getFirstName));
  }

  getLastName$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getLastName));
  }

  getTenantLogo$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getTenantLogo));
  }

  getTenantName$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getTenantName));
  }

  getTenantThemeName$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getTenantThemeName));
  }

  getTerminalTheme$(): Observable<ITerminalTheme | undefined> {
    return this.sdkStoreService.state$.pipe(map(getTerminalTheme));
  }

  isUserAuthenticated$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isUserAuthenticated));
  }

  // choices
  getChoices$(): Observable<IChoiceState> {
    return this.sdkStoreService.state$.pipe(map(getChoices));
  }

  getSelectedChoices$(questionGroupId: string): Observable<IChoice[]> {
    return this.sdkStoreService.state$.pipe(map(state => getSelectedChoices(state, questionGroupId)));
  }

  // config
  getPartnerEngineConfig$(): Observable<IPartnerEngineConfig> {
    return this.sdkStoreService.state$.pipe(map(getPartnerEngineConfig));
  }

  getGoogleApiToken$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getGoogleApiToken));
  }

  getWebsocketsCableToken$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getWebsocketsCableToken));
  }

  isDebugMode$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isDebugMode));
  }

  getConfigOptions$(): Observable<IConfigOptions> {
    return this.sdkStoreService.state$.pipe(map(getConfigOptions));
  }

  isAutocompleteEnabled$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isAutocompleteEnabled));
  }

  isTrailerSupportEnabled$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(IsTrailerSupportEnabled));
  }

  areDetailedRelevanceChipsEnabled$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(areDetailedRelevanceChipsEnabled));
  }

  selectIsQuestionSetNavigationEnabled$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(selectIsQuestionSetNavigationEnabled));
  }

  selectAreParentQuestionSetsInNavigationEnabled$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(selectAreParentQuestionSetsInNavigationEnabled));
  }

  // eligibleCarriers
  getEligibleCarriersProducts$(): Observable<IQuoteableCarriersProduct[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleCarriersProducts));
  }

  getEligibleCarriers$(): Observable<ICarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleCarriers));
  }

  getEligibleCarriersWithProducts$(): Observable<ICarrierWithProducts[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleCarriersWithProducts));
  }

  getEligibleOnlineCarriersProducts$(): Observable<IQuoteableCarriersProduct[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleOnlineCarriersProducts));
  }

  getEligibleOnlineCarriers$(): Observable<ICarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleOnlineCarriers));
  }

  getEligibleOnlineCarriersWithProducts$(): Observable<ICarrierWithProducts[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleOnlineCarriersWithProducts));
  }

  getEligibleOfflineCarriersProducts$(): Observable<IQuoteableCarriersProduct[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleOfflineCarriersProducts));
  }

  getEligibleOfflineCarriers$(): Observable<ICarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleOfflineCarriers));
  }

  getEligibleOfflineCarriersWithProducts$(): Observable<ICarrierWithProducts[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleOfflineCarriersWithProducts));
  }

  getProductsForCarrier$(carrierId: string): Observable<IProduct[]> {
    return this.sdkStoreService.state$.pipe(map(state => getProductsForCarrier(state, carrierId)));
  }

  // errors
  getErrors$(): Observable<IErrorState> {
    return this.sdkStoreService.state$.pipe(map(selectErrors));
  }

  // form

  getApplicationForm$(): Observable<IFormState> {
    return this.sdkStoreService.state$.pipe(map(getApplicationForm));
  }
  getFormId$(): Observable<string | undefined> {
    return this.sdkStoreService.state$.pipe(map(getFormId));
  }

  getFormFeatures$(): Observable<string[]> {
    return this.sdkStoreService.state$.pipe(map(getApplicationFeatures));
  }

  getQuoteId$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getQuoteId));
  }

  getApplicationFormProgress$(): Observable<number> {
    return this.sdkStoreService.state$.pipe(map(getApplicationFormProgress));
  }

  getApplicationFormProgressForwardOnly$(): Observable<number> {
    return this.sdkStoreService.state$.pipe(map(getApplicationFormProgressForwardOnly));
  }

  getApplicationState$(): Observable<ApplicationFormStates> {
    return this.sdkStoreService.state$.pipe(map(getApplicationState));
  }

  getApplicationSource$(): Observable<{ metadata: IApplicationFormSourceMetadata | null; source: string }> {
    return this.sdkStoreService.state$.pipe(map(getApplicationSource));
  }

  getAppetite$(): Observable<IAppetiteProduct[]> {
    return this.sdkStoreService.state$.pipe(map(getAppetite));
  }

  getCarriersProductsBuckets$(): Observable<ICarrierProductBucket[]> {
    return this.sdkStoreService.state$.pipe(map(getCarriersProductsBuckets));
  }

  getCurrentCarrierProductBucket$(): Observable<ICarrierProductBucket | undefined> {
    return this.sdkStoreService.state$.pipe(map(getCurrentCarrierProductBucket));
  }

  isFormLocked$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isFormLocked));
  }

  isFormTakenOver$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isFormTakenOver));
  }

  isFormCloned$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isFormCloned));
  }

  isFormInactive$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isFormInactive));
  }

  isFormLoaded$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isFormLoaded));
  }

  isFormLoading$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isFormLoading));
  }

  // Messages
  getMessagesForCurrentQuestionSet$(): Observable<IMessage[]> {
    return this.sdkStoreService.state$.pipe(map(getMessagesForCurrentQuestionSet));
  }

  getMessagesForStepperStepQuestionSet$(questionSetId: string): Observable<IMessage[]> {
    return this.sdkStoreService.state$.pipe(map(state => getMessagesForStepperStepQuestionSet(state, questionSetId)));
  }

  getInlineMessagesForAnswerId$(answerId: string): Observable<IMessage[]> {
    return this.sdkStoreService.state$.pipe(map(state => getInlineMessagesForAnswerId(state, answerId)));
  }

  getMessagesForQuoteRequest$(quoteRequestId: string): Observable<IMessage[]> {
    return this.sdkStoreService.state$.pipe(map(state => getMessagesForQuoteRequest(state, quoteRequestId)));
  }
  getMessages$(): Observable<IMessage[]> {
    return this.sdkStoreService.state$.pipe(map(getMessages));
  }

  getMessagesForApplicationForm$(): Observable<IMessage[]> {
    return this.sdkStoreService.state$.pipe(map(getMessagesForApplicationForm));
  }

  // pending processes
  selectIsActiveQuestionSetPendingProcess$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(selectIsActiveQuestionSetPendingProcess));
  }

  // permissions
  getAllUsersPermissions$(): Observable<string[]> {
    return this.sdkStoreService.state$.pipe(map(getAllUsersPermissions));
  }

  doesUserOwnApplicationForm$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(doesUserOwnApplicationForm));
  }

  userHasPermission$(param: {
    permission: BpSdkPermissions;
    action?: BpSdkPermissionAction;
    object?: Record<string, unknown>;
  }): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(state => userHasPermission(state, param)));
  }

  isUserImpersonating$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isUserImpersonating));
  }

  // question pools
  getQuestionPoolInActiveQuestionSetByAnswer$(answer: IAnswer): Observable<IQuestionPool | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getQuestionPoolInActiveQuestionSetByAnswer(state, answer)));
  }

  // question sets
  selectQuestionSetsState$(): Observable<IQuestionSetState> {
    return this.sdkStoreService.state$.pipe(map(selectQuestionSetsState));
  }

  getQuestionSets$(): Observable<IApplicationFormQuestionSet[]> {
    return this.sdkStoreService.state$.pipe(map(getQuestionSets));
  }

  getCurrentActiveQuestionSetId$(): Observable<string> {
    return this.sdkStoreService.state$.pipe(map(getCurrentActiveQuestionSetId));
  }
  getCurrentActiveQuestionSet$(): Observable<IApplicationFormQuestionSet | undefined> {
    return this.sdkStoreService.state$.pipe(map(getCurrentActiveQuestionSet));
  }

  getNonEmptyQuestionSets$(): Observable<IApplicationFormQuestionSet[]> {
    return this.sdkStoreService.state$.pipe(map(getNonEmptyQuestionSets));
  }

  getCompletedQuestionSets$(): Observable<IApplicationFormQuestionSet[]> {
    return this.sdkStoreService.state$.pipe(map(getCompletedQuestionSets));
  }

  getIncompleteQuestionSets$(): Observable<IApplicationFormQuestionSet[]> {
    return this.sdkStoreService.state$.pipe(map(getIncompleteQuestionSets));
  }

  getQuestionSetById$(questionSetId: string): Observable<IApplicationFormQuestionSet | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getQuestionSetById(state, questionSetId)));
  }

  getQuestionSetByCode$(questionSetCode: string): Observable<IApplicationFormQuestionSet | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getQuestionSetByCode(state, questionSetCode)));
  }

  hasCompletedQuestionSetAnswerHasChanged$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(hasCompletedQuestionSetAnswerHasChanged));
  }

  selectParentQuestionSets$(): Observable<IApplicationFormQuestionSet[]> {
    return this.sdkStoreService.state$.pipe(map(selectParentQuestionSets));
  }

  // quotes
  getQuotes$(): Observable<IQuotesState[]> {
    return this.sdkStoreService.state$.pipe(map(getQuotes));
  }

  getQuoteById$(quoteId: string): Observable<IQuotesState | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getQuoteById(state, quoteId)));
  }

  getQuotesByRequestStatus$(quoteRequestStatus: QuoteRequestRequestTypes): Observable<IQuotesState[]> {
    return this.sdkStoreService.state$.pipe(map(state => getQuotesByRequestStatus(state, quoteRequestStatus)));
  }

  getQuotesByProduct$(product: IProductDetail): Observable<IQuotesState[]> {
    return this.sdkStoreService.state$.pipe(map(state => getQuotesByProduct(state, product)));
  }

  getQuotesByProductId$(productId: string): Observable<IQuotesState[]> {
    return this.sdkStoreService.state$.pipe(map(state => getQuotesByProductId(state, productId)));
  }

  getQuotesByProductName$(productName: string): Observable<IQuotesState[]> {
    return this.sdkStoreService.state$.pipe(map(state => getQuotesByProductName(state, productName)));
  }

  areQuotesLoading$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(areQuotesLoading));
  }

  areQuotesReadyForDisplay$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(areQuotesReadyForDisplay));
  }

  // rte
  getAllRTECarriers$(): Observable<IRealTimeEligibilityCarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getAllRTECarriers));
  }

  getEligibleRTECarriers$(): Observable<IRealTimeEligibilityCarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getEligibleRTECarriers));
  }

  getIneligibleRTECarriers$(): Observable<IRealTimeEligibilityCarrier[]> {
    return this.sdkStoreService.state$.pipe(map(getIneligibleRTECarriers));
  }

  getCoverageTypeAnswer$(): Observable<IAnswer[]> {
    return this.sdkStoreService.state$.pipe(map(getCoverageTypeAnswer));
  }

  getCoverageTypes$(): Observable<string[]> {
    return this.sdkStoreService.state$.pipe(map(getCoverageTypes));
  }

  selectIncompleteCarrierQuestionSetsAsQuoteRequests$(): Observable<IQuoteRequest[]> {
    return this.sdkStoreService.state$.pipe(map(selectIncompleteCarrierQuestionSetsAsQuoteRequests));
  }

  selectEligibleAdditionalMarketCarriersWithProducts$(): Observable<ICarrierWithProducts[]> {
    return this.sdkStoreService.state$.pipe(map(selectEligibleAdditionalMarketCarriersWithProducts));
  }

  selectOptInRequiredCarriers$(): Observable<IQuoteRequest[]> {
    return this.sdkStoreService.state$.pipe(map(selectOptInRequiredCarriers));
  }

  selectOfflineEligibleCarriersAsQuoteRequests$(): Observable<IQuoteRequest[]> {
    return this.sdkStoreService.state$.pipe(map(selectOfflineEligibleCarriersAsQuoteRequests));
  }

  selectAllQuotes$(): Observable<IQuotesState[]> {
    return this.sdkStoreService.state$.pipe(map(selectAllQuotes));
  }

  //refilter
  getRefilters$(): Observable<IRefilterState> {
    return this.sdkStoreService.state$.pipe(map(getRefilters));
  }

  getRefiltersByQuestionId$(questionId: string): Observable<IRefilter[]> {
    return this.sdkStoreService.state$.pipe(map(state => getRefiltersByQuestionId(state, questionId)));
  }

  getUnansweredRefiltersByQuestionId$(questionId: string): Observable<IRefilter[]> {
    return this.sdkStoreService.state$.pipe(map(state => getUnansweredRefiltersByQuestionId(state, questionId)));
  }

  getAnsweredRefiltersByQuestionId$(questionId: string): Observable<IRefilter[]> {
    return this.sdkStoreService.state$.pipe(map(state => getAnsweredRefiltersByQuestionId(state, questionId)));
  }
  //local status
  getSdkStatus$(): Observable<ILocalState> {
    return this.sdkStoreService.state$.pipe(map(getSdkStatus));
  }

  getSdkHealth$(): Observable<SdkHealthStates> {
    return this.sdkStoreService.state$.pipe(map(getSdkHealth));
  }

  getSdkState$() {
    return this.sdkStoreService.state$.pipe(map(getSdkState));
  }

  isSdkLoading$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkLoading));
  }

  isSdkConfigured$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkConfigured));
  }

  isSdkTokenLoaded$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkTokenLoaded));
  }

  isSdkUserLoaded$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkUserLoaded));
  }

  isSdkAuthenticated$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkAuthenticated));
  }

  isSdkAuthenticatedAndUserLoaded$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkAuthenticatedAndUserLoaded));
  }

  isSdkReady$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isSdkReady));
  }

  getSdkWebSocketConnectionState$(): Observable<WebSocketConnectionState> {
    return this.sdkStoreService.state$.pipe(map(getSdkWebSocketConnectionState));
  }

  // remote status
  getAnswersState$(): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(getAnswersState));
  }

  getAnswersResourceState$(): Observable<{ [p: string]: LoadingStates }> {
    return this.sdkStoreService.state$.pipe(map(getAnswersResourceState));
  }

  getApplicationFormState$(): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(getApplicationFormState));
  }

  getQuestionPoolState$(): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(getQuestionPoolState));
  }

  getQuestionPoolStateById$(questionPoolId: string): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(state => getQuestionPoolStateById(state, questionPoolId)));
  }

  getQuestionSetState$(): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(getQuestionSetState));
  }

  getAllQuestionSetResources$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getAllQuestionSetResources));
  }

  isActiveQuestionSetLoading$(): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(isActiveQuestionSetLoading));
  }

  isQuestionSetLoading$(questionSetId: string): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(state => isQuestionSetLoading(state, questionSetId)));
  }

  getQuotesState$(): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(getQuotesState));
  }

  // vehiclesOptions
  getVehicleOptionsMap$(): Observable<IVehicleOptionsState> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsMap));
  }

  getVehicleOptions$(questionGroupId: string): Observable<{
    configurations: IVehicleConfigurationOptions | undefined;
    makes: IVehicleMakeOptions | undefined;
    models: IVehicleModelOptions | undefined;
    selection: IVehicleSelection | undefined;
    vin: IVehicleVinOptions | undefined;
    years: IVehicleYearOptions | undefined;
  }> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptions(state, questionGroupId)));
  }

  getVehicleOptionsYearsMap$(): Observable<IQuestionGroupState<IVehicleYearOptions>> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsYearsMap));
  }

  getVehicleOptionsMakesMap$(): Observable<IQuestionGroupState<IVehicleMakeOptions>> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsMakesMap));
  }

  getVehicleOptionsModelsMap$(): Observable<IQuestionGroupState<IVehicleModelOptions>> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsModelsMap));
  }

  getVehicleOptionsConfigurationsMap$(): Observable<IQuestionGroupState<IVehicleConfigurationOptions>> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsConfigurationsMap));
  }

  getVehicleOptionsSelectionsMap$(): Observable<IQuestionGroupState<IVehicleSelection>> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsSelectionsMap));
  }

  getVehicleOptionsVinsMap$(): Observable<IQuestionGroupState<IVehicleVinOptions>> {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsVinsMap));
  }

  getVehicleOptionsYears$(questionGroupId: string): Observable<IVehicleYearOptions | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptionsYears(state, questionGroupId)));
  }

  getVehicleOptionsMakes$(questionGroupId: string): Observable<IVehicleMakeOptions | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptionsMakes(state, questionGroupId)));
  }

  getVehicleOptionsModels$(questionGroupId: string): Observable<IVehicleModelOptions | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptionsModels(state, questionGroupId)));
  }

  getVehicleOptionsConfigurations$(questionGroupId: string): Observable<IVehicleConfigurationOptions | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptionsConfigurations(state, questionGroupId)));
  }

  getVehicleOptionsSelection$(questionGroupId: string): Observable<IVehicleSelection | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptionsSelection(state, questionGroupId)));
  }

  getVehicleOptionsVin$(questionGroupId: string): Observable<IVehicleVinOptions | undefined> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleOptionsVin(state, questionGroupId)));
  }

  getDisplayFriendlyVehicleOptionsSelection$(questionGroupId: string): Observable<{
    year: string | number;
    make: string;
    model: string;
    configuration: string;
  }> {
    return this.sdkStoreService.state$.pipe(map(state => getDisplayFriendlyVehicleOptionsSelection(state, questionGroupId)));
  }

  getVehicleOptionsResourceMap$(): Observable<
    CombinedState<{
      configurations: IResourceMap;
      makes: IResourceMap;
      models: IResourceMap;
      selection: IResourceMap;
      vins: IResourceMap;
      years: IResourceMap;
    }>
  > {
    return this.sdkStoreService.state$.pipe(map(getVehicleOptionsResourceMap));
  }

  getVehicleConfigurationsResourceMap$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getVehicleConfigurationsResourceMap));
  }

  getVehicleMakesResourceMap$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getVehicleMakesResourceMap));
  }

  getVehicleModelsResourceMap$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getVehicleModelsResourceMap));
  }

  getVehicleSelectionsResourceMap$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getVehicleSelectionsResourceMap));
  }

  getVehicleVinsResourceMap$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getVehicleVinsResourceMap));
  }

  getVehicleYearsResourceMap$(): Observable<IResourceMap> {
    return this.sdkStoreService.state$.pipe(map(getVehicleYearsResourceMap));
  }

  getVehicleSelectionState$(questionGroupId: string): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(state => getVehicleSelectionState(state, questionGroupId)));
  }

  getVinSelectionState$(questionGroupId: string): Observable<LoadingStates> {
    return this.sdkStoreService.state$.pipe(map(state => getVinSelectionState(state, questionGroupId)));
  }

  //viewState
  getViewStateFeatures$(): Observable<IFeatureState> {
    return this.sdkStoreService.state$.pipe(map(getViewStateFeatures));
  }

  isFeatureEnabled$(feature: string): Observable<boolean> {
    return this.sdkStoreService.state$.pipe(map(state => isFeatureEnabled(state, feature)));
  }
}
