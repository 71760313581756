<ng-container [ngSwitch]="uiButtonType">
  <button
    *ngSwitchCase="emperorUIButtonTypes.Standard"
    #button
    mat-raised-button
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button"
    [color]="materialColor"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>
  <button
    *ngSwitchCase="emperorUIButtonTypes.Large"
    #button
    mat-raised-button
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button emperor-ui-button-large"
    [color]="materialColor"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>
  <button
    *ngSwitchCase="emperorUIButtonTypes.Stroked"
    #button
    mat-stroked-button
    class="emperor-ui-button emperor-ui-button-stroked"
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    [color]="materialColor"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>
  <button
    *ngSwitchCase="emperorUIButtonTypes.StrokedLarge"
    #button
    mat-stroked-button
    class="emperor-ui-button emperor-ui-button-stroked emperor-ui-button-large"
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    [color]="materialColor"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>
  <button
    *ngSwitchCase="emperorUIButtonTypes.FabLarge"
    #button
    mat-fab
    [emperorTooltip]="tooltip"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button mat-elevation-z"
    [color]="materialColor"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>

  <button
    *ngSwitchCase="emperorUIButtonTypes.Fab"
    #button
    mat-mini-fab
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [tooltipPlacement]="tooltipPlacement"
    [closeTooltip]="closeTooltip"
    class="emperor-ui-button mat-elevation-z"
    [color]="materialColor"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>

  <button
    *ngSwitchCase="emperorUIButtonTypes.Text"
    #button
    mat-button
    [color]="materialColor"
    class="emperor-ui-button emperor-ui-button-text"
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [closeTooltip]="closeTooltip"
    [tooltipPlacement]="tooltipPlacement"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>

  <button
    *ngSwitchCase="emperorUIButtonTypes.Link"
    #button
    [ngClass]="color"
    class="emperor-ui-link-button"
    [type]="type"
    (click)="onButtonClick($event)"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>

  <button
    *ngSwitchCase="emperorUIButtonTypes.InverseStroked"
    #button
    mat-flat-button
    [color]="materialColor"
    class="emperor-ui-button emperor-ui-inverse-stroked-button"
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [tooltipPlacement]="tooltipPlacement"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>

  <button
    *ngSwitchCase="emperorUIButtonTypes.Neutral"
    #button
    mat-stroked-button
    [color]="materialColor"
    class="emperor-ui-button emperor-ui-button-neutral"
    [emperorTooltip]="tooltip"
    [disableRipple]="disabled"
    [closeTooltip]="closeTooltip"
    [tooltipPlacement]="tooltipPlacement"
    [type]="type"
    [id]="id || ''"
    [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemp"></ng-container>
  </button>
</ng-container>

<ng-template #contentTemp>
  <ng-content></ng-content>
</ng-template>
