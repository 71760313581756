<div class="container" [class]="uiAlertType">
  <emperor-icon *ngIf="iconName && !hideIcon" class="indicator-icon" [name]="iconName"></emperor-icon>
  <div class="message">
    <ng-content></ng-content>
  </div>
  <emperor-icon
    *ngIf="!!dismissTooltip"
    name="remove_circle_2"
    class="close-icon"
    tabindex="0"
    role="button"
    [emperorTooltip]="dismissTooltip"
    (click)="dismiss.emit()"
  ></emperor-icon>
</div>
