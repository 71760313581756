import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { EmperorTooltipModule } from '../tooltip/tooltip.module';
import { TimelineStepComponent } from './components/timeline-step/timeline-step.component';
import { TimelineTaskComponent } from './components/timeline-task/timeline-task.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { EmperorButtonModule } from '../button';

export * from './components/timeline/timeline.component';
@NgModule({
  imports: [CommonModule, IconsModule, EmperorTooltipModule, EmperorButtonModule],
  declarations: [TimelineComponent, TimelineStepComponent, TimelineTaskComponent],
  exports: [TimelineComponent],
})
export class EmperorTimelineModule {}
