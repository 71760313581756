import { Pipe, PipeTransform } from '@angular/core';
import { IChoice } from '@boldpenguin/sdk';

@Pipe({
  name: 'bcsExamples',
})
export class BcsExamplesPipe implements PipeTransform {
  transform(value: IChoice): string {
    if (!value?.highlights) return '';
    const examples = value.highlights
      .filter(h => h.field === 'synonyms')
      .map(h => h.text)
      .join(', ');
    return examples ? `<span class="bcs-examples-label">Examples</span>: ${examples}` : '';
  }
}
