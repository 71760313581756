import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export interface FooterLink {
  text: string;
  url?: string;
  isHtml?: boolean;
}

export enum FooterLinkType {
  Text,
  Url,
  Html,
}

interface TypedFooterLink extends FooterLink {
  type: FooterLinkType;
}

const defaultLinks: TypedFooterLink[] = [
  {
    text: `©${new Date().getFullYear()} Bold Penguin`,
    type: FooterLinkType.Text,
  },
  {
    text: 'All Rights Reserved',
    type: FooterLinkType.Text,
  },
  {
    text: 'Terms & Conditions',
    url: 'https://www.boldpenguin.com/terms',
    type: FooterLinkType.Url,
  },
  {
    text: 'Privacy Policy',
    url: 'https://www.boldpenguin.com/privacy-policy',
    type: FooterLinkType.Url,
  },
];

@Component({
  selector: 'emperor-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() links: FooterLink[];

  linkTypes = FooterLinkType;
  typedLinks = new Array<TypedFooterLink>();

  ngOnInit(): void {
    this.typedLinks = this.mapLinksToTypedLinks(this.links);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.links && !changes.links.firstChange) {
      this.typedLinks = this.mapLinksToTypedLinks(this.links);
    }
  }

  private mapLinksToTypedLinks(links: FooterLink[]): TypedFooterLink[] {
    const linksOrDefaults = links ?? defaultLinks;
    return linksOrDefaults.map(link => this.addLinkType(link));
  }

  private addLinkType(link: FooterLink): TypedFooterLink {
    return {
      ...link,
      type: this.getLinkType(link),
    };
  }

  private getLinkType(link: FooterLink): FooterLinkType {
    let type = FooterLinkType.Text;

    if (link.url) {
      type = FooterLinkType.Url;
    } else if (link.isHtml) {
      type = FooterLinkType.Html;
    }

    return type;
  }
}
