import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EmperorCardHeaderColor, EmperorDialogSize, EmperorUIAlertType, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import {
  AddressValidationVerdict,
  IAnswer,
  IAnswerUpdatedEvent,
  IGoogleAddressValidationComponent,
  IGoogleValidatedAddressView,
  IPartnerEngineConfig,
  ValidationCTA,
  isAddressValidationMissingInfoButtonDisabled,
  mapToComponentTypeText
} from '@boldpenguin/sdk';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, map } from 'rxjs';
import { AnalyticsTrackingEventName } from '../../models';
import { AddressValidationDialogService } from '../../service/address-validation-dialog.service';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-google-address-validation',
  templateUrl: './bp-sdk-google-address-validation.component.html',
  styleUrls: ['./bp-sdk-google-address-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkGoogleAddressValidationComponent implements IGoogleAddressValidationComponent, OnInit, OnChanges {
  @Input() answerMap: { [key: string]: IAnswer };
  @Input() config: IPartnerEngineConfig;
  @Input() googleApiToken: string;
  @Input() originalAddress: string;
  @Input() stagedAnswers: IAnswerUpdatedEvent[];
  @Input() verdict: AddressValidationVerdict;
  @Input() validatedAddressView : IGoogleValidatedAddressView;
  @Input() addressValidationId : string;

  readonly AddressValidationVerdict = AddressValidationVerdict;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly dialogSize = EmperorDialogSize.Small;
  readonly EmperorUIAlertType = EmperorUIAlertType;
  shouldShow$: Observable<boolean> = new Observable();

  constructor(private elementRef: ElementRef, private dialogService: AddressValidationDialogService) {}

  ngOnInit() {
    this.shouldShow$ = this.dialogService.dialogIdToShow$.pipe(map(id => this.addressValidationId === id));
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.validatedAddressView && this.verdict && this.verdict !== AddressValidationVerdict.ACCEPT) {
      this.elementRef.nativeElement.dispatchEvent(
        new CustomEvent('bpAnalyticsTrack', {
          detail: {
            eventName: AnalyticsTrackingEventName.AddressValidation_DialogOpened,
            eventPayload: {
              verdict: this.verdict
            }
          },
          bubbles: true,
        }),
      );

      this.dialogService.addToQueue(this.addressValidationId);
    }
  }

  continueWithConfirm() {
    this.dialogService.removeFromQueue(this.addressValidationId);
    this.emitCTA(ValidationCTA.CONFIRM);
  }

  continueWithMissingInputFix() {
    this.dialogService.removeFromQueue(this.addressValidationId);
    this.emitCTA(ValidationCTA.MISSING_FIX);
  }

  continueAsEntered() {
    this.dialogService.removeFromQueue(this.addressValidationId);
    this.emitCTA(ValidationCTA.AS_ENTERED);
  }

  editAddress() {
    this.dialogService.removeFromQueue(this.addressValidationId);
    this.emitCTA(ValidationCTA.EDIT);
  }

  close() {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('bpAnalyticsTrack', {
        detail: {
          eventName: AnalyticsTrackingEventName.AddressValidation_CloseDialogClicked,
        },
        bubbles: true,
      }),
    );
    this.editAddress();
  }

  isMissingInfoButtonDisabled(): boolean {
    return isAddressValidationMissingInfoButtonDisabled(this.stagedAnswers);
  }
  mapToComponentTypeText(type: string): string {
    return mapToComponentTypeText(type);
  }

  private emitCTA(CTA: ValidationCTA) {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('validationCTAEvent', {
        detail: CTA,
        bubbles: true,
      }),
    );
  }
}
