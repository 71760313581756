import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuestionFieldType } from '@boldpenguin/sdk';
import { InputDirective } from '../../directive/input.directive';
import { IIconPrefix } from '../../models';

@Component({
  selector: 'emperor-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TextInputComponent extends InputDirective {
  @Input() type = 'text';
  @Input() iconPrefix: IIconPrefix = {
    showPrefix: false,
    icon: '',
  };

  get isNumeric(): boolean {
    return this.type === QuestionFieldType.Number;
  }
}
