import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressValidationDialogService {
  private dialogs: string[] = [];
  dialogIdToShow$: BehaviorSubject<string> = new BehaviorSubject('');

  addToQueue(id: string) {
    this.dialogs.push(id);
    if(this.dialogs.length === 1) {
      this.dialogIdToShow$.next(this.dialogs[0]);
    }
  }

  removeFromQueue(id: string) {
    this.dialogs = this.dialogs.filter(dialog => dialog !== id);
    this.dialogIdToShow$.next(this.dialogs[0] ?? '');
  }
}
