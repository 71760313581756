import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmperorCardHeaderColor } from '../../../models';

@Component({
  selector: 'emperor-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorDialogHeaderComponent {
  @Input() title = '';
  @Input() shouldShowClose = true;
  @Input() color = EmperorCardHeaderColor.PRIMARY;
}
