<footer>
  <nav class="emperor-ui-nav">
    <ul class="emperor-ui-inline-links">
      <li class="emperor-ui-link" *ngFor="let link of typedLinks">
        <ng-container [ngSwitch]="link.type">
          <a *ngSwitchCase="linkTypes.Url" [href]="link.url">{{ link.text }}</a>
          <ng-container *ngSwitchCase="linkTypes.Text">
            {{ link.text }}
          </ng-container>
          <span *ngSwitchCase="linkTypes.Html" [innerHTML]="link.text"></span>
        </ng-container>
      </li>
    </ul>
    <a class="emperor-ui-penguin-link" href="https://www.boldpenguin.com/" aria-label="Go to Bold Penguin home">
      <emperor-penguin-power [height]="32"></emperor-penguin-power>
    </a>
  </nav>
</footer>
