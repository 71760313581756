import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotePresentationComponent } from './quote-presentation.component';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { EmperorButtonModule } from '../button';
import { DisplayPricePipe } from '../pipes/display-price.pipe';
import { EmperorTooltipModule } from '../tooltip';

export * from './quote-presentation.component';

@NgModule({
  declarations: [QuotePresentationComponent, DisplayPricePipe],
  exports: [QuotePresentationComponent],
  imports: [CommonModule, IconsModule, EmperorButtonModule, EmperorTooltipModule],
})
export class EmperorQuotePresentationModule {}
