<div class="emperor-add-item-container">
  <emperor-button
    class="emperor-add-item-button"
    [color]="EmperorThemeColor.PRIMARY"
    [uiButtonType]="EmperorUIButtonTypes.Stroked"
    [disabled]="!canAddMore || loadingSpinner"
    (click)="addItemRequested()"
    [emperorTooltip]="helpText"
  >
    <div class="button-layout">
      <emperor-spinner [diameter]="36" *ngIf="loadingSpinner; else buttonText"> </emperor-spinner>
      <ng-template #buttonText>
        <emperor-icon *ngIf="!!icon" [name]="icon"></emperor-icon>
        <span class="button-text">{{ text }}</span>
      </ng-template>
    </div>
  </emperor-button>
</div>
