import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoQuotesComponent } from './no-quotes.component';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { EmperorButtonModule } from '../button';

export * from './no-quotes.component';

@NgModule({
  declarations: [NoQuotesComponent],
  exports: [NoQuotesComponent],
  imports: [CommonModule, IconsModule, EmperorButtonModule],
})
export class EmperorNoQuotesModule {}
