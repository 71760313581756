<emperor-icon
  class="emperor-ui-coverage-elibility-icon"
  *ngIf="status?.icon"
  [class]="status.status"
  [ngClass]="{
    'pending-icon': status.icon === 'pending'
  }"
  [emperorTooltip]="status.statusMessage"
  [name]="status.icon!"
></emperor-icon>
