import { Component, ElementRef, Input } from '@angular/core';
import { EmperorThemeColor, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { IVehicleDeleteButtonComponent } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-vehicle-delete-button',
  templateUrl: './bp-sdk-vehicle-delete-button.component.html',
  styleUrls: ['./bp-sdk-vehicle-delete-button.component.scss'],
})
export class BpSdkVehicleDeleteButtonComponent implements IVehicleDeleteButtonComponent {
  uiButtonTypes = EmperorUIButtonTypes;
  themeColors = EmperorThemeColor;

  @Input()
  text = 'Delete';

  @Input()
  buttonDisabled = false;

  constructor(private elementRef: ElementRef) {}

  click() {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('click', {
        bubbles: true,
      }),
    );
  }
}
