import { Component } from '@angular/core';
import { EmperorCardHeaderColor, EmperorDialogConfig, EmperorDialogRef, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';

@Component({
  selector: 'emperor-dial-in-message-dialog',
  templateUrl: './dial-in-message-dialog.component.html',
  styleUrls: ['./dial-in-message-dialog.component.scss'],
})
export class DialInMessageDialogComponent {
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;

  content: string;

  constructor(private dialogRef: EmperorDialogRef, config: EmperorDialogConfig) {
    this.content = config?.data?.content;
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
}
