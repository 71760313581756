<div class="content-container">
  <emperor-icon class="indicator-icon" [name]="iconName"></emperor-icon>
  <div class="message">
    {{ selectedAlert }}
  </div>
</div>

<emperor-carousel-navigation
  *ngIf="shouldDisplayNavigation"
  class="navigation"
  [navigationMode]="navigationMode"
  [itemCount]="alerts.length"
  (changeIndex)="onChangeIndex($event)"
></emperor-carousel-navigation>
