import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { SelectorWrapperService } from '@boldpenguin/emperor-services';
import { IAnswer, IApplicationFormQuestionSet } from '@boldpenguin/sdk';
import { Observable, of } from 'rxjs';
import { delay, distinctUntilKeyChanged, filter, mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuestionFocusService {
  readonly hiddenDriverCountQuestionCode = 'mqs_drivers';
  readonly driverFirstNameCode = 'mqs_driver_first_name';
  readonly maxAttempts = 15;
  protected counter = 0;
  constructor(
    protected readonly selectorWrapperService: SelectorWrapperService,
    @Inject(DOCUMENT) protected readonly document: Document,
    private ngZone: NgZone,
  ) {}

  public findFirstQuestion(): Observable<{
    element: Element | null;
    answer: IAnswer | null;
  }> {
    return this.selectorWrapperService.getCurrentActiveQuestionSet$().pipe(
      filter((questionSet): questionSet is IApplicationFormQuestionSet => !!questionSet),
      distinctUntilKeyChanged('question_set_id'),
      switchMap((questionSet: IApplicationFormQuestionSet) => {
        this.counter = 0;
        return this.findFirstElement(questionSet.answers);
      }),
    );
  }

  public focusOnElement(answerElement, answer) {
    if (!answerElement || !answer) return;
    // Make sure the elements are in a focusable state
    Promise.all(this.document.getAnimations().map(a => a.finished))
      .then(() => {
        this.ngZone.run(() => {
          answerElement.scrollIntoView(false);
          if (this.isBooleanInput(answerElement, answer)) {
            this.focusOnBooleanInput(answerElement);
            return;
          } else if (this.isFocusableInput(answerElement)) {
            answerElement.focus();
            return;
          }
        });
      }) // Swallow error from animations getting canceled.
      .catch(() => {
        return;
      });
  }

  protected findFirstElement(answers: IAnswer[]): Observable<{ element: Element | null; answer: IAnswer | null }> {
    const emptyResponse = of({ element: null, answer: null });
    const answer = this.getFirstAnswer(answers);
    if (!answer) {
      return emptyResponse;
    }
    const element = this.document.getElementById(answer.id);
    // The questionSet can update faster than the renderer updates the DOM.
    // This attempts a few times to find the element.
    if (!element) {
      this.counter++;
      return this.counter < this.maxAttempts
        ? emptyResponse.pipe(
            delay(100),
            mergeMap(() => this.findFirstElement(answers)),
          )
        : emptyResponse;
    }
    return of({ element, answer });
  }

  private isBooleanInput(answerElement: HTMLElement, answer: IAnswer): boolean {
    return answerElement.nodeName === 'LABEL' && answer.question.field_type === 'radio';
  }

  private isFocusableInput(answerElement: HTMLElement): boolean {
    return answerElement.nodeName === 'INPUT' || answerElement.nodeName === 'MAT-SELECT';
  }

  private focusOnBooleanInput(answerElement: HTMLElement): void {
    answerElement.parentElement?.querySelector('button')?.focus();
  }

  protected getFirstAnswer(answers: IAnswer[]): IAnswer | undefined {
    let answer = answers.find(
      a =>
        !a.is_readonly &&
        a.question.code !== this.hiddenDriverCountQuestionCode &&
        a.question.field_type !== 'anchor' &&
        a.question.field_type !== 'label',
    );

    if (answer && this.isDriverInput(answer)) {
      answer = this.getOpenDriverInput(answers);
    }

    return answer;
  }

  private isDriverInput(answer: IAnswer): boolean {
    return answer.question.code.startsWith(this.driverFirstNameCode);
  }

  private getOpenDriverInput(answers: IAnswer[]): IAnswer {
    const drivers = answers.filter(a => this.isDriverInput(a));
    return drivers[drivers.length - 1];
  }
}
