import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';
import { EmperorUISelectFormChoice } from '../../models';
import { BPErrorMessage, BPErrorState } from '../../utils/input-error-state';

export enum EmperorBooleanInputTypes {
  Primary = 'emperor-boolean-input-primary',
  OnOff = 'emperor-boolean-input-onoff',
}

@Component({
  selector: 'emperor-on-off-input',
  templateUrl: './on-off-input.component.html',
  styleUrls: ['./on-off-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnOffInputComponent),
      multi: true,
    },
  ],
})
export class OnOffInputComponent extends InputFieldsetDisableDirective {
  @Input() id: string;

  get isDisabled(): boolean {
    return this.control?.disabled;
  }

  showErrorState(): boolean {
    return BPErrorState(this.control);
  }
  showErrorMessages(): string {
    return BPErrorMessage(this.control.errors, this.errorText, this.errorMessageMap);
  }

  onButtonClick(choice: boolean) {
    if (!this.isDisabled) {
      this.control.setValue(choice);
      this.onTouch();
    }
  }

  identifyRadioId(_index, choice: EmperorUISelectFormChoice) {
    return choice.id;
  }

  writeValue(value) {
    if (value !== this.control.value) {
      this.control.setValue(value);
    }
  }

  registerOnChange(fn: (_value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
}
