import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';
import { EmperorCardTypes } from '@boldpenguin/emperor-presentational';
import {
  IAnswer,
  IGlobalSDK,
  IMappedQuestionGroupAnswers,
  IVehiclePanelComponent,
  IVehicleSummary,
  VehicleFlowTypes,
  updateAnswer,
} from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, shareReplay, skip, startWith } from 'rxjs/operators';
import { VehicleService, questionCodeMap } from '../../service/vehicle.service';
import { containsPrefilledAnswer } from '../../utils/prefill';

export enum VehicleEntryQuestions {
  vin = 'vin',
  yearMakeModel = 'year',
  trailer = 'trailer',
  none = 'none',
}

const vehicleTypeMap = {
  [VehicleEntryQuestions.vin]: 'VIN',
  [VehicleEntryQuestions.yearMakeModel]: 'Year / Make / Model',
  [VehicleEntryQuestions.trailer]: 'Trailer',
  VIN: VehicleEntryQuestions.vin,
  'Year / Make / Model': VehicleEntryQuestions.yearMakeModel,
  Trailer: VehicleEntryQuestions.trailer,
};

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-vehicle-panel',
  templateUrl: './bp-sdk-vehicle-panel.component.html',
  styleUrls: ['./bp-sdk-vehicle-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Each instance should have its own instance of the service
  providers: [VehicleService],
})
export class BpSdkVehiclePanelComponent implements OnInit, IVehiclePanelComponent {
  @Input() vehicleLabel: string;
  @Input() set answers(answers: IAnswer[]) {
    this.vehicleService.updateAnswers(answers);
  }
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() canSave: boolean;
  @Input() code: string;
  /**
   * State of answering questions about the vehicles
   */
  @Input() set isEditing(editing: boolean) {
    this.vehicleService.updateEditingState(editing);
  }
  /**
   * Like isEdit but specific to the vin/year of vehicle questions
   */
  isEditingTypeOfVehicle$ = this.vehicleService.isEditingTypeOfVehicle$;

  /**
   * State of the showing the questions in SDK
   * Deprecated in this instance
   */
  @Input() isExpanded = false;
  @Input() isValid: boolean;
  @Input() isVehicleTrailer: boolean;
  @Input() set questionGroup(questionGroup: IMappedQuestionGroupAnswers) {
    this.vehicleService.updateQuestionGroup(questionGroup);
  }
  @Input() shouldShowTrailers: boolean;
  @Input() vehicleInformation: IVehicleSummary;
  /**
   * Updates internal SDK state for showing extra questions
   * Deprecated in this instance
   */
  @Input() handleClose: () => void;
  @Input() handleDelete: () => void;
  @Input() handleEdit: (e: MouseEvent) => void;
  /**
   * Updates internal SDK state for showing extra questions
   * Deprecated in this instance
   */
  @Input() handleOpen: () => void;

  questionGroupId$ = this.vehicleService.questionGroupId$;
  questionGroup$ = this.vehicleService.questionGroup$;
  answers$ = this.vehicleService.answers$;
  finishedEditingTypeOfVehicle$ = this.vehicleService.finishedEditingTypeOfVehicle$;
  typeOfVehicleQuestion$: Observable<VehicleEntryQuestions>;
  answersAreValid$ = this.vehicleService.answersAreValid$;
  isPanelExpanded$ = new BehaviorSubject<boolean>(false);
  answerIdMap$ = this.vehicleService.answerIdMap$;
  // Handles mapping the value of the vin number to the vin input field
  vinValue$ = this.vehicleService.vinValue$;
  isPrefilled$: Observable<boolean>;

  readonly emperorAddVehicleLabel = 'Choose how to add your vehicle';
  readonly vehicleEntryQuestions = VehicleEntryQuestions;
  readonly emperorCardType = EmperorCardTypes;
  readonly questionCodeMap = questionCodeMap;

  addVehicleChoices: EmperorUISelectFormChoice[] = [
    {
      id: 'year',
      value: VehicleEntryQuestions.yearMakeModel,
      label: 'Add a vehicle by year, make & model',
    },
    {
      id: 'vin',
      value: VehicleEntryQuestions.vin,
      label: 'Add vehicle by VIN',
    },
    {
      id: 'trailer',
      value: VehicleEntryQuestions.trailer,
      label: 'Add a trailer',
    },
  ];

  formControl = new FormControl<VehicleEntryQuestions | null>(null, Validators.required);

  constructor(private vehicleService: VehicleService, private element: ElementRef) {}

  ngOnInit(): void {
    this.addVehicleChoices = this.addVehicleChoices.filter(choice => {
      if (choice.value !== VehicleEntryQuestions.trailer || this.shouldShowTrailers) {
        return choice;
      }
    });

    this.isPrefilled$ = this.answers$.pipe(containsPrefilledAnswer());

    this.typeOfVehicleQuestion$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      map(value => (!value ? VehicleEntryQuestions.none : value)),
      shareReplay({ refCount: false }),
      distinctUntilChanged(),
      untilDestroyed(this),
    );

    this.typeOfVehicleQuestion$
      .pipe(
        filter(type => type === VehicleEntryQuestions.yearMakeModel || type === VehicleEntryQuestions.trailer),
        skip(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.handleEdit({ stopPropagation: () => {} } as MouseEvent);
      });

    this.vehicleService.vehicleFlow$
      .pipe(
        map(vehicleTypeQuestion =>
          vehicleTypeQuestion ? vehicleTypeQuestion.question.choices.find(c => c.id === vehicleTypeQuestion.choice_id) : undefined,
        ),
        first(),
        untilDestroyed(this),
      )
      .subscribe(choice => {
        if (choice) {
          const value = vehicleTypeMap[choice.value];
          // This needs to trigger the valueChanges observable on the form control, so don't disable emitting events.
          // This will only be triggered once due to first()
          this.formControl.setValue(value);
        }
      });

    //Match local selection to choice in sdk answer
    combineLatest([this.vehicleService.vehicleFlow$, this.typeOfVehicleQuestion$])
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe(([vehicleFlow, vehicleType]) => {
        if (vehicleType && vehicleFlow) {
          const matchingChoice = vehicleFlow?.question.choices.find(c => c.value === vehicleTypeMap[vehicleType]);
          const sdkStore: IGlobalSDK['store'] | undefined = window?.BpSdk?.store;
          const config = window.BpSdk?.getConfig ? window.BpSdk?.getConfig() : undefined;
          if (matchingChoice && config && sdkStore && vehicleFlow.choice_id !== matchingChoice.id) {
            sdkStore.dispatch(
              updateAnswer({
                answer: { ...vehicleFlow, choice_id: matchingChoice.id },
                config,
              }),
            );
          }
        }
      });

    // Handles the panel expansion state when the component loads or the user is transitioning between the vehicle type
    // section and the additional information section
    combineLatest([
      this.vehicleService.isEditingTypeOfVehicle$,
      this.vehicleService.finishedEditingTypeOfVehicle$,
      this.vehicleService.answersAreValid$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([isEditing, isFinishedEditing, areAnswersValid]) => {
        const isPanelCurrentlyExpanded = this.isPanelExpanded$.value;
        // We check the current state of the panel to prevent the panel from closing as soon as the user
        // completes it. This way the user must click the save button to close the panel even if the criteria for
        // completing the vehicle questions has been met.
        if (isFinishedEditing && !(isEditing || areAnswersValid || isPanelCurrentlyExpanded)) {
          this.isPanelExpanded$.next(true);
        }
      });
  }

  clickEdit(ev: MouseEvent) {
    this.handleEdit(ev);
    this.isPanelExpanded$.next(false);
  }

  onClickSaveButton(): void {
    this.handleClose();
    this.isPanelExpanded$.next(false);
  }

  onExpandPanel() {
    this.isPanelExpanded$.next(true);
  }

  onCollapsePanel() {
    this.isPanelExpanded$.next(false);
  }

  onSaveVehicle() {
    // There is a disconnect between what SDK needs to save a vehicle and what it needs to fetch trailer info
    // "Trailer" is on the answer.question.choice.alias, "Trailers" needs to be sent to saveVehicle in order
    // to send the correct param "type:Trailers".
    if (this.formControl.value != null) {
      const detail =
        this.formControl.value === VehicleEntryQuestions.trailer ? VehicleFlowTypes.trailers : vehicleTypeMap[this.formControl.value];

      this.element.nativeElement.dispatchEvent(
        new CustomEvent('saveVehicle', {
          detail,
          bubbles: true,
        }),
      );
    }
  }
}
