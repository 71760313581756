import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { IHintWithIcon } from '@boldpenguin/emperor-form-fields';
import { Subject } from 'rxjs';
import { AnalyticsTrackingEventName } from '../../models';
import { SdkWrapperModuleConfigService } from '../../service/sdk-wrapper-module-config.service';
import { QuestionFieldType } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-base-component',
  template: '',
})
export class BpSdkBaseComponentComponent implements OnInit, OnChanges, OnDestroy {
  protected _unsubscribe$ = new Subject<void>();
  formControl: FormControl;

  /**
   * Answer id
   */
  @Input() answerId: string;
  /**
   * Question code
   */
  @Input() code: string;
  /**
   * Error text
   */
  @Input() errorText: string | null;
  /**
   * Error messages
   */
  @Input() errors: string[];
  /**
   * Field type
   */
  @Input() fieldType: QuestionFieldType;
  /**
   * Is answer in complete state
   */
  @Input() isCompleted: boolean;
  /**
   * Label
   */
  @Input() label: string | null;
  /**
   * Aria label id's that describes input for screen readers
   */
  @Input() labelId: string | null;
  /**
   * Labels that describe group
   */
  @Input() labelledBy: string | null;
  /**
   * Is pristine
   */
  @Input() pristine: boolean;
  /**
   * Question id
   */
  @Input() questionId: string;
  /**
   * Is question required
   */
  @Input() required: boolean;
  /**
   * is touched
   */
  @Input() touched: boolean;
  /**
   * is readonly
   */
  @Input() isReadOnly: boolean;
  /**
   * How the question was answered (ie "ACORD 126")
   */
  @Input() answeredBySource: string | null;

  hintWithIcon: IHintWithIcon | undefined;

  @HostListener('document:saveQuestionSet')
  onSaveQuestionSet() {
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

  @HostListener('document:validateQuestionSet')
  onValidateQuestionSet() {
    // Persist error. updateValueAndValidity will blow away this error as it's programmatically set.
    let serverResponseError: string | undefined;
    if (this.formControl.hasError('serverResponseError')) {
      serverResponseError = this.formControl.getError('serverResponseError');
    }
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
    if (serverResponseError) {
      this.formControl.setErrors({ serverResponseError });
    }
  }

  @HostListener('document:addressMissingComponents', ['$event'])
  onAddressMissingComponents(event: CustomEvent): void {
    this.voidAddressInputs(event.detail.missing_components);
  }

  constructor(
    public elementRef: ElementRef,
    @Optional()
    private sdkWrapperModuleConfigService: SdkWrapperModuleConfigService,
  ) {
    this.formControl = new FormControl('');
  }

  ngOnInit() {
    this.setupValidators();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetStateIfReused(changes);

    if (changes.required && !changes.required.firstChange) {
      this.setupValidators();
    }

    if (changes.answeredBySource) {
      const currentValue = changes.answeredBySource.currentValue;
      const previousValue = changes.answeredBySource.previousValue;

      // This is an opt-out model, so the consumer has to explicitly configure these to not show
      const shouldDisplayAnswerSource = this.sdkWrapperModuleConfigService?.shouldDisplayAnswerSource !== false;
      const hasAnswerSource = !!currentValue;
      this.hintWithIcon = shouldDisplayAnswerSource && hasAnswerSource ? this.getPrefillIndicator(currentValue) : undefined;

      if (!currentValue && previousValue) {
        this.trackPrefilledFieldChange(previousValue);
      }
    }

    if (changes.errors && !changes.errors.firstChange) {
      if (changes.errors.currentValue.length > 0 && this.formControl.errors === null) {
        this.formControl.setErrors({ serverResponseError: changes.errors.currentValue[0] });
      } else if (changes.errors.currentValue.length === 0 && this.formControl.hasError('serverResponseError')) {
        this.formControl.setErrors({ serverResponseError: null });
        this.formControl.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /**
   * The SDK Web Components, which the Emperor components are passed into,
   * don't trigger rerenders when a new field is rendered. So instead of
   * 100% relying on `ngOnInit`, we must check which input field(s) changed and
   * manually run the lifecycle hooks when necessary.
   */
  protected resetStateIfReused(changes: SimpleChanges) {
    if (this.doesComponentNeedToBeReset(changes)) {
      this.ngOnDestroy();
      this.formControl = new FormControl('');
      this.ngOnInit();
    }
  }

  setupValidators() {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  trackPrefilledFieldChange(previousAnsweredBySource: string | undefined) {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('bpAnalyticsTrack', {
        detail: {
          eventName: AnalyticsTrackingEventName.ModifyPrefilledValue,
          eventPayload: {
            answeredBySource: previousAnsweredBySource,
            code: this.code,
            questionId: this.questionId,
          },
        },
        bubbles: true,
      }),
    );
  }

  /**
   * The browser will reuse components in an effort to be efficient.
   * The component will then need to be manually reset.
   * The questionId and code should not update unless the browser has reused the component
   */
  protected doesComponentNeedToBeReset(changes: SimpleChanges): boolean {
    return (!!changes.questionId && !!changes.questionId.previousValue) || (!!changes.code && !!changes.code.previousValue);
  }

  /**
   * Allows address components to clear their input values
   * if the selected Google Places address is missing
   * that component's required address data
   */
  voidAddressInputs(_missingAddressComponents: string[]): void {
    return;
  }

  clearFormControlValue(): void {
    this.formControl.setValue('', { emitEvent: false });
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

  private getPrefillIndicator(answeredBySource: string): IHintWithIcon {
    return {
      icon: 'prefill_lightning',
      value: `Prefilled from ${answeredBySource}`,
      color: 'var(--emp-accent-500)',
    };
  }
}
