<emperor-text-input
  [min]="min"
  [max]="max"
  [pattern]="pattern || ''"
  [placeholder]="placeholder || ''"
  [label]="label"
  [formControl]="formControl"
  [id]="answerId"
  [type]="(inputType$ | async)!"
  [ariaLabelledBy]="labelledBy"
  [errorText]="errorText"
  [hint]="helpText"
  [hintWithIcon]="hintWithIcon"
  [code]="code"
  [isReadOnly]="isReadOnly"
  [errorMessageMap]="errorMessageMap"
></emperor-text-input>
