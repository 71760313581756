import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEligibleCarrierStatusInfo } from '../../models';

@Component({
  selector: 'emperor-coverage-eligibility-icon',
  templateUrl: './coverage-eligibility-icon.component.html',
  styleUrls: ['./coverage-eligibility-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageEligibilityIconComponent {
  @Input() status: IEligibleCarrierStatusInfo;
}
