import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'emperor-no-quotes',
  templateUrl: './no-quotes.component.html',
  styleUrls: ['./no-quotes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoQuotesComponent {
  @Input() headerText = 'No insurance carriers are available for coverage at this time';
  // TODO: Update the FAQ link
  @Input() helpHtml =
    'For additional support contact us at (855) 656-2774 M-F 9AM to 6PM EST or check out our <a href="#">Frequently Asked Questions</a>.';
}
