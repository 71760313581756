/* eslint-disable no-case-declarations */
import { FormControl, ValidationErrors } from '@angular/forms';
import { QuestionFieldType } from '@boldpenguin/sdk';
import { lightFormat } from 'date-fns';

const errorExistsInErrorObject = (errorMap: object, errorObj: object): string | undefined => {
  const errorMapArray = Object.getOwnPropertyNames(errorMap);
  return Object.getOwnPropertyNames(errorObj).find(error => errorMapArray.some(y => error === y));
};

const createErrorMessageForDates = (minDate: string | null, maxDate: string | null, fieldType: QuestionFieldType | undefined): string => {
  let min: string | undefined;
  let max: string | undefined;
  if(fieldType == QuestionFieldType.MonthYear) {
    min = minDate ? lightFormat(new Date(minDate), 'MM/yyyy') : undefined;
    max = maxDate ? lightFormat(new Date(maxDate), 'MM/yyyy') : undefined;
  } else {
    min = minDate
      ? new Date(minDate).toLocaleDateString('en-US', {
        timeZone: 'UTC',
      })
      : undefined;
    max = maxDate
      ? new Date(maxDate).toLocaleDateString('en-US', {
        timeZone: 'UTC',
      })
      : undefined;
  }
  if (min && max) {
    return fieldType == QuestionFieldType.MonthYear ?
      `Please enter a value between ${min} and ${max}` :
      `Please enter a date between ${min} and ${max}`;
  } else if (min) {
    return `This field needs to be at least ${min}`;
  } else if (max) {
    return `This field shouldn't be after ${max}`;
  }
  return 'This is an invalid date';
};

export const BPErrorState = (control: FormControl) => !!(control && control.invalid && (control.dirty || control.touched));

export const BPErrorMessage = (
  errorObj: ValidationErrors | null | undefined,
  errorText: string | null,
  errorMessageMap: { [index: string]: string } | null,
  minDate: string | null = null,
  maxDate: string | null = null,
  fieldType: QuestionFieldType | undefined = undefined
): string => {
  if (errorText) {
    return errorText;
  }

  if (!errorObj) {
    return '';
  }

  if (errorMessageMap) {
    const errorMapMessageKey = errorExistsInErrorObject(errorMessageMap, errorObj);
    if (errorMapMessageKey) {
      return errorMessageMap[errorMapMessageKey];
    }
  }

  const error = Object.keys(errorObj)[0];

  let errorMessage: string;
  switch (error) {
    case 'required':
      errorMessage = 'This field is required';
      break;
    case 'pattern':
      errorMessage = 'This field has an invalid pattern';
      break;
    case 'matDatepickerMin':
      errorMessage = createErrorMessageForDates(minDate, maxDate, fieldType);
      break;
    case 'matDatepickerMax':
      errorMessage = errorMessage = createErrorMessageForDates(minDate, maxDate, fieldType);
      break;
    case 'min':
      const minAmount = errorObj?.min?.min;
      errorMessage = minAmount || minAmount === 0 ? `This field must be at least ${minAmount}` : 'This field is below the minimal allowed';
      break;
    case 'max':
      const maxAmount = errorObj?.max?.max;
      errorMessage = maxAmount || maxAmount === 0 ? `This field must be below ${maxAmount}` : 'This field is above the maximum allowed';
      break;
    case 'minlength':
      errorMessage = `This field has a minimum character length of ${errorObj?.minlength.requiredLength}`;
      break;
    case 'maxlength':
      errorMessage = `This field has a max character length limit of ${errorObj?.maxlength.requiredLength}`;
      break;
    case 'phone':
      errorMessage = 'Must be a valid phone number';
      break;
    case 'phoneStartsWith1':
      errorMessage = 'Area code cannot start with 1';
      break;
    case 'serverResponseError':
      errorMessage = Object.values(errorObj)[0];
      break;
    default:
      errorMessage = '';
  }
  return errorMessage;
};
