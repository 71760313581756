<label [id]="labelId" [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-increment">
  <emperor-button
    [uiButtonType]="emperorUIButtonTypes.Text"
    [disabled]="control.disabled"
    type="button"
    matIconPrefix
    [ariaLabel]="'subtract from ' + label"
    (click)="subtract()"
  >
    <emperor-icon name="subtract_circle_2_1"></emperor-icon>
  </emperor-button>
  <input
    type="number"
    matInput
    [min]="min ?? ''"
    [max]="max ?? ''"
    [formControl]="control"
    [placeholder]="placeholder || '0'"
    [attr.aria-label]="ariaLabel ? ariaLabel : sanitizedLabelText"
    [attr.aria-labelledby]="emperorInputAriaLabeledBy"
    [attr.data-test]="qaAttributeHook"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [id]="id ?? ''"
  />

  <emperor-button
    [uiButtonType]="emperorUIButtonTypes.Text"
    [disabled]="control.disabled"
    type="button"
    matIconSuffix
    [ariaLabel]="'add to ' + label"
    (click)="add()"
  >
    <emperor-icon name="add_circle_2"></emperor-icon>
  </emperor-button>
  <mat-error emperorErrorHandler [id]="code || ''"></mat-error>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
</mat-form-field>
