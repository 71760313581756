/* eslint-disable no-extra-boolean-cast */
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { IIconPrefix, containsNumericSequence, repeatingNumerals } from '@boldpenguin/emperor-form-fields';
import { IMaskOptions, IMaskedInputComponent, QuestionFieldType } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { InputType } from '../../models/inputTypes.model';
import {
  CurrencyMaskOptions,
  DateMaskOptions,
  FeinMaskOptions,
  PercentageMaskOptions,
  PhoneNumberMaskOptions,
  SsnMaskOptions,
} from '../../models/mask-options';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-masked-input',
  templateUrl: './bp-sdk-masked-input.component.html',
  styleUrls: ['./bp-sdk-masked-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkMaskedInputComponent extends BpSdkBaseComponentComponent implements OnInit, OnChanges, IMaskedInputComponent {
  /**
   * Autocomplete attribute value for input
   */
  @Input() autoComplete: string | null;
  /**
   * 	Whether the text can be hidden
   */
  @Input() canHide = false;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Mask to use
   */
  @Input() mask: string | null;
  /**
   * minimum value
   */
  @Input() min: number | null;
  /**
   * minimum date value
   */
  @Input() minDate: string | null;
  /**
   * maximum value
   */
  @Input() max: number | null;
  /**
   * maximum date value
   */
  @Input() maxDate: string | null;
  /**
   * Options for input mask
   */
  @Input() options: IMaskOptions | null;
  /**
   * Regex pattern to match
   */
  @Input() pattern: string | null;
  /**
   * placeholder
   */
  @Input() placeholder: string;
  /**
   * Whether to use complex mask logic
   */
  @Input() useComplexMaskLogic = false;
  /**
   * Current Value
   */
  @Input() value;

  readonly dateFieldType = QuestionFieldType.Date;
  formattedMask: any;
  specialCharacters: string[] | undefined;
  prefix: string | undefined;
  warning: string | undefined = undefined;
  inputType$ = new BehaviorSubject<InputType>('text');
  inputMode$ = new BehaviorSubject<string>('text');
  placeholderForDOB$ = new BehaviorSubject<string>('');
  errorMessageMap: { [index: string]: string } | null;

  iconPrefix: IIconPrefix;

  private _altPatternByFieldType = {
    fein: '^\\*{5}\\d{4}$',
    ssn: '^\\*{5}\\d{4}$',
  };

  ngOnInit(): void {
    super.ngOnInit();

    this.formattedMask = this.convertSDKMaskToFormattedMask();

    this.iconPrefix = this.determineIconPrefix(this.fieldType);

    this.inputType$.next(this.convertFieldTypeToInputType(this.fieldType as QuestionFieldType));
    this.inputMode$.next(this.convertFieldTypeToInputMode(this.fieldType as QuestionFieldType));

    // If warning is defined on init then it has been reused by the browser
    if (this.warning) {
      this.warning = undefined;
    }

    if (this.fieldType === this.dateFieldType) {
      this.errorMessageMap = {
        matDatepickerParse: 'Invalid Date. Please use format MM/DD/YYYY',
      };

      this.placeholderForDOB$.next(this.placeholderForMaskedDate());
    }

    if(this.fieldType === QuestionFieldType.MonthYear) {
      this.errorMessageMap = {
        matDatepickerParse: 'Please use format MM/YYYY',
      };
    }

    if (this.fieldType === QuestionFieldType.Phone) {
      this.errorMessageMap = {
        pattern: 'Must be a valid phone number',
      };
    }

    this.formControl.valueChanges
      .pipe(startWith(this.value), debounceTime(500), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        if (this.fieldType === 'fein' || this.fieldType === 'ssn') {
          this.warningValidators(value);
        }
        if (this.fieldType === this.dateFieldType && value === '**/**/****') {
          return;
        }
        if (this.fieldType === QuestionFieldType.MonthYear && value === '**/****') {
          return;
        }
        this.elementRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { detail: value, bubbles: true }));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).some(k => k === 'value')) {
      if (this.formControl.value !== this.value) {
        this.formControl.setValue(this.value, { emitEvent: false });
      }
    }

    if (changes.pattern && !changes.pattern.firstChange) {
      this.setupValidators();
    }

    if (changes.mask && !changes.mask.firstChange) {
      this.formattedMask = this.convertSDKMaskToFormattedMask();
    }

    if (changes.fieldType && !changes.fieldType.firstChange) {
      this.formattedMask = this.convertSDKMaskToFormattedMask();
      this.iconPrefix = this.determineIconPrefix(changes.fieldType.currentValue);
      this.inputType$.next(this.convertFieldTypeToInputType(changes.fieldType.currentValue));
      this.inputMode$.next(this.convertFieldTypeToInputMode(changes.fieldType.currentValue));

      if (changes.fieldType.currentValue === this.dateFieldType) {
        this.placeholderForDOB$.next(this.placeholderForMaskedDate());
      }
    }
  }

  setupValidators() {
    const validators: ValidatorFn[] = [];
    const NUMBER_FIELD_TYPES = ['number', 'currency', 'percentage'];
    if (this.required) {
      validators.push(Validators.required);
    }

    if (this.min != null && this.min !== -1) {
      if (NUMBER_FIELD_TYPES.includes(this.fieldType)) {
        validators.push(Validators.min(this.min));
      } else {
        validators.push(Validators.minLength(this.min));
      }
    }

    if (this.max != null && this.max !== -1) {
      if (NUMBER_FIELD_TYPES.includes(this.fieldType)) {
        validators.push(Validators.max(this.max));
      } else {
        validators.push(Validators.maxLength(this.max));
      }
    }

    if (this.pattern) {
      if (this._altPatternByFieldType[this.fieldType]) {
        validators.push(Validators.pattern(`${this.pattern}|${this._altPatternByFieldType[this.fieldType]}`));
      } else {
        validators.push(Validators.pattern(this.pattern));
      }
    }

    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  onFocus() {
    if (this.fieldType === this.dateFieldType) {
      return;
    }

    // In the case that this is answered, the value is represented as '****' last four.
    // '*' characters are a problem for inputs. It'll reset the value to the last four.
    // Just clear the field
    if (!!this.formControl.value && this.formControl.value.indexOf('****') === 0) {
      this.formControl.setValue('');
    }
  }

  private convertSDKMaskToFormattedMask(): IMaskOptions {
    const maskOptions = {
      percentage: PercentageMaskOptions,
      date: DateMaskOptions,
      phone: PhoneNumberMaskOptions,
      currency: CurrencyMaskOptions,
      fein: FeinMaskOptions,
      ssn: SsnMaskOptions,
    };
    return maskOptions[this.fieldType];
  }

  private warningValidators(value: string | null) {
    if (value) {
      if (repeatingNumerals(value) || containsNumericSequence(value)) {
        this.warning = `This looks like an invalid input`;
      } else if (this.warning) {
        this.warning = undefined;
      }
    }
  }

  private determineIconPrefix(fieldType: string): IIconPrefix {
    switch (fieldType) {
      case 'currency':
        return {
          showPrefix: true,
          icon: 'sign_dollar',
        };
      case 'percentage':
        return {
          showPrefix: true,
          icon: 'percent',
        };

      default:
        return {
          showPrefix: false,
          icon: '',
        };
    }
  }

  private convertFieldTypeToInputType(fieldType: QuestionFieldType): InputType {
    switch (fieldType) {
      case QuestionFieldType.Phone:
        return 'tel';
      default:
        return 'text';
    }
  }

  private convertFieldTypeToInputMode(fieldType: QuestionFieldType): string {
    switch (fieldType) {
      case QuestionFieldType.Phone:
        return 'tel';
      case QuestionFieldType.Currency:
      case QuestionFieldType.Number:
      case QuestionFieldType.Percentage:
        return 'decimal';
      default:
        return 'text';
    }
  }

  private placeholderForMaskedDate(): string {
    let placeholder: string;

    if (this.value) {
      placeholder = 'hidden for privacy';
    } else if (this.placeholder) {
      placeholder = this.placeholder;
    } else {
      placeholder = this.formattedMask.mask;
    }

    return placeholder;
  }
}
