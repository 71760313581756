import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './services/authentication.service';
import { SdkStoreService } from './services/sdk-store.service';
import { SelectorWrapperService } from './services/selector-wrapper.service';

export { IGuestFlowApplicationResponse } from './models/guest-flow-response.model';

@NgModule({
  imports: [CommonModule],
  providers: [AuthenticationService, SdkStoreService, SelectorWrapperService],
})
export class ServicesModule {}
