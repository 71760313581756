import { Injectable, Optional } from '@angular/core';
import { GoogleModuleOptions } from '@ng-maps/google';

export class GoogleMapsConfigServiceConfig {
  apiKey: string;
}
@Injectable()
export class GoogleMapsConfigService implements GoogleModuleOptions {
  apiKey: string;
  libraries: string[];

  constructor(@Optional() config: GoogleMapsConfigServiceConfig) {
    this.apiKey = config?.apiKey ?? '';
    this.libraries = ['places'];
  }
}
