import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IQuotesState } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, distinctUntilChanged, map, shareReplay } from 'rxjs';
import { EligibleCarrierStatus, IEligibleCarrierStatusInfo, IEmperorRealTimeEligibilityCarrier } from '../../models';
import { RealTimeEligibilityService } from '../../services/real-time-eligibility.service';

@UntilDestroy()
@Component({
  selector: 'emperor-carrier-product-eligibility',
  templateUrl: './carrier-product-eligibility.component.html',
  styleUrls: ['./carrier-product-eligibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierProductEligibilityComponent implements OnInit {
  @Input() carrier: IEmperorRealTimeEligibilityCarrier;
  @Input() products: string[] = [];
  @Input() quote: IQuotesState | undefined;
  @Input() isQuestionSetCompleted = false;
  @Input() bulkAddCarrierQuestionSetsEnabled: boolean;
  @Input() useRecommendedCarriersEnabled: boolean;
  @Output() goToQuote = new EventEmitter<IQuotesState>();

  quoteStatus$: Observable<IEligibleCarrierStatusInfo | null>;
  isCarrierQuoteFetching$: Observable<boolean>;
  isInteractive$: Observable<boolean>;
  showPrice$: Observable<boolean>;

  constructor(readonly realTimeEligibilityService: RealTimeEligibilityService) {}

  get productName(): string {
    if (this.products.length === 1) {
      return this.products[0];
    } else if (this.products.length > 1) {
      return 'Bundle';
    }
    return '';
  }

  get showTooltip(): boolean {
    return this.isBundle || this.isOffPlatform;
  }

  get isBundle(): boolean {
    return !!this.quote && this.quote.products.length > 1;
  }

  get isOffPlatform(): boolean {
    return !!this.quote && !this.quote?.online;
  }

  get offPlatformTooltip(): string {
    return `This risk may be available via ${this.carrier.name}`;
  }

  ngOnInit(): void {
    this.setupObservables();
  }

  handleProductClick(): void {
    if (this.bulkAddCarrierQuestionSetsEnabled) {
      this.goToQuote.emit(this.quote);
    }
  }

  private setupObservables(): void {
    this.quoteStatus$ = this.realTimeEligibilityService
      .getCarrierProductStatus$(
        this.quote,
        this.carrier?.id,
        this.useRecommendedCarriersEnabled,
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));

    this.showPrice$ = this.quoteStatus$.pipe(
      map(
        info =>
          info?.status === EligibleCarrierStatus.Success ||
          info?.status === EligibleCarrierStatus.Preferred ||
          info?.status === EligibleCarrierStatus.OffPlatform,
      ),
      untilDestroyed(this),
    );

    this.isInteractive$ = this.quoteStatus$.pipe(
      map(
        (info) =>
          info?.status === EligibleCarrierStatus.OffPlatform ||
          this.canShowOnlineQuote(info?.status),
      ),
      distinctUntilChanged(),
      untilDestroyed(this),
    );

    this.isCarrierQuoteFetching$ = this.realTimeEligibilityService.carrierIsFetchingQuote$(this.carrier.id).pipe(untilDestroyed(this));
  }

  private canShowOnlineQuote(
    status: EligibleCarrierStatus | undefined,
  ): boolean {
    return (
      !!status &&
      (status === EligibleCarrierStatus.Success ||
        status === EligibleCarrierStatus.Preferred ||
        status === EligibleCarrierStatus.Warning) &&
      this.isQuestionSetCompleted &&
      this.bulkAddCarrierQuestionSetsEnabled
    );
  }
}
