export enum TimelineStepStatus {
  NOT_STARTED = 'not started',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
}
export interface ITimelineStep {
  status: TimelineStepStatus;
  label: string;
  disabled?: boolean;
  subtasks?: ITimelineStep[];
  callback?: () => void;
}
