<div class="emperor-driver-panel-container">
  <mat-accordion [id]="driverService.questionGroupId$ | async">
    <mat-expansion-panel
      #expansionPanel
      class="primary-accordion"
      [expanded]="isPanelExpanded$ | async"
      [disabled]="(driverService.answersAreValid$ | async) === false"
      hideToggle="true"
      (opened)="panelOpen()"
      (closed)="panelClose()"
    >
      <mat-expansion-panel-header class="emperor-driver-header-content">
        <mat-panel-title>
          <div class="emperor-driver-label-container">
            <div class="emperor-driver-main-label">
              <h3>
                {{ driverService.driverName$ | async }}
              </h3>
              <emperor-button [uiButtonType]="uiButtonType.Text" (click)="expansionPanel.open()" *ngIf="(isPanelExpanded$ | async) === false">
                <span> EDIT</span><emperor-icon name="pencil_3_1" class="pencil-icon"></emperor-icon>
              </emperor-button>
            </div>

            <div
              *ngIf="(isPrefilled$ | async) && (isPanelExpanded$ | async) === false"
              class="emperor-driver-prefill-container"
            >
              <emperor-icon name="prefill_lightning"></emperor-icon>
              <p>This driver entry is prefilled</p>
            </div>
          </div>

        </mat-panel-title>

        <mat-panel-description>
          <div class="emperor-driver-icon-wrapper">
            <emperor-icon
              class="completed-icon"
              name="check_circle_2_2"
              *ngIf="(driverService.answersAreValid$ | async)"
            ></emperor-icon>
          </div>
          <h4 class="expanded-text-header">
            {{ (isPanelExpanded$ | async) ? 'HIDE' : 'SHOW' }}
          </h4>
          <div class="emperor-ui-accordion-toggle-container" [ngClass]="{ expanded: isPanelExpanded$ | async }">
            <emperor-icon name="keyboard_arrow_up"></emperor-icon>
            <emperor-icon name="keyboard_arrow_down"></emperor-icon>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <bp-sdk-dynamic-input-list
        *ngIf="(isPanelExpanded$ | async) === true"
        [answers]="driverService.answers$ | async"
      ></bp-sdk-dynamic-input-list>

      <div class="emperor-driver-panel-buttons">
        <emperor-button class="delete-driver-button" [uiButtonType]="uiButtonType.Stroked" [disabled]="!canDelete" (click)="handleDelete()"
          >Delete</emperor-button
        >
        <emperor-button
          data-testid="driver-save-btn"
          [disabled]="(driverService.answersAreValid$ | async) === false"
          (disabledClick)="onDisabledClick()"
          (click)="panelClose()"
          >Save Info</emperor-button
        >
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
