<div class="emperor-ui-timeline-item" [class.emperor-ui-not-started]="step.status === TimelineStepStatus.NOT_STARTED">
  <!-- Vertical line connecting to other steps -->
  <div *ngIf="order > 0" class="emperor-ui-vertical-line" [class.emperor-ui-step-disabled]="disabled"></div>

  <!-- Step button -->
  <emperor-button [uiButtonType]="EmperorUIButtonTypes.Text" class="emperor-ui-step" [disabled]="disabled" (click)="stepClicked()">
    <div class="emperor-ui-step-button-content">
      <emperor-icon *ngIf="step.status === TimelineStepStatus.COMPLETED; else stepNumber" name="check_circle_2_1"></emperor-icon>
      <span class="emperor-ui-label">{{ step.label }}</span>
    </div>
  </emperor-button>

  <!-- Subtasks -->
  <div
    *ngIf="step && step.status === TimelineStepStatus.IN_PROGRESS && step.subtasks && step.subtasks.length > 0"
    class="emperor-ui-subtasks"
  >
    <ng-container *ngFor="let subtask of step.subtasks; let i = index">
      <emperor-timeline-task [step]="subtask" [order]="i"></emperor-timeline-task>
    </ng-container>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #stepNumber>
  <span class="emperor-ui-step-number" [class.emperor-ui-not-started]="step.status === TimelineStepStatus.NOT_STARTED">{{
    order + 1
  }}</span>
</ng-template>
