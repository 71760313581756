import { Directive, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { InputDirective } from './input.directive';

// This is used to fix an issue where material inputs are not disabled when wrapped with <fieldset disabled>
// https://github.com/angular/angular/issues/35309
@Directive({
  selector: '[emperorInputFieldsetDisable]',
})
export class InputFieldsetDisableDirective extends InputDirective implements OnInit, OnDestroy {
  private readonly disabledAttributeName = 'disabled';
  private mutationObserver: MutationObserver | undefined;

  constructor(injector: Injector, sanitizer: DomSanitizer, private readonly elementRef: ElementRef) {
    super(injector, sanitizer);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const fieldsetElement: Element = this.elementRef.nativeElement.closest('fieldset');

    if (fieldsetElement) {
      this.registerFieldsetObserver(fieldsetElement);
      this.toggleFieldsetControl(fieldsetElement);
    }
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private registerFieldsetObserver(fieldsetElement: Element): void {
    this.mutationObserver = new MutationObserver(mutations => this.handleMutations(mutations));

    this.mutationObserver.observe(fieldsetElement, {
      attributes: true,
      childList: false,
      characterData: false,
      attributeFilter: [this.disabledAttributeName],
    });
  }

  private handleMutations(mutations: MutationRecord[]): void {
    mutations.forEach(mutation => {
      const element = mutation.target as Element;
      this.toggleFieldsetControl(element);
    });
  }

  private toggleFieldsetControl(element: Element): void {
    const currentState = (this.getControl() as FormControl).disabled;
    if (element.attributes.getNamedItem(this.disabledAttributeName)) {
      if (!currentState) {
        (this.getControl() as FormControl).disable();
      }
    } else {
      if (currentState && !this.isReadOnly) {
        (this.getControl() as FormControl).enable();
      }
    }
  }
}
