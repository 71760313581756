/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationRef, ComponentRef, Injectable, Injector, Type, createComponent } from '@angular/core';
import { DynamicDialogComponent } from '../components/dynamic-dialog/dynamic-dialog.component';
import { EmperorDialogInjector } from '../dialog-injector';
import { EmperorDialogRef } from '../dialog-ref';
import { EmperorDialogSize } from '../models/dialog-size.model';
import { EmperorThemeColor } from '../../models';

export class EmperorDialogConfig<D = any> {
  data?: D;
  color?: EmperorThemeColor;
  uiDialogSize?: EmperorDialogSize;
  backdropDismiss?: boolean;
  ariaLabelledBy?: string;
  ariaDescribeby?: string;
  forceToFront?: boolean;
  /* NOTE: forceToFront is to be used only for competing open dialogs.
  This does not work for dialogs with form fields that contain menus (selects, date pickers, etc)*/
}

@Injectable({
  providedIn: 'root',
})
export class EmperorDialogRefService {
  private componentRef: ComponentRef<DynamicDialogComponent>;
  constructor(private appRef: ApplicationRef, private injector: Injector) {}

  open(componentType: Type<any>, config?: EmperorDialogConfig): EmperorDialogRef {
    const ref = this.createDialogComponent(config);
    this.componentRef.instance.childComponentType = componentType;
    return ref;
  }

  close() {
    this.removeDialogComponentFromBody();
  }

  private createDialogComponent(config?: EmperorDialogConfig): EmperorDialogRef {
    const map = new WeakMap();
    if (config) {
      map.set(EmperorDialogConfig, config);
    }

    const dialogRef = new EmperorDialogRef();
    map.set(EmperorDialogRef, dialogRef);
    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });
    const environmentInjector = this.appRef.injector;

    const hostElement = document.body.appendChild(document.createElement('emperor-dialog-host-element'));

    this.componentRef = createComponent(DynamicDialogComponent, {
      hostElement,
      environmentInjector,
      elementInjector: new EmperorDialogInjector(this.injector, map),
    });

    this.appRef.attachView(this.componentRef.hostView);
    return dialogRef;
  }

  private removeDialogComponentFromBody() {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }
}
