import { Component, Input } from '@angular/core';
import { ITimelineStep } from '../../models/timeline.model';

@Component({
  selector: 'emperor-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {
  @Input() steps: ITimelineStep[] = [];

  stepClicked(step: ITimelineStep) {
    if (step?.callback && !step.disabled) {
      step.callback();
    }
  }
}
