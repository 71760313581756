<div
  class="emperor-ui-vertical-line emperor-ui-subtask-vertical-line"
  [class.emperor-ui-first]="order === 0"
  [class.emperor-ui-not-started]="step.status === TimelineStepStatus.NOT_STARTED"
></div>
<emperor-button
  [uiButtonType]="EmperorUIButtonTypes.Text"
  class="emperor-ui-step emperor-ui-subtask"
  [class.emperor-ui-not-started]="step.status === TimelineStepStatus.NOT_STARTED"
  (click)="stepClicked()"
  [disabled]="disabled"
>
<div class="emperor-ui-step-button-content">
  <div class="emperor-ui-subtask-bubble"></div>
  <span [emperorTooltip]="step.label" class="emperor-ui-label">{{ step.label }}</span>
</div>
</emperor-button>
