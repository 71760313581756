import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPrice',
})
export class DisplayPricePipe implements PipeTransform {
  transform(value: string | undefined, type: 'dollars' | 'cents'): string {
    if (!value) {
      return '';
    } else if (type === 'cents') {
      const cents: string | undefined = value.split('.')[1];
      return !cents ? '00' : `${cents}00`.slice(0, 2);
    } else {
      return value.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
}
