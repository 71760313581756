import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { shouldCoverageLimitAccordionsBeExpanded } from '@boldpenguin/emperor-common';
import { ICoverageGroupComponent, IMappedQuestionGroupAnswers } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-coverage-group',
  templateUrl: './bp-sdk-coverage-group.component.html',
  styleUrls: ['./bp-sdk-coverage-group.component.scss'],
})
export class BpSdkCoverageGroupComponent implements ICoverageGroupComponent, OnChanges {
  @Input() questionGroup: IMappedQuestionGroupAnswers;
  startPanelExpanded = false;
  private _startPanelChecked = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionGroup && !!changes.questionGroup.currentValue && !this._startPanelChecked) {
      this.updateStartPanelExpanded(changes.questionGroup.currentValue);
    }
  }

  private updateStartPanelExpanded(questionGroup: IMappedQuestionGroupAnswers) {
    this._startPanelChecked = true;
    this.startPanelExpanded = shouldCoverageLimitAccordionsBeExpanded(questionGroup.parentQuestionAnswer.application_form_id);
  }
}
