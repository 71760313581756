import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'emperor-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorCardContentComponent {
  @HostBinding('class.emperor-ui-card-content-scrollable') @Input() scrollable = false;
}
