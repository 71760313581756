<button
  matRipple
  [matRippleDisabled]="!!disabled"
  [class]="'emperor-ui-nav-button nav-button-ripple mat-' + color"
  [class.emperor-ui-hide-bg]="!highlight || disabled"
  [type]="type"
  [id]="id || ''"
  [ngClass]="{ 'emperor-ui-button-disabled': disabled }"
  [attr.aria-disabled]="disabled"
  [attr.aria-label]="ariaLabel"
  (click)="onButtonClick($event)"
>
  <ng-content select="emperor-icon:not(.icon-suffix)"></ng-content>
  <ng-content></ng-content>

  <div class="emperor-ui-chip-container">
    <ng-content select="emperor-chip"></ng-content>
  </div>

  <ng-content select=".icon-suffix"></ng-content>
</button>
