import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  EmperorButtonModule,
  EmperorCardModule,
  EmperorDialogModule,
  EmperorDialogRefService,
  EmperorSpinnerModule,
  PresentationalModule,
} from '@boldpenguin/emperor-presentational';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { FormFieldsModule } from '@boldpenguin/emperor-form-fields';
import { DialInMessageDialogComponent } from './components/dial-in-message-dialog/dial-in-message-dialog.component';

@NgModule({
  declarations: [DialInMessageDialogComponent],
  imports: [
    CommonModule,
    EmperorButtonModule,
    EmperorSpinnerModule,
    EmperorCardModule,
    EmperorDialogModule,
    PresentationalModule,
    IconsModule,
    FormFieldsModule,
  ],
  exports: [DialInMessageDialogComponent],
  providers: [EmperorDialogRefService],
})
export class DialInMessageModule {}
