import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ITextInputComponent, QuestionFieldType } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { InputType } from '../../models/inputTypes.model';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-text-input',
  templateUrl: './bp-sdk-text-input.component.html',
  styleUrls: ['./bp-sdk-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkTextInputComponent extends BpSdkBaseComponentComponent implements OnInit, OnChanges, ITextInputComponent {
  /**
   * Autocomplete attribute value for input
   */
  @Input() autoComplete: string | null;
  /**
   * Is enabled
   */
  @Input() isEnabled: boolean;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * maximum length of input
   */
  @Input() max = -1;
  /**
   * minimum length of input
   */
  @Input() min = -1;
  /**
   * Regular expression pattern input should match
   */
  @Input() pattern: string | null;
  /**
   * Placeholder to display
   */
  @Input() placeholder = '';
  /**
   * Initial text value
   */
  @Input() text: string | null;

  inputType$ = new BehaviorSubject<InputType>('text');
  errorMessageMap: { [index: string]: string } | null;

  ngOnInit(): void {
    super.ngOnInit();

    this.inputType$.next(this.convertFieldTypeToInputType(this.fieldType as QuestionFieldType));

    this.formControl.valueChanges
      .pipe(startWith(this.text), distinctUntilChanged(), untilDestroyed(this))

      .subscribe(detail => {
        this.elementRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { detail, bubbles: true }));
      });

    if (this.fieldType === QuestionFieldType.Email) {
      this.errorMessageMap = {
        pattern: 'Must be a valid email address',
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).some(k => k === 'text')) {
      if (this.formControl.value !== this.text) {
        this.formControl.setValue(changes.text.currentValue, {
          emitEvent: false,
        });
      }
    }

    if (changes.fieldType && !changes.fieldType.firstChange) {
      this.inputType$.next(this.convertFieldTypeToInputType(changes.fieldType.currentValue));
    }

    if (
      Object.keys(changes).some(k => k === 'min') ||
      Object.keys(changes).some(k => k === 'max') ||
      Object.keys(changes).some(k => k === 'pattern')
    ) {
      this.setupValidators();
    }
  }

  setupValidators() {
    const NUMBER_FIELD_TYPES = ['number', 'currency', 'percentage'];
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }

    if (this.min && this.min > -1) {
      if (NUMBER_FIELD_TYPES.includes(this.fieldType)) {
        validators.push(Validators.min(this.min));
      } else {
        validators.push(Validators.minLength(this.min));
      }
    }

    if (this.max && this.max > -1) {
      if (NUMBER_FIELD_TYPES.includes(this.fieldType)) {
        validators.push(Validators.max(this.max));
      } else {
        validators.push(Validators.maxLength(this.max));
      }
    }

    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    if (this.fieldType === QuestionFieldType.VIN) {
      const vinValidator = (): ValidationErrors | null => {
        return this.errorText == null ? null : { vin: this.errorText };
      };
      validators.push(vinValidator);
    }

    this.formControl.setValidators(validators);
  }

  private convertFieldTypeToInputType(fieldType: QuestionFieldType): InputType {
    switch (fieldType) {
      case QuestionFieldType.Currency:
        return 'number';
      case QuestionFieldType.Email:
        return 'email';
      case QuestionFieldType.Number:
        return 'number';
      case QuestionFieldType.Phone:
        return 'tel';
      case QuestionFieldType.Text:
        return 'text';
      default:
        return 'text';
    }
  }
}
