import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmperorDialogComponent } from './dialog.component';
import { EmperorCardModule } from '../card';
import { EmperorDialogCloseDirective } from './directives/dialog-close.directive';
import { DialogInsertionDirective } from './directives/dialog-insertion.directive';
import { DynamicDialogComponent } from './components/dynamic-dialog/dynamic-dialog.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { EmperorDialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { IconsModule } from '@boldpenguin/emperor-icons';

export { EmperorDialogCloseDirective } from './directives/dialog-close.directive';
export { EmperorDialogComponent } from './dialog.component';
export { EmperorDialogHeaderComponent } from './components/dialog-header/dialog-header.component';

@NgModule({
  declarations: [EmperorDialogComponent, EmperorDialogCloseDirective, DynamicDialogComponent, DialogInsertionDirective, EmperorDialogHeaderComponent],
  imports: [CommonModule, EmperorCardModule, OverlayModule, A11yModule, IconsModule],
  exports: [EmperorDialogCloseDirective, EmperorDialogComponent, EmperorDialogHeaderComponent],
})
export class EmperorDialogModule {}
