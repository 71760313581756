export enum EmperorUIAlertType {
  Info = 'info',
  Warn = 'warn',
  Success = 'success',
  Fail = 'fail',
}

export const EmperorAlertIconMap = {
  [EmperorUIAlertType.Info]: 'interface_information_1',
  [EmperorUIAlertType.Warn]: 'interface_alert_circle_1',
  [EmperorUIAlertType.Fail]: 'interface_alert_circle_1',
  [EmperorUIAlertType.Success]: 'check_circle_2_1',
};
