import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IBusinessClassificationSelectorListComponent, IChoice } from '@boldpenguin/sdk';

/**
 * Not exported from sdk
 */
interface IGroupedChoicesConfig {
  maxResults: number;
}

@Component({
  selector: 'emperor-bp-sdk-slim-business-class-selector-list',
  templateUrl: './bp-sdk-slim-business-class-selector-list.component.html',
  styleUrls: ['./bp-sdk-slim-business-class-selector-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
/**
 * Not replaced by default, can be used with slim BCS in consuming app
 */
export class BpSdkSlimBusinessClassSelectorListComponent implements IBusinessClassificationSelectorListComponent, OnChanges {
  @Input()
  choiceId: string;

  @Input()
  choices: IChoice[];

  @Input()
  showConfidence: boolean;

  @Input()
  showNaicsCode: boolean;

  @Input()
  groupedChoicesConfig: IGroupedChoicesConfig;

  visibleChoices: IChoice[];
  bestChoice?: string;

  constructor(private elRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.choices || changes.groupedChoicesConfig) {
      this.visibleChoices = this.choices.slice(0, this.groupedChoicesConfig?.maxResults || this.choices.length);

      this.bestChoice = undefined;
      let highestScore = 0;
      for (const ch of this.visibleChoices) {
        if (ch.score && ch.score > highestScore) {
          highestScore = ch.score;
          this.bestChoice = ch.id;
        }
      }
    }
  }

  clickChoice(choice: IChoice) {
    //component receiving this event is expecting an array
    this.elRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { bubbles: true, detail: [choice.id] }));
  }
}
