import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ButtonComponent } from './components/button/button.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { EmperorTooltipModule } from '../tooltip';

export * from './components/button/button.component';
export * from './components/link-button/link-button.component';
@NgModule({
  imports: [CommonModule, MatButtonModule, EmperorTooltipModule, ],
  declarations: [ButtonComponent, LinkButtonComponent],
  exports: [ButtonComponent, LinkButtonComponent],
})
export class EmperorButtonModule {}
