import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { PresentationalModule } from '@boldpenguin/emperor-presentational';

// 3rd Party
import { IMaskModule } from 'angular-imask';
import { MaterialModule } from './material.module';
import { NgMapsGoogleModule, GOOGLE_MAPS_API_CONFIG } from '@ng-maps/google';

import { ErrorHandlerDirective } from './directive/error-handler.directive';

import { AddItemComponent } from './components/add-item/add-item.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BooleanInputComponent } from './components/boolean-input/boolean-input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { GoogleAutocompleteInputComponent } from './components/google-autocomplete-input/google-autocomplete-input.component';
import { IncrementInputComponent } from './components/increment-input/increment-input.component';
import { MaskedInputComponent } from './components/masked-input/masked-input.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SelectComponent } from './components/select/select.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { InputFieldsetDisableDirective } from './directive/input-fieldset-disable.directive';
import { HintWithIconComponent } from './components/hint-with-icon/hint-with-icon.component';
import { OnOffInputComponent } from './components/on-off-input/on-off-input.component';
import { SearchableListComponent } from './components/searchable-list/searchable-list.component';
import { SearchableListDialogComponent } from './components/searchable-list-dialog/searchable-list-dialog.component';
import { TextareaInputComponent } from './components/textarea-input/textarea-input.component';
import { MonthYearInputComponent } from './components/month-year-input/month-year-input.component';

import { GoogleMapsConfigService, GoogleMapsConfigServiceConfig } from './services/google-maps-config.service';

import { EmperorGoogleMapsAutocompleteModule } from './google-maps-autocomplete/google-maps-autocomplete.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export { ErrorHandlerDirective } from './directive/error-handler.directive';
export { InputDirective } from './directive/input.directive';

export { AddItemComponent } from './components/add-item/add-item.component';
export { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
export { BooleanInputComponent } from './components/boolean-input/boolean-input.component';
export { CheckboxComponent } from './components/checkbox/checkbox.component';
export { DateInputComponent } from './components/date-input/date-input.component';
export { EmperorUISelectFormChoice } from './models/selectable-choices.model';
export { GoogleAutocompleteInputComponent } from './components/google-autocomplete-input/google-autocomplete-input.component';
export { IncrementInputComponent } from './components/increment-input/increment-input.component';
export { MaskedInputComponent } from './components/masked-input/masked-input.component';
export { RadioButtonComponent } from './components/radio-button/radio-button.component';
export { SelectComponent } from './components/select/select.component';
export { TextInputComponent } from './components/text-input/text-input.component';
export { HintWithIconComponent } from './components/hint-with-icon/hint-with-icon.component';
export { OnOffInputComponent } from './components/on-off-input/on-off-input.component';
export { SearchableListComponent } from './components/searchable-list/searchable-list.component';
export { SearchableListDialogComponent } from './components/searchable-list-dialog/searchable-list-dialog.component';
export { TextareaInputComponent } from './components/textarea-input/textarea-input.component';
export { MonthYearInputComponent } from './components/month-year-input/month-year-input.component';

export {
  EmperorGoogleMapsAutocompleteModule,
  EmperorGoogleMapsAutocompleteDirective,
} from './google-maps-autocomplete/google-maps-autocomplete.module';
import { matAutocompleteScrollStrategyProvider, matSelectScrollStrategyProvider } from './services/scroll-strategy-providers.service';

export { updateFormTreeValidity } from './utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgMapsGoogleModule,
    IconsModule,
    PresentationalModule,
    MaterialModule,
    IMaskModule,
    EmperorGoogleMapsAutocompleteModule,
  ],
  declarations: [
    InputFieldsetDisableDirective,
    TextInputComponent,
    TextareaInputComponent,
    ErrorHandlerDirective,
    AutocompleteComponent,
    AddItemComponent,
    CheckboxComponent,
    DateInputComponent,
    IncrementInputComponent,
    RadioButtonComponent,
    SelectComponent,
    BooleanInputComponent,
    MaskedInputComponent,
    GoogleAutocompleteInputComponent,
    OnOffInputComponent,
    HintWithIconComponent,
    SearchableListComponent,
    SearchableListDialogComponent,
    MonthYearInputComponent,
  ],
  exports: [
    TextInputComponent,
    TextareaInputComponent,
    ErrorHandlerDirective,
    AutocompleteComponent,
    AddItemComponent,
    CheckboxComponent,
    DateInputComponent,
    IncrementInputComponent,
    RadioButtonComponent,
    SelectComponent,
    BooleanInputComponent,
    MaskedInputComponent,
    GoogleAutocompleteInputComponent,
    OnOffInputComponent,
    HintWithIconComponent,
    SearchableListComponent,
    SearchableListDialogComponent,
    MonthYearInputComponent,
  ],
  providers: [
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useClass: GoogleMapsConfigService,
      deps: [GoogleMapsConfigServiceConfig],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', hideRequiredMarker: true, subscriptSizing: 'dynamic' },
    },
    matAutocompleteScrollStrategyProvider,
    matSelectScrollStrategyProvider,
  ],
})
export class FormFieldsModule {
  static forRoot(config: GoogleMapsConfigServiceConfig): ModuleWithProviders<FormFieldsModule> {
    return {
      ngModule: FormFieldsModule,
      providers: [{ provide: GoogleMapsConfigServiceConfig, useValue: config }],
    };
  }
}
