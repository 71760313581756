import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EmperorThemeColor, EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';

@Component({
  selector: 'emperor-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddItemComponent {
  @Input() text: string;
  @Input() icon: string;
  @Input() helpText: string | undefined;
  @Input() canAddMore: boolean;
  @Input() loadingSpinner: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() addItem = new EventEmitter();

  EmperorUIButtonTypes = EmperorUIButtonTypes;
  EmperorThemeColor = EmperorThemeColor;

  addItemRequested() {
    this.addItem.emit();
  }
}
