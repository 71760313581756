import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { IIncrementInputComponent, QuestionReferenceType } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-increment-input',
  templateUrl: './bp-sdk-increment-input.component.html',
  styleUrls: ['./bp-sdk-increment-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkIncrementInputComponent extends BpSdkBaseComponentComponent implements OnInit, OnChanges, IIncrementInputComponent {
  /**
   * Autocomplete attribute value for input
   */
  @Input() autoComplete: string | undefined;
  /**
   * Is enabled
   */
  @Input() isEnabled: boolean;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * maximum value
   */
  @Input() max = -1;
  /**
   * minimum value
   */
  @Input() min = -1;
  /**
   * Regular expression pattern input should match
   */
  @Input() pattern: string | null;
  /**
   * Placeholder to display
   */
  @Input() placeholder = '';
  /**
   * Initial text value
   */
  @Input() text: string;
  /**
   * Reference type
   */
  @Input() referenceType: QuestionReferenceType | null;
  /**
   * Number of characters of the input value
   */
  inputLength: number;

  ngOnInit(): void {
    super.ngOnInit();

    // Add a css class for targeting specific styles
    const questionGroup: Element | undefined = this.elementRef.nativeElement.closest('.bp-sdk-question-group-content');
    if (questionGroup && !questionGroup.classList.contains('emperor-ui-increment-question-group')) {
      questionGroup.classList.add('emperor-ui-increment-question-group');
    }

    this.formControl.valueChanges
      .pipe(
        startWith(this.text),
        map(value => value?.toString() ?? ''),
        distinctUntilChanged(),
        untilDestroyed(this),
      )

      .subscribe(detail => {
        this.elementRef.nativeElement.dispatchEvent(new CustomEvent('valueUpdate', { detail, bubbles: true }));
        this.inputLength = detail.length;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).some(k => k === 'text')) {
      if (this.formControl.value !== changes.text.currentValue) {
        this.formControl.setValue(changes.text.currentValue, {
          emitEvent: false,
        });
      }
    }

    if (
      Object.keys(changes).some(k => k === 'min') ||
      Object.keys(changes).some(k => k === 'max') ||
      Object.keys(changes).some(k => k === 'pattern')
    ) {
      this.setupValidators();
    }
  }

  setupValidators() {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }

    if (this.min && this.min > -1) {
      validators.push(Validators.min(this.min));
    }

    if (this.max && this.max > -1) {
      validators.push(Validators.max(this.max));
    }

    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    this.formControl.setValidators(validators);
  }
}
