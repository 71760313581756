import { Component, ElementRef, Input } from '@angular/core';
import { IChoice, IParentQuestionPoolSelectorComponent } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-parent-question-pool-selector',
  templateUrl: './bp-sdk-parent-question-pool-selector.component.html',
  styleUrls: ['./bp-sdk-parent-question-pool-selector.component.scss'],
})
export class BpSdkParentQuestionPoolSelectorComponent implements IParentQuestionPoolSelectorComponent {
  @Input() choices: IChoice[];
  @Input() label: string;
  selectedChoiceId: string | undefined;

  constructor(private element: ElementRef) {}

  onValueUpdate(event) {
    event.stopPropagation();
    this.selectedChoiceId = event.detail[0];
  }

  submit() {
    if (this.selectedChoiceId) {
      this.element.nativeElement.dispatchEvent(new CustomEvent('selectedAnswerPoolId', { detail: this.selectedChoiceId, bubbles: true }));
    }
  }
}
