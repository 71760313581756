import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export enum EmperorCardTypes {
  Outlined = 'outlined',
  Elevated = 'elevated',
}

@Component({
  selector: 'emperor-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorCardComponent {
  @Input() type = EmperorCardTypes.Elevated;

  @HostBinding('class.emperor-ui-card') readonly className = true;
  @HostBinding(`class.emperor-ui-card-elevated`) get elevated() {
    return this.type === EmperorCardTypes.Elevated;
  }
  @HostBinding(`class.emperor-ui-card-outlined`) get outlined() {
    return this.type === EmperorCardTypes.Outlined;
  }
}
