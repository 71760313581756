import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CardElementBaseDirective } from '../../directives/card-element-base.directive';

@Component({
  selector: 'emperor-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmperorCardFooterComponent extends CardElementBaseDirective {}
