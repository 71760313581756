import { IQuotesState, ITranslatedMessage, QuoteRequestRequestTypes, QuotesState } from '@boldpenguin/sdk';
import { CarrierProductStatus, EligibleCarrierStatus, IEligibleCarrierStatusInfo } from './eligible-carriers';

export enum QuoteProductBundleNames {
  BUNDLES = 'Bundles',
  ADMITTED = 'Admitted markets',
}

export type GroupedOnlineQuotes = {
  [key: string]: typeof QuotesState;
};

export const QuoteRequestNotOptedIn = 'not_opted_in' as QuoteRequestRequestTypes;

export const QuoteRequestIncompleteQuestionSet = 'incomplete_question_set' as QuoteRequestRequestTypes;

export const isQuoteSuccessful = (quote: IQuotesState): boolean =>
  !isQuoteErrored(quote) && !isQuotePending(quote) && !isQuoteNotOptedIn(quote) && !isQuoteQuestionSetIncomplete(quote);

export const isQuoteErrored = (quote: IQuotesState): boolean =>
  quote.request_status === QuoteRequestRequestTypes.failed ||
  quote.request_status === QuoteRequestRequestTypes.ineligible ||
  quote.request_status === QuoteRequestRequestTypes.timed_out;

export const isQuotePending = (quote: IQuotesState): boolean =>
  quote.request_status === QuoteRequestRequestTypes.unsent ||
  quote.request_status === QuoteRequestRequestTypes.sent ||
  quote.request_status === QuoteRequestRequestTypes.in_progress;

export const isQuoteNotOptedIn = (quote: IQuotesState): boolean => quote.request_status === QuoteRequestNotOptedIn;

export const isQuoteQuestionSetIncomplete = (quote: IQuotesState): boolean => quote.request_status === QuoteRequestIncompleteQuestionSet;

export const isQuoteIncomplete = (quote: IQuotesState): boolean => isQuoteNotOptedIn(quote) || isQuoteQuestionSetIncomplete(quote);

export const isQuoteOffPlatform = (quote: IQuotesState): boolean =>
  !quote.online && quote.request_status === QuoteRequestRequestTypes.referral;

export const isQuotePreferred = (quote: IQuotesState): boolean => !!quote?.preferred;

export const sortQuotes = (quotes: typeof QuotesState): typeof QuotesState => {
  if (!quotes || quotes.length === 0) {
    return quotes;
  }

  return [...quotes]
    .map(quote => ({
      ...quote,
      order: quote.order === null ? quotes.length : quote.order,
    }))
    .sort((quote1, quote2) => {
      if (quote1.order === quote2.order) {
        if (quote1.carrier.name.toLocaleLowerCase() < quote2.carrier.name.toLocaleLowerCase()) {
          return -1;
        }
        if (quote1.carrier.name.toLocaleLowerCase() > quote2.carrier.name.toLocaleLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        return quote1.order - quote2.order;
      }
    });
};



export const getQuoteStatus = (
  quote: IQuotesState,
  hasPreferredQuotesEnabled: boolean,
  isQuestionSetPreferred = false,
): IEligibleCarrierStatusInfo | null => {
  if (!quote.request_status) {
    return null;
  } else if (!quote.online) {
    return CarrierProductStatus[EligibleCarrierStatus.OffPlatform];
  } else if (isQuoteSuccessful(quote)) {
    // If the carrier is marked as preferred, change the success status
    return (isQuotePreferred(quote) || isQuestionSetPreferred) &&
      hasPreferredQuotesEnabled
      ? CarrierProductStatus[EligibleCarrierStatus.Preferred]
      : CarrierProductStatus[EligibleCarrierStatus.Success];
  } else if (isQuoteErrored(quote)) {
    return {
      ...CarrierProductStatus[EligibleCarrierStatus.Warning],
      statusMessage: displayQuoteErrorMessages(quote.translated_messages),
    };
  } else if (isQuotePending(quote)) {
    return CarrierProductStatus[EligibleCarrierStatus.Info];
  } else if (isQuoteNotOptedIn(quote)) {
    return CarrierProductStatus[EligibleCarrierStatus.NotOptedIn];
  } else if (isQuoteQuestionSetIncomplete(quote)) {
    return CarrierProductStatus[EligibleCarrierStatus.Incomplete];
  }
  return null;
};

export function displayQuoteErrorMessages(translatedMessages: ITranslatedMessage[]) {
  let errorString = 'Unable to retrieve quote';
  if (translatedMessages?.length > 0) {
    const category = translatedMessages.find(
      (message) => message.code !== '00.00.00.00',
    )?.category;
    errorString = category ? category : errorString;
  }

  return errorString;
}
