/**
 * todo: move this to SDK types
 * See `source` enum BoldPenguin/partner-engine: app/models/application_form.rb
 */
export enum Source {
  AcordFormPdf = 'acord_form_pdf',
  AgentQuoteStart = 'agent_quote_start',
  ClientQuoteStart = 'client_quote_start',
  Storefront = 'storefront',
  AppetiteSearch = 'appetite_search',
}
