<emperor-masked-input
  *ngIf="fieldType !== dateFieldType; else datePicker"
  [min]="min"
  [max]="max"
  [placeholder]="placeholder || ''"
  [iconPrefix]="iconPrefix"
  [label]="label"
  [formControl]="formControl"
  [ariaLabelledBy]="labelledBy"
  [id]="answerId"
  [type]="(inputType$ | async)!"
  [mode]="(inputMode$ | async)!"
  [errorText]="errorText"
  [errorMessageMap]="errorMessageMap"
  [mask]="formattedMask"
  [warning]="warning"
  [hint]="helpText"
  [hintWithIcon]="hintWithIcon"
  [code]="code"
  (focus)="onFocus()"
  [isReadOnly]="isReadOnly"
></emperor-masked-input>

<ng-template #datePicker>
  <emperor-bp-sdk-date-input
    [answerId]="answerId"
    [code]="code"
    [errors]="errors"
    [errorText]="errorText"
    [fieldType]="fieldType"
    [helpText]="helpText"
    [hintWithIcon]="hintWithIcon"
    [isCompleted]="isCompleted"
    [isSensitive]="true"
    [label]="label"
    [labelId]="labelId"
    [labelledBy]="labelledBy"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [pattern]="pattern"
    [placeholder]="(placeholderForDOB$ | async)!"
    [pristine]="pristine"
    [questionId]="questionId"
    [required]="required"
    [touched]="touched"
    [value]="value"
    [errorMessageMap]="errorMessageMap"
    [isReadOnly]="isReadOnly"
  ></emperor-bp-sdk-date-input>
</ng-template>
