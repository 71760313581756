import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { EmperorButtonModule, EmperorSpinnerModule, EmperorTooltipModule } from '@boldpenguin/emperor-presentational';
import { CarrierProductEligibilityWrapperComponent } from './components/carrier-product-eligibility/carrier-product-eligibility-wrapper/carrier-product-eligibility-wrapper.component';
import { CarrierProductEligibilityComponent } from './components/carrier-product-eligibility/carrier-product-eligibility.component';
import { CoverageEligibilityIconComponent } from './components/coverage-eligibility-icon/coverage-eligibility-icon.component';
import { EligibilityCarrierCardHeaderComponent } from './components/eligibility-carrier-card/eligibility-carrier-card-header/eligibility-carrier-card-header.component';
import { EligibilityCarrierCardComponent } from './components/eligibility-carrier-card/eligibility-carrier-card.component';
import { EligibilityPanelComponent } from './components/eligibility-panel/eligibility-panel.component';
import { FormatProductBundlePipe } from './pipes/format-product/format-product-bundle.pipe';
import { FormatProductPipe } from './pipes/format-product/format-product.pipe';
import { QuotePriceLabelPipe } from './pipes/quote-price-label/quote-price-label.pipe';
import { QuotePricePipe } from './pipes/quote-price/quote-price.pipe';
import { RealTimeEligibilityService } from './services/real-time-eligibility.service';

@NgModule({
  imports: [CommonModule, EmperorButtonModule, MatExpansionModule, EmperorTooltipModule, IconsModule, MatCardModule, EmperorSpinnerModule],
  declarations: [
    EligibilityPanelComponent,
    EligibilityCarrierCardComponent,
    EligibilityCarrierCardHeaderComponent,
    CoverageEligibilityIconComponent,
    CarrierProductEligibilityComponent,
    CarrierProductEligibilityWrapperComponent,
    QuotePricePipe,
    QuotePriceLabelPipe,
    FormatProductPipe,
    FormatProductBundlePipe,
    QuotePricePipe,
    QuotePriceLabelPipe,
  ],
  providers: [RealTimeEligibilityService],
  exports: [
    EligibilityPanelComponent,
    EligibilityCarrierCardComponent,
    EligibilityCarrierCardHeaderComponent,
    CoverageEligibilityIconComponent,
    CarrierProductEligibilityComponent,
    CarrierProductEligibilityWrapperComponent,
    QuotePricePipe,
    QuotePriceLabelPipe,
    FormatProductPipe,
    FormatProductBundlePipe,
    QuotePricePipe,
    QuotePriceLabelPipe,
  ],
})
export class RealTimeEligibilityModule {}
