import { Injectable } from '@angular/core';
import { EmperorIcon } from './icons/emperor-icons/emperor-icons';
import * as emperorIcons from './icons/index';

@Injectable({
  providedIn: 'root',
})
export class EmperorIconRegistry {
  private registry = new Map<string, string>();

  constructor() {
    const icons: EmperorIcon[] = Object.keys(emperorIcons).map(key => emperorIcons[key]);
    icons.map(icon => this.registry.set(icon.name, icon.data));
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.registry.has(iconName)) {
      console.warn(`We could not find the Icon with the name ${iconName}, did you add it to the Icon registry?`);
    }
    return this.registry.get(iconName);
  }

  public getAllIconNames(): string[] {
    return Array.from(this.registry.keys());
  }
}
