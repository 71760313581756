import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { EmperorCardHeaderColor } from '../../models';

// The Card Header & Footer Base for functionality shared between the 2.
// Every component who uses this directive MUST use onPush change detection
@Directive({
  selector: '[emperorCardElementBase]',
})
export class CardElementBaseDirective implements OnChanges {
  @Input() color: EmperorCardHeaderColor;

  @HostBinding('class.emperor-ui-default') defaultColor = true;
  @HostBinding('class.emperor-ui-primary') primaryColor = false;
  @HostBinding('class.emperor-ui-accent') accentColor = false;
  @HostBinding('class.emperor-ui-warn') warnColor = false;
  @HostBinding('class.emperor-ui-none') noColor = false;

  ngOnChanges() {
    this.defaultColor = !this.color;
    this.primaryColor = this.color === EmperorCardHeaderColor.PRIMARY;
    this.accentColor = this.color === EmperorCardHeaderColor.ACCENT;
    this.warnColor = this.color === EmperorCardHeaderColor.WARN;
    this.noColor = this.color === EmperorCardHeaderColor.NONE;
  }
}
