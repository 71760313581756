import { Pipe, PipeTransform } from '@angular/core';
import { CoverageTypesUtils } from '@boldpenguin/emperor-services';
import { IProduct } from '@boldpenguin/sdk';
@Pipe({
  name: 'formatProductBundle',
})
export class FormatProductBundlePipe implements PipeTransform {
  transform(products: IProduct[] | null, abbreviate = true): string {
    if (!products) {
      return '';
    }
    let formattedStr: string;
    if (abbreviate) {
      formattedStr = `${products
        .map((product) => CoverageTypesUtils.getCoverageAbbreviation(product.name))
        .join(', ')}`;
    } else {
      formattedStr = `${products.map((product) => product.name).join(', ')}`;
    }

    return formattedStr;
  }
}
