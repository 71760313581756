import { Component, ElementRef } from '@angular/core';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { IVehicleEditButtonComponent } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-vehicle-edit-button',
  templateUrl: './bp-sdk-vehicle-edit-button.component.html',
  styleUrls: ['./bp-sdk-vehicle-edit-button.component.scss'],
})
export class BpSdkVehicleEditButtonComponent implements IVehicleEditButtonComponent {
  uiButtonTypes = EmperorUIButtonTypes;

  constructor(private elementRef: ElementRef) {}

  click(event: Event) {
    event.stopPropagation();
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('click', {
        bubbles: true,
      }),
    );
  }
}
