import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'emperor-carrier-product-eligibility-wrapper',
  templateUrl: './carrier-product-eligibility-wrapper.component.html',
  styleUrls: ['./carrier-product-eligibility-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierProductEligibilityWrapperComponent {
  @Input() interactive = false;
  @Output() goToQuote = new EventEmitter<void>();

  handleProductClick(): void {
    this.goToQuote.emit();
  }
}
