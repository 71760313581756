import { Component, ElementRef, Input } from '@angular/core';
import { IVehicleSaveButtonComponent, generateId } from '@boldpenguin/sdk';

@Component({
  selector: 'emperor-bp-sdk-vehicle-save-button',
  templateUrl: './bp-sdk-vehicle-save-button.component.html',
  styleUrls: ['./bp-sdk-vehicle-save-button.component.scss'],
})
export class BpSdkVehicleSaveButtonComponent implements IVehicleSaveButtonComponent {
  @Input()
  text = 'Save & Continue';

  @Input()
  buttonDisabled: boolean;

  @Input()
  buttonId: string = generateId(8);

  constructor(private elementRef: ElementRef) {}

  click(): void {
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('click', {
        bubbles: true,
      }),
    );
  }
}
