<label [id]="labelId" [innerHTML]="label" class="emperor-ui-form-field-label" [for]="id"></label>
<mat-form-field appearance="outline" class="emperor-ui-full-width emperor-ui-text-input">
  <span *ngIf="iconPrefix.showPrefix" matIconPrefix>
    <emperor-icon class="emperor-ui-text-prefix-icon" [name]="iconPrefix.icon"></emperor-icon>
  </span>
  <input
    matInput
    [type]="type"
    [min]="min"
    [max]="max"
    [attr.minlength]="!isNumeric ? min : null"
    [attr.maxlength]="!isNumeric ? max : null"
    [id]="id || ''"
    [formControl]="control"
    [attr.aria-label]="ariaLabel ? ariaLabel : sanitizedLabelText"
    [attr.aria-labelledby]="emperorInputAriaLabeledBy"
    [attr.data-test]="qaAttributeHook"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [placeholder]="placeholder ?? ''"
  />
  <mat-error emperorErrorHandler [errorText]="errorText" [errorMessageMap]="errorMessageMap" [id]="code || ''"></mat-error>
  <mat-hint *ngIf="!!hint && !hintWithIcon" [innerHtml]="hint"></mat-hint>
  <mat-hint *ngIf="!!hintWithIcon">
    <emperor-hint-with-icon [hintWithIcon]="hintWithIcon"></emperor-hint-with-icon>
  </mat-hint>
  <!-- TODO(mdc-migration): matSuffix is deprecated, so we may need to distinguish between matIconSuffix and matTextSuffix (RN) -->
  <span matSuffix>
    <ng-content select="[suffix]"></ng-content>
  </span>
</mat-form-field>
