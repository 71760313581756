import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IHintWithIcon } from '@boldpenguin/emperor-form-fields';
import { IDateInputComponent, cleanDateString } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { BpSdkBaseComponentComponent } from '../bp-sdk-base-component/bp-sdk-base-component.component';

export class CustomValidators extends Validators {
  static sensitiveRequired(is_completed: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (is_completed && control.pristine) {
        return null;
      }
      return control.value == null || control.value.length === 0 ? { required: true } : null;
    };
  }
}

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-date-input',
  templateUrl: './bp-sdk-date-input.component.html',
  styleUrls: ['./bp-sdk-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSdkDateInputComponent extends BpSdkBaseComponentComponent implements OnChanges, OnInit, IDateInputComponent {
  /**
   * Help text
   */
  @Input() helpText: string | null;
  /**
   * Is sensitive flagged, used for dates like birth date
   */
  @Input() isSensitive: boolean;

  protected _maxDate$ = new BehaviorSubject<string | undefined>(undefined);
  maxDate$ = this._maxDate$.asObservable();
  /**
   * maximum date value
   */
  @Input() set maxDate(max: string | null) {
    this._maxDate$.next(max != undefined ? cleanDateString(max) : undefined);
  }

  protected _minDate$ = new BehaviorSubject<string | undefined>(undefined);
  readonly minDate$ = this._minDate$.asObservable();
  /**
   * minimum date value
   */
  @Input() set minDate(min: string | null) {
    this._minDate$.next(min != undefined ? cleanDateString(min) : undefined);
  }
  /**
   * Regex pattern to match
   */
  @Input() pattern: string | null;
  /**
   * Placeholder to display
   */
  @Input() placeholder = 'Pick a date';
  /**
   * Question text
   */
  @Input() text: string | null;
  /**
   * question value
   */
  @Input() value: string | null;
  /**
   * An object to map custom error messages to certain types of errors
   */
  @Input() errorMessageMap: { [index: string]: string } | null;

  /**
   * Text that is displayed below the input box with an icon.
   */
  @Input() hintWithIcon: IHintWithIcon | undefined;

  private readonly DATE_ONLY_STRING_REG_EXP = /^(?<year>\d{4})-(?<month>\d{2})-(?<date>\d{2})$/;

  ngOnInit(): void {
    super.ngOnInit();

    this.formControl.valueChanges.pipe(startWith(this.value), untilDestroyed(this)).subscribe((value: string | Date) => {
      // ignore falsy values, masked dates, and invalid dates
      if (!value || (value instanceof Date && isNaN(value as unknown as number))) {
        // Ignore if this is a blur on sensitive completed dates
        if (this.isSensitive && this.isCompleted && this.formControl.pristine) {
          return;
        }

        this.elementRef.nativeElement.dispatchEvent(
          new CustomEvent('valueUpdate', {
            detail: '',
            bubbles: true,
          }),
        );
        return;
      }

      if (this.isMaskedDateString(value.toString())) {
        return;
      }

      const detail = new Date(value).toISOString();
      this.elementRef.nativeElement.dispatchEvent(
        new CustomEvent('valueUpdate', {
          detail,
          bubbles: true,
        }),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (Object.keys(changes).includes('value')) {
      this.handleValueChanges(changes.value);
    }

    if (changes.pattern && !changes.pattern.firstChange) {
      this.setupValidators();
    }
  }

  setupValidators() {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      if (!this.isSensitive) {
        validators.push(Validators.required);
      } else {
        validators.push(CustomValidators.sensitiveRequired(this.isCompleted));
      }
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }
    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  private handleValueChanges(valueChange: SimpleChange): void {
    if (this.isMaskedDateString(valueChange.currentValue)) {
      return;
    }

    if (this.formControl.value !== valueChange.currentValue) {
      // Update to local time or allow form to reset value (e.g. date no longer valid)
      const newValue = this.getNewValue(valueChange.currentValue);
      this.formControl.setValue(newValue, { emitEvent: false });
    }
  }

  private isMaskedDateString(date: string | null): boolean {
    // The initial masked date value will be a string equal to: "**/**/****"
    return !!date && date.includes('*');
  }

  private getNewValue(value: string | null): Date | null {
    if (!value) {
      return null;
    }
    if (this.DATE_ONLY_STRING_REG_EXP.test(value)) {
      return new Date(`${value}T00:00:00`);
    }
    return new Date(value);
  }
}
