import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmperorAlertModule } from './alert';
import { EmperorButtonModule } from './button/button.module';
import { EmperorCardModule } from './card/card.module';
import { EmperorCarouselModule } from './carousel';
import { EmperorChipModule } from './chip';
import { EmperorDialogModule } from './dialog/dialog.module';
import { EmperorFooterModule } from './footer/footer.module';
import { EmperorNavButtonModule } from './nav-button';
import { EmperorNoQuotesModule } from './no-quotes';
import { EmperorProgressBarModule } from './progress-bar';
import { EmperorQuotePresentationModule } from './quote-presentation';
import { EmperorSpinnerModule } from './spinner';
import { EmperorTimelineModule } from './timeline/timeline.module';
import { EmperorTooltipModule } from './tooltip/tooltip.module';

export * from './models';

@NgModule({
  imports: [
    CommonModule,
    EmperorAlertModule,
    EmperorTooltipModule,
    EmperorTimelineModule,
    EmperorSpinnerModule,
    EmperorButtonModule,
    EmperorCardModule,
    EmperorNavButtonModule,
    EmperorChipModule,
    EmperorQuotePresentationModule,
    EmperorFooterModule,
    EmperorDialogModule,
    EmperorNoQuotesModule,
    EmperorProgressBarModule,
    EmperorCarouselModule,
  ],
  exports: [
    EmperorAlertModule,
    EmperorTooltipModule,
    EmperorTimelineModule,
    EmperorSpinnerModule,
    EmperorButtonModule,
    EmperorCardModule,
    EmperorNavButtonModule,
    EmperorChipModule,
    EmperorQuotePresentationModule,
    EmperorFooterModule,
    EmperorDialogModule,
    EmperorNoQuotesModule,
    EmperorProgressBarModule,
    EmperorCarouselModule,
  ],
})
export class PresentationalModule {}

export * from './alert/alert.module';
export * from './button/button.module';
export * from './card/card.module';
export * from './carousel/carousel.module';
export * from './chip/chip.module';
export * from './dialog/dialog.module';
export * from './footer/footer.module';
export * from './nav-button/nav-button.module';
export * from './no-quotes/no-quotes.module';
export * from './progress-bar/progress-bar.module';
export * from './quote-presentation/quote-presentation.module';
export * from './spinner/spinner.module';
export * from './timeline/timeline.module';
export * from './tooltip/tooltip.module';
