<emperor-card aria-modal="true" role="dialog" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-describedby]="ariaDescribeby" #card>
  <ng-content></ng-content>
</emperor-card>

<div class="backdrop" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="true"
  [cdkConnectedOverlayScrollStrategy]="overlayScrollStrategy"
>
</ng-template>
