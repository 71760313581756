import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { SdkStoreService, SelectorWrapperService } from '@boldpenguin/emperor-services';
import { INextButtonComponent, LoadingStates } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { QuestionFocusService } from '../../service/question-focus.service';
import { QuestionSetInterceptorService } from '../../service/question-set-interceptor.service';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-question-set-next-button',
  templateUrl: './bp-sdk-question-set-next-button.component.html',
  styleUrls: ['./bp-sdk-question-set-next-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BpSdkQuestionSetNextButtonComponent implements OnInit, INextButtonComponent {
  @Input() buttonDisabled: boolean;
  @Input() cssClasses: string;

  @Output() disabledClick = new EventEmitter();

  closeTooltip$ = new Subject<void>();
  spinnerActive = false;

  readonly emperorUIButtonTypes = EmperorUIButtonTypes;

  private activeQuestionSetId = '';

  constructor(
    private elementRef: ElementRef,
    private sdkStoreService: SdkStoreService,
    private selectorWrapperService: SelectorWrapperService,
    private readonly questionFocusService: QuestionFocusService,
    private readonly questionSetInterceptorService: QuestionSetInterceptorService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.sdkStoreService.initStoreListener();

    const anyAnswerLoading$ = this.selectorWrapperService
      .getAnswersState$()
      .pipe(map(answersState => answersState === LoadingStates.Loading));

    const currentQuestionSetLoading$ = this.selectorWrapperService.isActiveQuestionSetLoading$();

    const isActiveQuestionSetPendingProcess$ = this.selectorWrapperService.selectIsActiveQuestionSetPendingProcess$();

    combineLatest({
      anyAnswerLoading: anyAnswerLoading$,
      currentQuestionSetLoading: currentQuestionSetLoading$,
      isQuestionSetPendingProcess: isActiveQuestionSetPendingProcess$,
    })
      .pipe(
        map(
          ({ anyAnswerLoading, currentQuestionSetLoading, isQuestionSetPendingProcess }) =>
            anyAnswerLoading || currentQuestionSetLoading || isQuestionSetPendingProcess,
        ),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(loading => {
        this.spinnerActive = loading;
        this.cdRef.detectChanges();
      });

    this.selectorWrapperService
      .getCurrentActiveQuestionSetId$()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(activeQuestionSetId => {
        this.activeQuestionSetId = activeQuestionSetId;
      });

    this.questionFocusService
      .findFirstQuestion()
      .pipe(untilDestroyed(this))
      .subscribe(({ element, answer }) => {
        this.questionFocusService.focusOnElement(element, answer);
      });
  }

  click() {
    this.closeTooltip$.next();
    if (this.questionSetInterceptorService.hasSaveInterceptor(this.activeQuestionSetId)) {
      this.buttonDisabled = true;
      this.spinnerActive = true;
      this.questionSetInterceptorService.callSaveInterceptor(this.activeQuestionSetId).then(() => {
        this.elementRef.nativeElement.dispatchEvent(new CustomEvent('navigateNext', { bubbles: true }));
      });
    } else {
      this.elementRef.nativeElement.dispatchEvent(new CustomEvent('navigateNext', { bubbles: true }));
    }
  }

  onDisabledClick() {
    this.closeTooltip$.next();

    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent('validateQuestionSet', {
        bubbles: true,
      }),
    );
    this.elementRef.nativeElement.dispatchEvent(new CustomEvent('disabledNextQuestionSetClick', { bubbles: true }));
    return;
  }
}
