import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmperorUIButtonTypes } from '../../../button';
import { CarouselNavigationMode } from '../../models/carousel.model';

@Component({
  selector: 'emperor-carousel-navigation',
  templateUrl: './carousel-navigation.component.html',
  styleUrls: ['./carousel-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselNavigationComponent implements OnInit, OnChanges {
  @Input() currentIndex = 0;
  @Input() itemCount = 1;
  /**
   * In bounded mode, navigation is limited on the first and last items.
   * Previous is disabled on the first item, and next is disabled on the last item.
   *
   * In endless mode, navigation wraps around from the end to the beginning.
   * If next is clicked on the last item, the index will be set to the beginning of the list. If previous is clicked on
   * the first item, the index will be set to the end of the list.
   */
  @Input() navigationMode: CarouselNavigationMode = 'bounded';
  @Output() changeIndex = new EventEmitter<number>();

  readonly EmperorButtonType = EmperorUIButtonTypes;
  isNextDisabled = true;
  isPreviousDisabled = true;
  navigationMeatballs = this.getNavigationMeatballs(1);

  ngOnInit(): void {
    this.itemCount = this.getNormalizedItemCount(this.itemCount);
    this.navigationMeatballs = this.getNavigationMeatballs(this.itemCount);
    this.updateNavigationEnabledState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemCount && !changes.itemCount.firstChange) {
      this.currentIndex = 0;
      this.itemCount = this.getNormalizedItemCount(this.itemCount);
      this.navigationMeatballs = this.getNavigationMeatballs(this.itemCount);
      this.updateNavigationEnabledState();
    }

    if (changes.currentIndex && !changes.currentIndex.firstChange) {
      this.updateNavigationEnabledState();
    }
  }

  isCurrentItem(index: number) {
    return index === this.currentIndex;
  }

  moveBackward(): void {
    if (this.isPreviousDisabled) {
      return;
    }

    const newIndex = this.calculatePreviousIndex(this.currentIndex);
    this.currentIndex = newIndex;
    this.updateNavigationEnabledState();
    this.changeIndex.emit(newIndex);
  }

  moveForward(): void {
    if (this.isNextDisabled) {
      return;
    }

    const newIndex = this.calculateNextIndex(this.currentIndex);
    this.currentIndex = newIndex;
    this.updateNavigationEnabledState();
    this.changeIndex.emit(newIndex);
  }

  selectItem(index: number): void {
    this.currentIndex = index;
    this.updateNavigationEnabledState();
    this.changeIndex.emit(index);
  }

  getItemAriaLabel(index: number): string {
    // Adjust index from 0-based to 1-based for human readability
    return `Move to item ${index + 1}`;
  }

  private calculatePreviousIndex(currentIndex: number): number {
    const previousIndex = currentIndex - 1;

    if (this.navigationMode === 'bounded') {
      return previousIndex;
    } else {
      // endless mode wraps around to the last item
      return previousIndex < 0 ? this.itemCount - 1 : previousIndex;
    }
  }

  private calculateNextIndex(currentIndex: number): number {
    const nextIndex = currentIndex + 1;
    // endless mode wraps around to the first item
    return this.navigationMode === 'bounded' ? nextIndex : nextIndex % this.itemCount;
  }

  private updateNavigationEnabledState(): void {
    this.isNextDisabled = this.itemCount === 1 || (this.navigationMode === 'bounded' && this.isLastItem());
    this.isPreviousDisabled = this.itemCount === 1 || (this.navigationMode === 'bounded' && this.isFirstItem());
  }

  private isFirstItem(): boolean {
    return this.currentIndex === 0;
  }

  private isLastItem(): boolean {
    return this.currentIndex === this.itemCount - 1;
  }

  /**
   * Ensures item count is at least 1 so controls don't break
   */
  private getNormalizedItemCount(itemCount: number): number {
    return itemCount > 0 ? itemCount : 1;
  }

  /**
   * The actual items which the carousel displays are not passed to the navigation, so this dummy list is
   * needed for ngFor to iterate over
   */
  private getNavigationMeatballs(itemCount: number): string[] {
    return new Array(itemCount).fill('');
  }
}
