import { Component, forwardRef, Injector, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FactoryArg as AnyMaskedOptions } from 'imask';
import { BehaviorSubject } from 'rxjs';
import { InputDirective } from '../../directive/input.directive';
import { IIconPrefix } from '../../models';

@Component({
  selector: 'emperor-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskedInputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MaskedInputComponent extends InputDirective implements OnInit, OnChanges {
  @Input() mask: AnyMaskedOptions;
  @Input() iconPrefix?: IIconPrefix;
  @Input() type = 'text';
  @Input() mode = 'text';

  private _suffix$ = new BehaviorSubject<string | undefined>(undefined);
  readonly suffix$ = this._suffix$.asObservable();

  @Input() set suffix(suffix: string | undefined) {
    this._suffix$.next(suffix);
  }

  private _warning$ = new BehaviorSubject<string | undefined>(undefined);
  readonly warning$ = this._warning$.asObservable();

  @Input() set warning(warning: string | undefined) {
    this._warning$.next(warning);
  }

  /**
   * Current value
   */
  @Input() value: string;

  constructor(injector: Injector, sanitizer: DomSanitizer) {
    super(injector, sanitizer);
  }

  // As a consequence of using ngx-mask and FormControlDirective
  // there is an Expression changed after detection
  registerOnChange(fn: (_value: unknown) => void) {
    this.onChange(fn);
  }
}
