import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';
import { IBusinessClassificationSelectorCardContentComponent, IChoiceHighlight, IChoiceObject } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'emperor-bp-sdk-business-classification-selector-card-content',
  templateUrl: './bp-sdk-business-classification-selector-card-content.component.html',
  styleUrls: ['./bp-sdk-business-classification-selector-card-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BpSdkBusinessClassificationSelectorCardContentComponent
  implements OnInit, OnChanges, AfterViewInit, IBusinessClassificationSelectorCardContentComponent
{
  @Input() alias: string;
  @Input() confidence: number;
  @Input() explanation: string;
  @Input() highlights: IChoiceHighlight[];
  @Input() showConfidence: boolean;
  @Input() showDetailedRelevanceChip: boolean;
  @Input() showExplanation: boolean;
  @Input() showNaicsCode: boolean;
  @Input() value: string;
  @Input() isSelected: boolean;
  @Input() isBestMatch: boolean;
  @Input() isoCodes: IChoiceObject[];
  @Input() sicCodes: IChoiceObject[];
  @ViewChild('uiName') uiNameElement: ElementRef;
  @ViewChild('uiBestMatch') uiBestMatchElement: ElementRef;
  isCollision = false;

  control = new FormControl<boolean | string>(false);
  choices$ = new BehaviorSubject<EmperorUISelectFormChoice[]>([{ label: '', value: false, id: '1' }]);
  synonym: string | undefined;

  isoCode: string | undefined;
  sicCode: string | undefined;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.choices$.next([{ label: '', value: '1', id: '1' }]);
    this.control.setValue(this.isSelected ? '1' : '0');

    if (this.isoCodes) {
      if (this.isoCodes.length === 1) {
        this.isoCode = this.isoCodes[0].code;
      }
    }

    if (this.sicCodes) {
      if (this.sicCodes.length === 1) {
        this.sicCode = this.sicCodes[0].code;
      }
    }

    if (this.highlights) {
      this.synonym = this.highlights.find(highlight => highlight.field === 'synonyms')?.text;

      const valueHighlight = this.highlights.find(highlight => highlight.field === 'value');
      if (valueHighlight) {
        this.value = valueHighlight.text;
      }

      const naicsHighlight = this.highlights.find(highlight => highlight.field === 'alias');
      if (naicsHighlight) {
        this.alias = naicsHighlight.text;
      }

      const isoHighlight = this.highlights.find(highlight => highlight.field === 'iso_codes');
      if (isoHighlight?.nested_field === 'code' && isoHighlight.object?.code === this.isoCode) {
        this.isoCode = isoHighlight.text;
      }

      const sicHighlight = this.highlights.find(highlight => highlight.field === 'sic_codes');
      if (sicHighlight?.nested_field === 'code' && sicHighlight.object?.code === this.sicCode) {
        this.sicCode = sicHighlight.text;
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.isSelected) {
      this.choices$.next([{ label: '', value: '1', id: '1' }]);
      this.control.setValue(this.isSelected ? '1' : '0');
    }
  }

  ngAfterViewInit() {
    if (this.isBestMatch) {
      fromEvent(window, 'resize')
        .pipe(debounceTime(100), untilDestroyed(this))
        .subscribe(() => {
          this.collisionDetection();
        });
    }
  }

  collisionDetection() {
    const nameRect = this.uiNameElement.nativeElement.getBoundingClientRect();
    const bestMatchRect = this.uiBestMatchElement.nativeElement.getBoundingClientRect();

    this.isCollision = bestMatchRect.left - nameRect.right < 0;
    this.cdRef.detectChanges();
  }
}
