<div class="slim-bcs-selector-list">
  <button
    class="naics-choice"
    *ngFor="let choice of visibleChoices"
    [class.selected]="choiceId === choice.id"
    (click)="clickChoice(choice)"
  >
    <div class="naics-best-choice" *ngIf="choice.id === bestChoice">
      <span>TOP SUGGESTION</span>
    </div>
    <div class="naics-title">
      {{ choice.value }}
    </div>
    <div class="naics-examples" *ngIf="choice | bcsExamples as examples">
      <span [innerHtml]="examples"></span>
    </div>
  </button>
</div>
