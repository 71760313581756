<ng-container *ngIf="!isReadOnly; else select">
  <h2 class="emperor-ui-product-label">{{ label }}</h2>

  <div *ngIf="(showError$ | async) === true" class="emperor-ui-product-list-error" data-testid="show-error">This is required</div>

  <div
    *ngFor="let control of $any(formArray.controls); let i = index"
    class="emperor-ui-sdk-product-list"
    [ngClass]="{ 'emperor-ui-selected': control.value === true }"
  >
    <emperor-checkbox
      [formControl]="control"
      [label]="choices[i].value"
      [withSupportingText]="true"
      [supportingText]="coverageTypesUtils.getDescriptionFromChoice(choices[i])"
      [id]="choices[i].id"
    >
    </emperor-checkbox>
  </div>
</ng-container>

<ng-template #select>
  <emperor-select
    [placeholder]="''"
    [choices]="emperorUiChoices"
    [multi]="true"
    [formControl]="formControl"
    [ariaLabel]="labelId"
    [ariaLabelledBy]="labelledBy"
    [label]="label ?? 'Coverage Types'"
    [id]="answerId"
    [errorText]="errorText"
    [hint]="helpText"
    [hintWithIcon]="hintWithIcon"
    [isReadOnly]="isReadOnly"
    [code]="code"
  ></emperor-select>
</ng-template>
