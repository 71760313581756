<div class="emperor-ui-naic-option" [class.emperor-ui-selected]="isSelected">
  <div class="emperor-ui-best-match-collision" [class.collision-hide]="!isCollision" *ngIf="isBestMatch">Best Suggestion</div>
  <div class="option-information">
    <div class="emperor-ui-radio-icon">
      <emperor-radio-button [formControl]="control" [choices]="(choices$ | async)!"></emperor-radio-button>
    </div>

    <div class="emperor-ui-naic-details">
      <div class="emperor-ui-name" #uiName [innerHTML]="value"></div>
      <div class="emperor-ui-synonym" *ngIf="synonym">Related to: <span [innerHTML]="synonym"></span></div>
      <div class="emperor-ui-alias">
        <div>NAICS code: <span [innerHTML]="alias"></span></div>
        <div *ngIf="isoCode">ISO code: <span [innerHTML]="isoCode"></span></div>
        <div *ngIf="sicCode">SIC code: <span [innerHTML]="sicCode"></span></div>
      </div>
    </div>

    <div class="emperor-ui-best-match" [class.best-match-hide]="isCollision" *ngIf="isBestMatch" #uiBestMatch>Best Suggestion</div>
  </div>
</div>
