import { Component, ElementRef, forwardRef, Injector, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { InputFieldsetDisableDirective } from '../../directive/input-fieldset-disable.directive';
import { EmperorUISelectFormChoice } from '../../models';

@Component({
  selector: 'emperor-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent extends InputFieldsetDisableDirective implements OnChanges {
  @Input() choices: EmperorUISelectFormChoice[] = [];
  @Input() multi = false;
  @Input() selectTrigger = '';
  @ViewChild('select') selectRef: MatSelect;

  constructor(injector: Injector, sanitizer: DomSanitizer, elementRef: ElementRef) {
    super(injector, sanitizer, elementRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.id) {
      this.selectRef?.close();
    }
  }
}
