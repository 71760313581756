import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { SdkStoreService } from '@boldpenguin/emperor-services';
import { IAddItemContainerComponent, LoadingStates } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';

@Component({
  selector: 'emperor-bp-sdk-add-item',
  templateUrl: './bp-sdk-add-item.component.html',
  styleUrls: ['./bp-sdk-add-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class BpSdkAddItemComponent implements IAddItemContainerComponent {
  @Input() answerPoolId: string | null;
  /**
   * Can add more state
   */
  @Input() canAddMore = true;
  /**
   * Help text
   */
  @Input() helpText: string;
  /**
   * Icon name
   */
  @Input() icon: string | null = 'add_circle_2';
  /**
   * Text
   */
  @Input() text: string;
  /**
   *
   */
  @Input() questionId: string;
  @Input() questionSetId: string;

  buttonDisabled$: Observable<boolean>;

  constructor(private elementRef: ElementRef, private storeService: SdkStoreService) {

    const questionPool$ = this.storeService.state$.pipe(
      map(state => state.application.questionSets.list.find(qs => qs.question_set_id === this.questionSetId)),
      map(afqs => afqs?.question_set.question_pools.find(qp => qp.parent_question_id === this.questionId)),
      distinctUntilChanged((previous, current) => previous?.id === current?.id),
    )

    const questionPoolLoadingState$ = this.storeService.state$.pipe(
      map(state => state.status.remote.questionPools.map)
    )

    this.buttonDisabled$ = combineLatest([questionPool$, questionPoolLoadingState$]).pipe(
      map(([pool, loadingState ]) => {
        return !pool ? false : loadingState[pool.id] === LoadingStates.Loading
      }),
      distinctUntilChanged(),
      untilDestroyed(this)
    )
  }

  addClicked() {
    this.elementRef.nativeElement.dispatchEvent(new CustomEvent('addItem', { detail: this.answerPoolId, bubbles: true }));
  }
}
