<emperor-checkbox
  [formControl]="formControl"
  [label]="label"
  [ariaLabelledBy]="labelledBy"
  [required]="required"
  [hintWithIcon]="hintWithIcon"
  [id]="answerId"
  [code]="code"
  [isReadOnly]="isReadOnly"
></emperor-checkbox>
