import { Component, Input } from '@angular/core';
import { EmperorThemeColor } from '../../../models';

@Component({
  selector: 'emperor-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() set color(value: EmperorThemeColor | undefined) {
    if (value) {
      this.colorClass = `emperor-ui-${value}`;
    }
  }
  colorClass?: string;
}
